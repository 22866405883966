const CookieConsent = ({ onAccept }) => {
  return (
    <>
      {/* Dark overlay */}
      <div className="fixed inset-0 bg-black/75 z-40" />

      {/* Cookie consent modal */}
      <div className="fixed bottom-10 left-[50%] -translate-x-1/2 flex md:w-full w-[90%] max-w-[520px] mx-auto my-0 p-4 gap-2 items-center flex-wrap md:flex-nowrap [background:var(--Color-Light-Opacity-White-10,rgba(255,255,255,0.10))] backdrop-blur-[5px] rounded-xl z-50">
        <div className="flex gap-2 items-center flex-1">
          <div className="">🍪</div>
          <div className="flex-1 font-outfit text-sm font-extralight">
            <span className="text-white">
              Cookies keep Wubble grooving! Check out our policy
            </span>
            <a
              href="https://info.wubble.ai/cookie-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#d54dff] underline cursor-pointer ml-1"
            >
              here.
            </a>
          </div>
        </div>
        <button onClick={onAccept} className="flex items-center shrink-0 z-10">
          <div className="flex h-8 px-2 justify-center items-center bg-white rounded-full hover:bg-gray-100 transition-colors duration-200">
            <span className="font-outfit text-sm font-normal text-black whitespace-nowrap">
              Accept all
            </span>
          </div>
        </button>
      </div>
    </>
  );
};

export default CookieConsent;
