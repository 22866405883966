import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Check } from "@gravity-ui/icons";
import { useNavigate } from "react-router-dom";
import { useLocalState } from "../../../contexts/LocalStateProvider";
import {
  setOpenInviteMemberModal,
  setSelectedPlan,
  setSubscriptionModal,
} from "../../../store/reducers/subscriptionSlice";
// Replace with your Stripe publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
import { getCookie } from "../../../utils/cookieUtils";
import Toast from "../../generic_components/Toast";
import { usePostHog } from "posthog-js/react";

const planMapper = {
  Monthly: {
    Basic: {
      price: "price_1QVQas2MK4knwytneEMgm8yr",
      amount: 10,
      minutes: 30,
    },
    Business: {
      price: "price_1QlCRf2MK4knwytnTdq794Aq",
      amount: 30,
      minutes: 120,
    },
    Free: {
      price: "price_1QVQeS2MK4knwytndlaZzBrD",
      amount: 0,
      minutes: 10,
    },
  },
  Annual: {
    Basic: {
      price: "price_1QlCRz2MK4knwytnHoSiAdkU",
      amount: 110,
      minutes: 360,
    },
    Business: {
      price: "price_1QlCRB2MK4knwytnigbRo5oQ",
      amount: 330,
      minutes: 1440,
    },
    Free: {
      price: "price_1QVQeS2MK4knwytndlaZzBrD",
      amount: 0,
      minutes: 10,
    },
  },
};
const BaseCard = ({
  tier,
  monthlyPrice,
  annualPrice,
  period,
  isActive,
  features,
  buttonText,
  additionalInfo,
  showSavings,
  isAnnual,
  path,
  cardStyle = {}, // Custom card styles
  bgStyle, // Accept custom background style
}) => {
  const { localState } = useLocalState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirectAfterSelectedPlan, subscriptionType } = useSelector(
    (state) => state.subscription
  );
  const currentPrice = isAnnual ? annualPrice : monthlyPrice;
  const currentPeriod = isAnnual ? "Annual" : "Monthly";
  let activePlan = tier == "Free";
  activePlan = localState?.userProfileData?.subscription_type
    ? localState?.userProfileData?.subscription_type ===
      `${tier} ${currentPeriod}`
    : activePlan;
  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState("info");
  const posthog = usePostHog();

  // Add this near the top of the file with other constants
  const PLAN_HIERARCHY = {
    Free: 0,
    "Basic Monthly": 1,
    "Business Monthly": 2,
    "Basic Annual": 3,
    "Business Annual": 4,
    Enterprise: 5,
    "Film & Broadcast": 5,
  };

  // Inside BaseCard component, add this function
  const shouldDisableButton = () => {
    if (tier === "Enterprise" || tier === "Film & Broadcast") return false;

    const currentUserPlan = `${localState?.userProfileData?.subscription_type}`;
    console.log("Current User Plan:", currentUserPlan);

    const selectedPlan = `${tier} ${isAnnual ? "Annual" : "Monthly"}`;

    // Free tier is always disabled
    if (tier === "Free") return true;

    // If user has a plan, check hierarchy
    if (currentUserPlan) {
      const currentPlanLevel = PLAN_HIERARCHY[currentUserPlan];
      const selectedPlanLevel = PLAN_HIERARCHY[selectedPlan];

      return selectedPlanLevel == currentPlanLevel;
    }

    return false;
  };

  const handleManageBilling = async () => {
    try {
      const userId = getCookie("user_id");
      const teamId = localState?.userProfileData?.team_id;
      if (!userId || !teamId) return;
      // 1. Call your backend route to create a portal session.
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/create-portal-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            teamId,
          }),
        }
      );
      const data = await response.json();
      console.log(data);

      // 2. Redirect user to the portal URL
      if (data.url) {
        window.location.href = data.url;
      } else {
        console.error("Failed to retrieve portal session URL");
      }
    } catch (error) {
      console.error("Error creating portal session:", error);
      setToastMessage("You are not authorized to manage billing");
      setToastType("error");
    }
  };

  const handlePlanClick = async () => {
    posthog.capture(`Click on Continue ${tier} `);

    dispatch(
      setSelectedPlan(`${currentPeriod}-${tier}-${planPrice}-${priceId}`)
    );
    dispatch(setSubscriptionModal(false));
    if (tier === "Enterprise" || tier === "Film & Broadcast") {
      dispatch(setSubscriptionModal(false));
      return (window.location.href = "https://info.wubble.ai/talk-to-us");
    }

    if (subscriptionType === "upgrade") {
      handleManageBilling();
      // dispatch(setOpenInviteMemberModal(true));
    }

    if (redirectAfterSelectedPlan && !localState.isTeamMember)
      navigate(redirectAfterSelectedPlan);
    // Get Stripe.js instance
    // const stripe = await stripePromise;
    const priceId = planMapper[currentPeriod][tier].price; // Basic Plan Price ID
    const planPrice = planMapper[currentPeriod][tier].amount; // Basic Plan Price ID
    // const payment_type = `basic_${currentPeriod.toLowerCase()}`;
    // console.log("priceId+++++++++++", priceId);

    // // Call your backend to create the Checkout Session
    // const response = await fetch(
    //   `${process.env.REACT_APP_SERVER_ENDPOINT}/api/create-checkout-session`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${getCookie("token")}`,
    //     },
    //     body: JSON.stringify({ priceId, payment_type }),
    //   }
    // );

    // const { sessionId } = await response.json();

    // // Redirect to Stripe Checkout
    // const { error } = await stripe.redirectToCheckout({
    //   sessionId,
    // });

    // if (error) {
    //   console.error("Error redirecting to Stripe Checkout:", error);
    //   setToastMessage(
    //     error.response?.data?.error || "Error redirecting to Stripe Checkout:"
    //   );
    //   setToastType("error");
    // }
  };

  // Dynamic button styles based on the tier
  const getButtonStyle = () => {
    if (isActive) {
      return {
        background: "#444444",
        color: "#aaaaaa",
      };
    }

    switch (tier) {
      case "Business":
        return {
          background: "linear-gradient(135deg, #FC10F2 0%, #3078E4 100%)",
          color: "#fff",
        };
      case "Enterprise":
        return {
          background: "rgba(255, 255, 255, 0.05))",
          color: "#fff",
        };
      case "Film & Broadcast":
        return {
          background: "rgba(255, 255, 255, 0.05))",
          color: "#fff",
        };
      default:
        return {
          background: "#FFF",
          color: "#000",
        };
    }
  };

  return (
    <div
      className="py-0 rounded-lg flex flex-col w-full"
      style={{
        background:
          bgStyle ||
          "linear-gradient(135deg, rgba(250, 154, 32, 0.2) 0%, rgba(252, 16, 242, 0.2) 100%)", // Use custom background if provided
        ...cardStyle, // Apply custom styles if provided
      }}
    >
      <div className="p-3 w-full">
        <div className="flex gap-2">
          <h3 className="text-base font-normal text-white">{tier}</h3>
          {showSavings && isAnnual && (
            <div>
              <span className="bg-green-600 text-white text-xs px-1 py-0.5 rounded-md">
                1 month free
              </span>
            </div>
          )}
        </div>
        <div className="mt-2 flex-col w-full">
          <div className="flex w-full items-center gap-2">
            {isAnnual && monthlyPrice !== "$0" && (
              <span className="text-gray-400 text-base line-through text-sm">
                {tier === "Enterprise"
                  ? ""
                  : tier === "Film & Broadcast" && isAnnual
                  ? ""
                  : `$${parseInt(monthlyPrice.slice(1)) * 12}`}
              </span>
            )}
            <div className="w-full flex">
              <p
                className={`font-normal text-white ${
                  tier === "Enterprise" && isAnnual
                    ? "text-2xl -ml-2"
                    : tier === "Film & Broadcast" && isAnnual
                    ? "text-2xl -ml-2"
                    : "text-2xl"
                } `}
              >
                {currentPrice}
              </p>
            </div>
          </div>
          {period && <p className="text-gray-400 text-xs">{currentPeriod}</p>}
        </div>
      </div>
      <div className="px-3 w-full flex place-content-center pb-3 border-b border-b-white/10">
        <button
          className=" px-2 h-8 text-sm font-normal w-full rounded-full backdrop-brightness-200 disabled:cursor-not-allowed"
          style={getButtonStyle()} // Apply dynamic button styles
          onClick={() => handlePlanClick()}
          disabled={shouldDisableButton()}
        >
          {tier === "Enterprise"
            ? "Talk to us"
            : tier === "Film & Broadcast"
            ? "Talk to us"
            : activePlan
            ? "Active"
            : `Continue with ${tier}`}
        </button>
      </div>

      <ul className="space-y-1.5 flex-grow p-3">
        {features.map((feature, index) => (
          <li key={index}>
            <div className="flex items-start justify-start gap-2">
              <div className="flex items-center justify-center rounded-full mt-1 w-4 h-4 ">
                <Check className="w-3.5 h-3.5 opacity-100" />
              </div>
              <p className="text-sm font-extralight text-gray-300">{feature}</p>
            </div>
          </li>
        ))}
      </ul>

      {additionalInfo && (
        <div className="p-3 text-xs font-extralight text-gray-400">
          {isAnnual
            ? additionalInfo.replace("/month", "/annual")
            : additionalInfo}
        </div>
      )}
      {toastMessage && (
        <Toast
          type={toastType}
          message={toastMessage}
          onClose={() => setToastMessage(null)}
        />
      )}
    </div>
  );
};

export default BaseCard;
