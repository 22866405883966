import { ChevronDown, Eye, EyeSlash } from "@gravity-ui/icons";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import apiService from "../../api/apiService";
import { useAuth } from "../../contexts/AuthContext";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { useUserContext } from "../../contexts/UserContext";
import { setOpenOnboardingModal } from "../../store/reducers/subscriptionSlice";
import { getCookie } from "../../utils/cookieUtils";
import { functionMapper } from "../../utils/functionMapper";
import Toast from "./Toast";

const InputWithButton = ({
  formClassName,
  inputFields,
  buttonClassName,
  buttonText,
  onButtonClick,
  onClick,
  onClickOption1,
  onClickOption2,
  path,
  defaultValues = {},
  cancelButtonClassName,
  cancelButtonText,
  onCancelClick,
  enableConditionalBackground,
  enableConditionalBackgroundWhite,
  enableConditionalBackgroundRed,
  requestData,
  profileData,
  closeModal,
  trigger,
  actionButtonId,
  cancelButtonId,
}) => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const { userId, updateUserId } = useUserContext();
  const { login, logout } = useAuth();
  const { updateLocalState, localState } = useLocalState();
  const dispatch = useDispatch();

  const initializeFormValues = () => {
    return inputFields.reduce((acc, field) => {
      if (field.type === "row") {
        field.fields.forEach((subField) => {
          acc[subField.name] = defaultValues[subField.name] || "";
        });
      } else if (field.type !== "info") {
        acc[field.name] = defaultValues[field.name] || "";
      }
      return acc;
    }, {});
  };

  useEffect(() => {
    // Initialize checkbox values on mount
    const initialCheckboxValues = {};
    inputFields.forEach((field) => {
      if (field.type === "checkbox") {
        initialCheckboxValues[field.name] = field.defaultChecked || false;
      }
    });
    setFormValues((prev) => ({ ...prev, ...initialCheckboxValues }));
  }, []);

  const [formValues, setFormValues] = useState(initializeFormValues);

  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [showPasswords, setShowPasswords] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const location = useLocation();
  const [passwordRequirements, setPasswordRequirements] = useState({
    uppercase: false,
    numeric: false,
    special: false,
    validLength: false,
  });
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherReason, setOtherReason] = useState(""); // State for 'Other' reason
  const [showPasswordCriteria, setShowPasswordCriteria] = useState(false); // State to track if user has started typing
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    if (formValues.password && inputFields[0].label !== "Current Password") {
      validatePassword(formValues.password);
    }
    if (formValues.newPassword) {
      validatePassword(formValues.newPassword);
    }
  }, [formValues.password, formValues.newPassword]);

  const validateName = (name) => {
    if (name.startsWith(" ")) {
      setNameError("Name cannot start with a space");
      return false;
    }

    if (name.trim().length < 2) {
      setNameError("Name must be at least 2 characters long");
      return false;
    }

    const nameRegex = /^[a-zA-Z][a-zA-Z\s]*$/;
    if (!nameRegex.test(name)) {
      setNameError("Name cannot contain numbers or special characters");
      return false;
    }

    setNameError("");
    return true;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
      return false;
    }
    setEmailError("");
    return true;
  };

  const validatePassword = (password) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumeric = /[0-9]/.test(password);
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isValidLength = password?.length >= 8; // Check for minimum length

    const requirements = {
      uppercase: hasUppercase,
      numeric: hasNumeric,
      special: hasSpecial,
      validLength: isValidLength,
    };

    setPasswordRequirements(requirements);

    // Calculate progress percentage based on the number of criteria met
    const metRequirementsCount =
      Object.values(requirements).filter(Boolean).length;
    setProgressPercentage((metRequirementsCount / 4) * 100); // 4 criteria
  };

  useEffect(() => {
    const allFilled = inputFields.every((field) => {
      if (field.type === "row") {
        return field.fields.every((subField) =>
          subField.name === "company" || subField.name === "role"
            ? true
            : formValues[subField.name]
        );
      } else if (field.type !== "info" && field.type !== "checkbox") {
        if (field.name === "reason" && formValues.reason === "other") {
          return otherReason.trim() !== ""; // Check if other reason is filled
        }
        return formValues[field.name];
      }
      return true;
    });
    // Check if checkbox exists in inputFields, and if so, validate the checkbox
    const hasCheckbox = inputFields.some((field) => field.type === "checkbox");
    if (hasCheckbox) {
      setAllFieldsFilled(allFilled && formValues.agreedToTerms); // Ensure checkbox is checked if it exists
    } else {
      setAllFieldsFilled(allFilled); // Otherwise, just check if all fields are filled
    }
  }, [formValues, otherReason, inputFields]);

  const handleInputChange = (e, name, label) => {
    const { type, checked, value } = e.target;

    if (name === "fullName") {
      validateName(value);
    }

    if (name === "email") {
      validateEmail(value);
    }

    setFormValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [name]: type === "checkbox" ? checked : value,
      };

      // Show or hide the "Other" input box
      if (name === "reason" && value === "other") {
        setShowOtherInput(true);
        setOtherReason(""); // Reset the other reason input when 'Other' is selected
      } else if (name === "reason") {
        setShowOtherInput(false);
      }

      // If the field is 'password', validate it after updating
      if (name === "password" && inputFields[0].label !== "Current Password") {
        validatePassword(updatedValues.password);
      }
      // If the field is 'password', validate it after updating
      if (name === "newPassword" || label === "New Password") {
        if (value && value?.length > 0) {
          setShowPasswordCriteria(true); // Show criteria when typing starts
        } else {
          setShowPasswordCriteria(false); // Hide criteria when the field is empty
        }
        validatePassword(updatedValues.newPassword);
      }

      return updatedValues;
    });
  };

  const handleOtherReasonChange = (e) => {
    setOtherReason(e.target.value); // Update other reason state
  };

  const resetFormValues = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      email: "", // Clear the email field when transitioning to a new form
    }));
  };

  const handleClick = async (e) => {
    setErrorMessage("");
    setSuccessMessage("");

    if (buttonText === "Sign Up") {
      posthog.capture("Click on Sign Up Button");
    }
    if (buttonText === "Verify") {
      posthog.capture("Click on Verify Button");
    }
    if (buttonText === "Reset password") {
      posthog.capture("Click on Reset password Button");
    }
    if (buttonText === "Let's Wubble") {
      posthog.capture("Click on Let's Wubble Button");
      // dispatch(setSubscriptionModal(true));
      // navigate("/onboarding");
    }

    if (buttonText === "Sign in") {
      posthog.capture("Click on Sign in Button");
    }

    if (buttonText === "Join the waitlist") {
      posthog.capture("Click on Join the Waitlist Button");
    }

    if (buttonText === "Apply") {
      posthog.capture("Click on Apply Button");
    }

    if (formValues.reason === "other") {
      formValues.reason = otherReason;
    }

    if (requestData.taskName === "createProfile") {
      resetFormValues();
    }

    if (requestData.taskName === "deleteAccount") {
      try {
        const result = await apiService.deleteAccount(
          formValues.email,
          formValues.reason
        );

        if (result.success) {
          setSuccessMessage("Account deleted successfully");

          setTimeout(() => {
            logout();
            navigate("/");
            updateLocalState("isTeamMember", null);
            posthog.capture("Delete Account Success");
          }, 1300);

          // Handle successful account deletion (e.g., logout, redirect)
        } else {
          setErrorMessage(result.error || "Failed to delete account");
        }
        return;
      } catch (error) {
        setErrorMessage(
          error?.response?.data?.error ||
            "An error occurred while deleting the account"
        );
        return;
      }
    }

    if (requestData && requestData.taskName === "deleteProject") {
      if (onButtonClick && typeof onButtonClick === "function") {
        try {
          await onButtonClick(e); // Invoke the function passed in onButtonClick
          navigate("/projects");
        } catch (error) {
          console.error("Error during button click:", error);
          setErrorMessage("An error occurred during operation");
          return;
        }
      } else {
        console.error(`Function ${onButtonClick} not found or not a function`);
        return;
      }
    } else if (
      onButtonClick &&
      typeof functionMapper[onButtonClick] === "function"
    ) {
      try {
        const errorMessages = functionMapper[onButtonClick](formValues);

        if (errorMessages) {
          setErrorMessage(errorMessages);
          return;
        }
      } catch (error) {
        console.error("Error during validation:", error);
        setErrorMessage("An error occurred during validation");
        return;
      }
    } else {
      console.error(`Function ${onButtonClick} not found in functionMapper`);
      return;
    }

    // Extract form values only relevant to this form
    const formData = {};
    inputFields.forEach((field) => {
      if (field.type === "row") {
        field.fields.forEach((subField) => {
          formData[subField.name] = formValues[subField.name];
        });
      } else if (field.type !== "info") {
        formData[field.name] = formValues[field.name];
      }
    });

    if (requestData.taskName === "resetPassword") {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get("token");

      if (!token) {
        setErrorMessage("Invalid token");
        return;
      }

      try {
        const resetPasswordResult = await apiService.resetPassword({
          password: formData?.password,
          token,
        });

        if (resetPasswordResult.success) {
          setSuccessMessage(resetPasswordResult.success);
          posthog.capture("Password Reset successfully");
          // Execute onClick if provided
          if (onClick) {
            onClick(e);
          }
        } else if (resetPasswordResult.error) {
          posthog.capture("Password Reset Failed");
          setErrorMessage(resetPasswordResult.error);
        }
      } catch (error) {
        posthog.capture("Password Reset Failed");
        setErrorMessage(error.message);
      }
    } else if (requestData && requestData.taskRequestPasswordReset) {
      // const verifyEmailForPasswordReset = await apiService.sendRequest(
      //   requestData.taskName,
      //   { email: formData?.email } // Assuming you're passing the email for the check
      // );

      // if (verifyEmailForPasswordReset?.success === "Email verified") {
      //   setInfoMessage("Email verified");
      // } else {
      //   setInfoMessage("Email not found in records");
      //   return;
      // }

      try {
        const resetPasswordRequestResult =
          await apiService.requestPasswordReset(formData?.email);

        if (resetPasswordRequestResult.success) {
          setSuccessMessage(resetPasswordRequestResult.success);

          // Execute onClick if provided
          if (onClick) {
            onClick(e);
          }
        } else if (resetPasswordRequestResult.error) {
          setErrorMessage(resetPasswordRequestResult.error);
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    } else if (requestData && profileData) {
      try {
        const checkUserExistsResult = await apiService.sendRequest(
          requestData.taskCheckUserExists,
          { email: formData?.email } // Assuming you're passing the email for the check
        );

        if (checkUserExistsResult?.success === "User exists") {
          setInfoMessage("Already registered!");
          return;
        }

        // Check if the email is already in the waitlist
        const checkEmailResult = await apiService.sendRequest(
          requestData.taskCheckEmailInWaitlist,
          { email: formData?.email } // Assuming you're passing the email for the check
        );

        if (checkEmailResult?.success === "Email found in waitlist") {
          setInfoMessage("Already in the waitlist");
          return;
        }

        // Second API call
        const sendRequestResult2 = await apiService.sendRequest(
          requestData.taskName,
          { ...requestData.payload, ...formData }
        );

        // Profile submission
        const submitProfileResult = await apiService.submitProfile(formData);

        // Handle result from sendRequest if needed
        if (sendRequestResult2.error) {
          setErrorMessage(sendRequestResult2.error);
          return; // Exit if there's an error with any of the sendRequest calls
        }

        // Handle result from submitProfile
        if (submitProfileResult.error) {
          setErrorMessage(submitProfileResult.error);
        } else {
          setSuccessMessage(
            submitProfileResult.message || "Profile submitted successfully"
          );
        }

        // Execute onClick if provided
        if (onClick) {
          onClick(e);
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    } else if (requestData) {
      // Merge form values with static payload for requestData
      const mergedPayload = { ...requestData.payload, ...formData };

      if (userId) {
        mergedPayload.user_id = userId;
      }

      const user_id = getCookie("user_id");

      if (user_id && !userId) mergedPayload.user_id = user_id;

      try {
        const result = await apiService.sendRequest(
          requestData.taskName,
          mergedPayload
        );
        // console.log("Request successful:", result);

        if (result.success) {
          // console.log("Setting success message:", result.success);
          setSuccessMessage(result.success);
        }

        if (result.error) {
          setErrorMessage(result.error);
        } else {
          if (requestData.taskName === "createUserAccount" && result.token) {
            login(result.token, result.user_id); // Use the login function to set the token
            updateUserId(result.user_id);
            posthog.capture("User Sign up Success");
          }

          if (requestData.taskName === "createProfile" && result.success) {
            closeModal && closeModal(); // Close the modal on successful sign-in
            updateLocalState("profileCreated", true);

            const joiningOrganization =
              localState.joiningOrganization ||
              sessionStorage.getItem("joiningOrganization");

            if (joiningOrganization) {
              // Force reload the page using navigate
              navigate(0);
              sessionStorage.removeItem("joiningOrganization");
              return;
            }

            // Get the token from cookies
            const token = getCookie("token");

            // Check for pending invitation using the token
            const invitationResponse = await fetch(
              `${process.env.REACT_APP_SERVER_ENDPOINT}/api/fetchPendingInvitationToken`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`, // Include the authorization header
                },
                body: JSON.stringify({}), // No need to pass email
              }
            );

            const invitationData = await invitationResponse.json();

            if (invitationData?.isPending) {
              // Store the invitation token in local state
              updateLocalState("newUserInvited", true);
              // Navigate to the accept-invitation page
              navigate(
                "/accept-invitation?token=" +
                  invitationData.inviteLink.split("token=")[1]
              );
            } else {
              dispatch(setOpenOnboardingModal(true));
            }
          }

          if (requestData.taskName === "signIn" && result.token) {
            login(result.token, result.user_id); // Use the login function to set the token
            updateUserId(result.user_id);
            setSuccessMessage("Signed in successfully");
            closeModal && closeModal(); // Close the modal on successful sign-in

            posthog.capture("User Sign in Success");

            const joiningOrganization =
              localState.joiningOrganization ||
              sessionStorage.getItem("joiningOrganization");
            // console.log("Joining organization:", joiningOrganization);

            if (joiningOrganization) {
              // console.log("Navigating to join organization page");
              // Force reload the page using navigate
              navigate(0);
              sessionStorage.removeItem("joiningOrganization");
              return;
            }
          } else if (result.email && result.access_code) {
            setFormValues((prevValues) => ({
              ...prevValues,
              email: result.email,
            }));
            setSuccessMessage("Email retrieved successfully");
          } else if (result.user_id) {
            updateUserId(result.user_id);
            setSuccessMessage(result.success);
          } else if (requestData.taskName === "changePassword") {
            posthog.capture("Password Change Success");
            if (closeModal) {
              // Delay navigation to allow toast to display
              setTimeout(() => {
                closeModal();
              }, 1000);
            }
          } else if (requestData.taskName === "deleteAccount") {
            if (closeModal) {
              // Delay navigation to allow toast to display
              setTimeout(() => {
                closeModal();
                logout();
                navigate("/");
              }, 1000);
            }
          } else if (requestData.taskName === "deleteProject") {
            closeModal && closeModal();
          }

          if (onClickOption1 && result.message === "User already exists") {
            setInfoMessage("User already exists");
            // Delay navigation to allow toast to display
            setTimeout(() => {
              onClickOption1();
            }, 1000);
            return;
          }

          if (
            onClickOption2 &&
            result.message === "No record found for this email"
          ) {
            setInfoMessage("No record found for this email");
            setTimeout(() => {
              onClickOption2();
            }, 1000);
            return;
          }

          if (onClick) {
            // Delay navigation to allow toast to display
            setTimeout(() => {
              onClick(e);
            }, 1000);
          }
        }
      } catch (error) {
        setErrorMessage(error.message);
        if (requestData.taskName === "changePassword") {
          posthog.capture("Password Change Failed", {
            error: error?.message,
          });
        }
      }
    } else {
      setErrorMessage("No requestData or profileData provided");
    }

    if (path) {
      const joiningOrganization =
        localState.joiningOrganization ||
        sessionStorage.getItem("joiningOrganization");
      // console.log("Joining organization:", joiningOrganization);

      if (joiningOrganization) {
        // console.log("Navigating to join organization page");
        // Force reload the page using navigate
        navigate(0);
        sessionStorage.removeItem("joiningOrganization");
        return;
      }
      navigate(path);
    }
  };

  const togglePasswordVisibility = (name) => {
    setShowPasswords((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const isButtonDisabled = () => {
    if (allFieldsFilled && !nameError && !emailError) {
      return false;
    }
    return true;
  };

  const getButtonClassName = () => {
    let baseClass = `font-medium ${buttonClassName}`;
    if (allFieldsFilled && !nameError && !emailError) {
      if (enableConditionalBackground) {
        baseClass +=
          " bg-gradient-to-b from-[#fc10f2] to-[#3078e4] text-white hover:[background:linear-gradient(135deg,_#e509e4,_#256bb8)]";
      }
      if (enableConditionalBackgroundWhite) {
        baseClass += " bg-white text-black hover:bg-[#E0DFE1]";
      }
      if (
        enableConditionalBackgroundRed &&
        formValues["confirmDelete"] === "CONFIRM DELETE"
      ) {
        baseClass += " hover:bg-[#dd3f3f] bg-[#BB2D2D] px-3 text-white";
      }
    } else baseClass = baseClass + " text-[#878188] bg-[#4e4a4f]";
    return baseClass;
  };

  return (
    <div className="flex flex-col items-center w-full">
      <form
        className={formClassName}
        onSubmit={(e) => {
          e.preventDefault();
          handleClick(e); // Submit the form on Enter press
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleClick(e); // Trigger handleClick on Enter key press
          }
        }}
      >
        {inputFields.map((field, index) => {
          if (field.type === "row") {
            return (
              <div key={index} className={field.className}>
                {field.fields.map((subField, subIndex) => (
                  <div key={subIndex} className="flex-1 relative">
                    {subField.label && (
                      <label
                        htmlFor={subField.name}
                        className={subField.labelClassName}
                      >
                        {subField.label}
                      </label>
                    )}
                    <input
                      id={subField.name}
                      type={
                        showPasswords[subField.name] ? "text" : subField.type
                      }
                      name={subField.name}
                      className={subField.className}
                      placeholder={subField.placeholder}
                      value={formValues[subField.name]}
                      onChange={(e) =>
                        handleInputChange(e, subField.name, subField.label)
                      }
                      readOnly={subField.readOnly}
                      style={{ display: subField.display || "block" }}
                    />
                    {subField.type === "password" && (
                      <button
                        type="button"
                        className="absolute items-center right-3 text-[#878188] hover:text-white bottom-2.5"
                        onClick={() => togglePasswordVisibility(subField.name)}
                      >
                        {showPasswords[subField.name] ? (
                          <EyeSlash className="w-4 h-4" />
                        ) : (
                          <Eye className="w-4 h-4" />
                        )}
                      </button>
                    )}
                  </div>
                ))}
              </div>
            );
          } else if (field.type === "select") {
            return (
              <div key={index}>
                {field.label && (
                  <label htmlFor={field.name} className={field.labelClassName}>
                    {field.label}
                  </label>
                )}
                <div className="relative">
                  <select
                    id={field.name}
                    name={field.name}
                    className={`appearance-none text-white ${field.className}`}
                    value={formValues[field.name]}
                    onChange={(e) =>
                      handleInputChange(e, field.name, field.label)
                    }
                  >
                    {field.options.map((option, optIndex) => (
                      <option
                        className="text-[#878188] font-extralight"
                        key={optIndex}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <ChevronDown className="absolute right-3 bottom-2.5 w-4 h-4 text-gray-500 pointer-events-none" />
                </div>
                {showOtherInput && (
                  <div className="relative">
                    <label
                      htmlFor="otherReason"
                      className="block text-left ml-2 mb-2 mt-3 text-sm"
                    >
                      Please specify:
                    </label>
                    <input
                      id="otherReason"
                      name="reason"
                      className="bg-transparent border border-solid border-[#878188] w-full h-9 px-3 font-extralight text-sm text-white rounded-full placeholder-[#878188]"
                      placeholder="Specify your reason"
                      value={otherReason}
                      onChange={handleOtherReasonChange}
                    />
                  </div>
                )}
              </div>
            );
          } else if (field.type === "info") {
            return (
              <div key={index} className={field.className}>
                <p className="font-extralight">{field.content[0]}</p>
                <ul className="list-disc ml-5 mt-1 text-sm">
                  {field.content.slice(1).map((line, lineIndex) => (
                    <li key={lineIndex}>{line}</li>
                  ))}
                </ul>
                {field.subContent && (
                  <p className="mt-1 text-sm text-[#878188]">
                    {field.subContent}
                  </p>
                )}
              </div>
            );
          } else if (field.type === "checkbox") {
            return (
              <div
                key={index}
                className="flex mx-1 pt-1 text-left gap-2 text-[#C5C3C6] font-extralight"
              >
                <input
                  type="checkbox"
                  id={field.name}
                  name={field.name}
                  checked={formValues[field.name]}
                  defaultChecked={field.defaultChecked}
                  onChange={(e) =>
                    handleInputChange(e, field.name, field.label)
                  }
                  className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                {field.customLabel ? (
                  <label
                    htmlFor={field.name}
                    className={field.labelClassName}
                    dangerouslySetInnerHTML={{ __html: field.labelHtml }}
                  ></label>
                ) : (
                  <label htmlFor={field.name} className={field.labelClassName}>
                    {field.label}
                  </label>
                )}
              </div>
            );
          } else {
            return (
              <div key={index} className="relative">
                {field.label && (
                  <label htmlFor={field.name} className={field.labelClassName}>
                    {field.label}
                  </label>
                )}
                <input
                  id={field.name}
                  type={showPasswords[field.name] ? "text" : field.type}
                  name={field.name}
                  className={field.className}
                  placeholder={field.placeholder}
                  value={formValues[field.name] || ""}
                  onChange={(e) =>
                    handleInputChange(e, field.name, field.label)
                  }
                  readOnly={field.readOnly}
                  style={{ display: field.display || "block" }}
                />
                {field.type === "password" && (
                  <button
                    type="button"
                    className={`absolute items-center text-[#878188] hover:text-white right-3 top-[39px]`}
                    onClick={() => togglePasswordVisibility(field.name)}
                  >
                    {showPasswords[field.name] ? (
                      <EyeSlash className="w-4 h-4" />
                    ) : (
                      <Eye className="w-4 h-4" />
                    )}
                  </button>
                )}
                {field.label === "New Password" && showPasswordCriteria && (
                  <div className="mt-4">
                    {/* Progress bar */}
                    <div className="mx-1 bg-[#FFFFFF1A] rounded-full h-1.5">
                      <div
                        className="h-1.5 rounded-full"
                        style={{
                          width: `${progressPercentage}%`,
                          background:
                            "linear-gradient(135deg, #FC10F2 0%, #3078E4 100%)",
                        }}
                      ></div>
                    </div>
                    <p className="py-2 text-[#C5C3C6] text-left px-1 text-xs font-extralight leading-[17.5px]">
                      Use 8 or more characters with a mix of letters, uppercase,
                      numbers & symbols.
                    </p>
                  </div>
                )}
                {field.name === "fullName" && nameError && (
                  <div className="text-red-500 text-sm mt-1 ml-2 text-left">
                    {nameError}
                  </div>
                )}
                {field.name === "email" && emailError && (
                  <div className="text-red-500 text-sm mt-1 ml-2 text-left">
                    {emailError}
                  </div>
                )}
              </div>
            );
          }
        })}
      </form>
      {errorMessage && (
        <Toast
          type="error"
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}
      {infoMessage && (
        <Toast
          type="info"
          message={infoMessage}
          onClose={() => setInfoMessage("")}
        />
      )}
      {successMessage && (
        <Toast
          type="success"
          message={successMessage}
          onClose={() => setSuccessMessage("")}
        />
      )}
      <div className="flex gap-3 font-medium self-end">
        {cancelButtonText && (
          <button
            id={cancelButtonId}
            className={cancelButtonClassName}
            onClick={
              () =>
                closeModal
                  ? closeModal()
                  : trigger === "closeModal" && onClick && onClick() // Close modal if trigger is closeModal
            }
          >
            {cancelButtonText}
          </button>
        )}
        <button
          id={actionButtonId}
          className={getButtonClassName()}
          onClick={(e) => handleClick(e)}
          disabled={isButtonDisabled()}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default InputWithButton;
