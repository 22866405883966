import React, { useState, useEffect } from "react";
import {
  ArrowShapeDownToLine,
  ArrowsRotateLeft,
  ArrowsRotateRight,
  ThumbsUp,
  ThumbsDown,
  ThumbsUpFill,
  ThumbsDownFill,
  PlayFill,
  PauseFill,
  ChevronLeft,
  ChevronRight,
  Clock,
  FaceFun,
  EllipsisVertical,
  CircleExclamation,
} from "@gravity-ui/icons";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import { localState, useLocalState } from "../../contexts/LocalStateProvider";
import EditableInput from "./EditableInput";
import LoadingComponent from "./LoadingComponent";
import apiService from "../../api/apiService";
import { getCookie } from "../../utils/cookieUtils";
import Toast from "../generic_components/Toast";
import Modal from "../modal/Modal";
import FeedbackContent from "../modal/FeedbackContent"; // Assuming FeedbackContent is located in the modal folder
import ChatBoxLoader from "./ChatBoxLoader";
import { usePostHog } from "posthog-js/react";
import DownloadModal from "./DownloadModal";
import FeedbackPrompt from "./FeedbackPrompt";
import AudioProcessor from "./AudioProcessor";

const AIChatBox = ({
  message,
  relatedMessages = [],
  isLastMessage,
  loadingResponseId,
  beforeRegenerateLoaderId,
  streamingResponseId,
  onRegenerate,
  onErrorRegenerate,
  onBlankRegenerate,
  id,
  passedFromChatInterface,
  projectName,
  generationCount,
  projectId,
  onGenerationError,
  // checkGenerationStatus,
}) => {
  const {
    isPlaying,
    playAudio,
    pauseAudio,
    resumeAudio,
    audioSrc: currentAudioSrc,
    setAiResponseId,
    setDiscoverId,
    setIsBuffering,
    setStreamingSrc,
  } = useAudioPlayer();

  const posthog = usePostHog();
  const { localState, updateLocalState } = useLocalState();
  const [showDurationInput, setShowDurationInput] = useState(false);
  const [showTempoInput, setShowTempoInput] = useState(false);
  const [responses, setResponses] = useState([message]);
  const [currentResponseIndex, setCurrentResponseIndex] = useState(0);
  const [likeStatus, setLikeStatus] = useState(null);
  const [toastMessage, setToastMessage] = useState(null);
  const [newResponseAdded, setNewResponseAdded] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [streamingId, setStreamingId] = useState(null);
  const [currentResponseisStreaming, setCurrentResponseIsStreaming] = useState(
    message.isStreaming || false
  );
  const [streamComplete, setStreamComplete] = useState(false);
  const [likeStatuses, setLikeStatuses] = useState({});
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false); // Modal state for small screen options
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [downloadOptions, setDownloadOptions] = useState(null);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [showFeedbackPrompt, setShowFeedbackPrompt] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (!projectId) return;

    // Check if target generation exists for this project
    if (projectId && !localState.projectFeedbackTargets?.[projectId]) {
      const randomTarget = Math.random() < 0.5 ? 3 : 6;
      updateLocalState("projectFeedbackTargets", {
        ...localState.projectFeedbackTargets,
        [projectId]: randomTarget,
      });
    }

    const targetGeneration = localState.projectFeedbackTargets?.[projectId];
    // console.log("generation count", generationCount);
    // console.log("target generation for project", targetGeneration);

    if (
      generationCount === targetGeneration &&
      isLastMessage &&
      currentResponse.audio
    ) {
      setShowFeedbackPrompt(true);
    }
  }, [
    generationCount,
    currentResponse,
    isLastMessage,
    localState.projectFeedbackTargets,
    projectId,
  ]);

  const handleFeedbackDismiss = () => {
    setShowFeedbackPrompt(false);
    if (!likeStatuses[currentResponse.ai_response_id]) {
      posthog.capture("Click on Dismiss Icon button on First Time Feedback");
    }
  };

  // useEffect(() => {
  //   // Update responses state when message or relatedMessages change
  //   setResponses([message, ...relatedMessages]);
  //   console.log(responses);
  // }, [message, relatedMessages]);

  // Add useEffect to handle highlighting
  useEffect(() => {
    if (localState.scrollToAiResponse === message.ai_response_id) {
      setIsHighlighted(true);
      const element = document.getElementById(id);
      element?.scrollIntoView({ behavior: "smooth", block: "center" });

      setTimeout(() => {
        setIsHighlighted(false);
        updateLocalState("scrollToAiResponse", null);
      }, 2000);
    }
  }, [localState.scrollToAiResponse]);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const newMessages = [message, ...relatedMessages].filter(
      (msg) =>
        !responses.some(
          (response) => response.ai_response_id === msg.ai_response_id
        )
    );

    if (newMessages.length > 0) {
      // Append new messages to the existing ones
      setResponses((prevResponses) => [...prevResponses, ...newMessages]);
      setNewResponseAdded(true); // Set the flag if a new message is added
    }
  }, [message, relatedMessages]);

  useEffect(() => {
    if (
      localState.editedSongDetails &&
      localState.editedSongDetails.ai_response_id ===
        responses[currentResponseIndex].ai_response_id
    ) {
      updateResponseDetails(localState.editedSongDetails);
    }
  }, [localState.editedSongDetails]);

  useEffect(() => {
    if (newResponseAdded) {
      setCurrentResponseIndex(responses.length - 1);
      setNewResponseAdded(false); // Reset the flag after toggling
    }
  }, [responses, newResponseAdded]);

  // useEffect(() => {
  //   if (message.like_count > 0) {
  //     setLikeStatus("like");
  //   } else if (message.dislike_count > 0) {
  //     setLikeStatus("dislike");
  //   } else {
  //     setLikeStatus(null);
  //   }
  // }, [message]);

  useEffect(() => {
    const newLikeStatuses = { ...likeStatuses };
    [message, ...relatedMessages].forEach((msg) => {
      if (!newLikeStatuses.hasOwnProperty(msg.ai_response_id)) {
        if (msg.like_count > 0) {
          newLikeStatuses[msg.ai_response_id] = "like";
        } else if (msg.dislike_count > 0) {
          newLikeStatuses[msg.ai_response_id] = "dislike";
        } else {
          newLikeStatuses[msg.ai_response_id] = null;
        }
      }
    });
    setLikeStatuses(newLikeStatuses);
  }, [message, relatedMessages]);

  useEffect(() => {
    if (
      localState.playLatestTrack === message.ai_response_id &&
      currentResponse.isStreaming &&
      currentResponse.audio
    ) {
      handleStreamingAudio();
    }
  }, []);

  const monitorGenerationStatus = async (requestId, aiResponseId) => {
    console.log("Monitoring status for:", requestId, aiResponseId); // Add logging

    const pollInterval = setInterval(async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/api/check-generation-status?requestId=${requestId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCookie("token")}`,
            },
          }
        );

        const data = await response.json();

        if (!data.success) {
          console.log(
            "Calling onGenerationError with:",
            aiResponseId,
            data.message
          ); // Add logging

          clearInterval(pollInterval);
          onGenerationError(aiResponseId, data.message);
        }
      } catch (error) {
        console.error("Error checking generation status:", error);
      }
    }, 2000);

    setTimeout(() => clearInterval(pollInterval), 20000);
  };

  const handleStreamingAudio = async () => {
    if (
      streamingId === null &&
      streamingId !== currentResponse.ai_response_id
    ) {
      try {
        const response = await apiService.sendRequest("checkMusicFileExists", {
          ai_response_id: currentResponse.ai_response_id,
        });

        if (response.success && response.musicFileExists) {
          setCurrentResponseIsStreaming(false);
          setStreamComplete(true);
          setResponses((prevResponses) => {
            const updatedResponses = [...prevResponses];
            updatedResponses[currentResponseIndex] = {
              ...updatedResponses[currentResponseIndex],
              audio: {
                ...updatedResponses[currentResponseIndex].audio,
                src: `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-audio/${currentResponse.ai_response_id}`,
              },
            };
            return updatedResponses;
          });

          // Update the version history
          const updatedVersionHistory = localState.versionHistory.map(
            (entry) => {
              if (entry.ai_response_id === currentResponse.ai_response_id) {
                return {
                  ...entry,
                  audio: {
                    ...entry.audio,
                    src: `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-audio/${currentResponse.ai_response_id}`,
                  },
                };
              }
              return entry;
            }
          );

          updateLocalState("versionHistory", updatedVersionHistory);

          // passedFromChatInterface
          //   ? setToastMessage("Streaming audio is available from DB.")
          //   : setToastMessage(
          //       "Streaming audio is available from DB for reloaded page."
          //     );
          // Play the audio with the new source
          playAudio(
            `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-audio/${currentResponse.ai_response_id}`,
            currentResponse.audio.name,
            currentResponse.musicDescription,
            currentResponse.avatar,
            currentResponse.audio.duration
          );
          return;
        }
      } catch (error) {
        console.error("Error checking music file existence:", error);
      }
    }

    setStreamingId(currentResponse.ai_response_id);
    setIsBuffering(true);
    setStreamingSrc(currentResponse.audio.src);

    fetch(currentResponse.audio.src)
      .then(async (response) => {
        const reader = response.body.getReader();
        let firstChunkReceived = false;

        while (true) {
          // passedFromChatInterface
          //   ? setToastMessage("streaming from redis for newly generated audio")
          //   : setToastMessage("streaming from redis for reloaded page");
          const { done, value } = await reader.read();

          if (done) {
            setStreamingId(null);
            setIsBuffering(false);
            setStreamingSrc(null);
            break;
          }

          if (!firstChunkReceived && value) {
            firstChunkReceived = true;
            playAudio(
              currentResponse.audio.src,
              currentResponse.audio.name,
              currentResponse.musicDescription,
              currentResponse.avatar,
              currentResponse.audio.duration
            );
            continue;
          }
        }
      })
      .catch((error) => {
        console.error("Error streaming audio:", error);
        onGenerationError(
          currentResponse.ai_response_id,
          "Streaming timed out. Please try again."
        );
        updateLocalState("responseInProgress", false);
        setStreamingId(null);
        setIsBuffering(false);
        setStreamingSrc(null);
      });
  };

  const handlePlayPause = () => {
    setTimeout(() => {
      pageWidth >= 768 && updateLocalState("isDetailsPanelOpen", true);
      updateLocalState(
        "currentPlayingResponseId",
        responses[currentResponseIndex].ai_response_id
      );
    }, 50);

    setDiscoverId(null);

    if (
      isPlaying &&
      currentAudioSrc === responses[currentResponseIndex].audio.src
    ) {
      pauseAudio();
    } else if (currentAudioSrc === responses[currentResponseIndex].audio.src) {
      resumeAudio();
    } else {
      setAiResponseId(responses[currentResponseIndex].ai_response_id);

      if (message.isStreaming !== false) {
        if (currentResponseisStreaming) {
          const requestId =
            currentResponse.requestId ||
            currentResponse.request_id ||
            new URLSearchParams(currentResponse.audio.src.split("?")[1]).get(
              "requestId"
            );
          // checkGenerationStatus(requestId, currentResponse.ai_response_id);
          handleStreamingAudio();
        } else if (passedFromChatInterface && !streamComplete) {
          const requestId =
            currentResponse.requestId ||
            currentResponse.request_id ||
            new URLSearchParams(currentResponse.audio.src.split("?")[1]).get(
              "requestId"
            );
          // checkGenerationStatus(requestId, currentResponse.ai_response_id);
          // setToastMessage("streaming right now from ai chat box");
          handleStreamingAudio();
        } else if (passedFromChatInterface && streamComplete) {
          // setToastMessage(
          //   "Streaming audio is available from DB and not using handleStreamingAudio function"
          // );
          // console.log(responses[currentResponseIndex].audio.duration);

          playAudio(
            responses[currentResponseIndex].audio.src,
            responses[currentResponseIndex].audio.name,
            responses[currentResponseIndex].musicDescription,
            responses[currentResponseIndex].avatar,
            responses[currentResponseIndex].audio.duration
          );
        }
      } else {
        // setToastMessage(
        //   `Playing audio from DB where page reloaded and ${message.isStreaming}`
        // );
        // console.log(responses[currentResponseIndex].audio.duration);

        playAudio(
          responses[currentResponseIndex].audio.src,
          responses[currentResponseIndex].audio.name,
          responses[currentResponseIndex].musicDescription,
          responses[currentResponseIndex].avatar,
          responses[currentResponseIndex].audio.duration
        );
      }
    }
  };

  const updateResponseDetails = (newDetails) => {
    setResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      updatedResponses[currentResponseIndex] = {
        ...updatedResponses[currentResponseIndex],
        audio: {
          ...updatedResponses[currentResponseIndex].audio,
          name:
            newDetails.name ||
            updatedResponses[currentResponseIndex].audio.name,
          src:
            newDetails.audioSrc ||
            updatedResponses[currentResponseIndex].audio.src,
          duration:
            newDetails.duration ||
            updatedResponses[currentResponseIndex].audio.duration,
        },
        musicDescription:
          newDetails.description ||
          updatedResponses[currentResponseIndex].musicDescription,
        avatar:
          newDetails.coverImage ||
          updatedResponses[currentResponseIndex].avatar,
      };
      return updatedResponses;
    });

    // console.log(responses, "responses");
  };

  const handleActionClick = (action) => {
    // if (action === "Set duration") {
    //   setShowDurationInput(true);
    // } else if (action === "Set tempo") {
    //   setShowTempoInput(true);
    // } else {
    posthog.capture(`Click on Suggestion button chat page`, {
      action: action,
    });
    updateLocalState("textPrompt", action + " to ");
  };

  // console.log(message.text);

  const handleAudioClick = () => {
    const currentResponse = responses[currentResponseIndex];

    const detailsData = {
      name: currentResponse.audio.name,
      description: currentResponse.musicDescription,
      coverImage: currentResponse.avatar,
      duration: currentResponse.audio.duration,
      audioSrc: currentResponse.audio.src,
      ai_response_id: currentResponse.ai_response_id,
      projectId: currentResponse.projectId,
    };

    setTimeout(() => {
      if (!localState.isDetailsPanelOpen) {
        updateLocalState("isDetailsPanelOpen", true);
        posthog.capture("Click to open Details Panel");
      }
    }, 100);
    updateLocalState("currentSongDetails", detailsData); // Store details in local state
  };

  const handleLikeDislike = async (isLike) => {
    const userId = getCookie("user_id");
    const currentResponse = responses[currentResponseIndex];

    if (!userId || !currentResponse.ai_response_id) return;

    const payload = {
      response_id: currentResponse.ai_response_id,
      user_id: userId,
      is_like: isLike,
    };

    try {
      const currentLikeStatus = likeStatuses[currentResponse.ai_response_id];

      const currentLikedTracks = localState.likedProjectTracks || [];

      if (currentLikeStatus === (isLike ? "like" : "dislike")) {
        const currentLikedTracks = localState.likedProjectTracks || [];
        const updatedLikedTracks = currentLikedTracks.filter(
          (track) => track.response_id !== currentResponse.ai_response_id
        );
        updateLocalState("likedProjectTracks", updatedLikedTracks);

        // If the current state is already like/dislike, remove the entry
        await apiService.sendRequest("removeAIResponseLike", payload);

        setLikeStatuses((prev) => ({
          ...prev,
          [currentResponse.ai_response_id]: null,
        }));
      } else {
        posthog.capture("AI Chatbox Song Like/Dislike", {
          LikeStatus: isLike ? "like" : "dislike",
        });

        // Add to liked tracks only if it's a like and track doesn't exist
        if (
          isLike &&
          !currentLikedTracks.some(
            (track) => track.response_id === currentResponse.ai_response_id
          )
        ) {
          const likedTrack = {
            response_id: currentResponse.ai_response_id,
            name: currentResponse.audio.name,
            description: currentResponse.musicDescription,
            imageSrc: currentResponse.avatar,
            duration: currentResponse.audio.duration,
            audioSrc: currentResponse.audio.src,
            project_id: currentResponse.projectId,
            projectname: projectName,
            isLiked: true,
          };
          updateLocalState("likedProjectTracks", [
            likedTrack,
            ...currentLikedTracks,
          ]);
        }

        if (!isLike) {
          setIsFeedbackModalOpen(true);
          // Remove from liked tracks when disliking
          const updatedLikedTracks = currentLikedTracks.filter(
            (track) => track.response_id !== currentResponse.ai_response_id
          );
          updateLocalState("likedProjectTracks", updatedLikedTracks);
        }

        // Otherwise, add or update the like/dislike
        await apiService.sendRequest("updateAIResponseLike", payload);

        setLikeStatuses((prev) => ({
          ...prev,
          [currentResponse.ai_response_id]: isLike ? "like" : "dislike",
        }));
      }
    } catch (error) {
      console.error("Error updating like/dislike:", error);
    }
  };

  const handleDownload = async () => {
    const currentResponse = responses[currentResponseIndex];

    // posthog.capture("Chat Interface Download Button Clicked");

    if (streamingId === currentResponse.ai_response_id) {
      // If the audio is still streaming, show a message or prevent download
      setToastMessage("Audio is still streaming; download is not available.");
    } else if (currentResponse.audio) {
      try {
        setIsDownloading(true);
        // If streaming is done, download the complete audio file
        const response = await apiService.sendRequest(
          "fetchCompleteAIResponseAudio",
          {
            ai_response_id: currentResponse.ai_response_id,
          }
        );
        const { audioFile } = response;

        if (response.success) {
          const blob = new Blob([new Uint8Array(audioFile.data)], {
            type: "audio/wav",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = currentResponse.audio.name || "audio.wav";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          response?.message
            ? setToastMessage(response?.message)
            : setToastMessage("Failed to fetch the audio file for download.");
        }
      } catch (error) {
        console.error("Error downloading audio:", error);
        setToastMessage("An error occurred while downloading the audio.");
      } finally {
        setIsDownloading(false);
      }
    }
  };

  useEffect(() => {
    if (localState.playLatestTrack === message.ai_response_id) {
      handlePlayPause();
      updateLocalState("playLatestTrack", null);
    }
  }, [localState.playLatestTrack]);

  const triggerDownloadModal = () => {
    setIsDownloadModalOpen(true);
  };

  const handlePreviousResponse = () => {
    if (currentResponseIndex > 0) {
      setCurrentResponseIndex(currentResponseIndex - 1);
    }
  };

  const handleNextResponse = () => {
    if (currentResponseIndex < responses.length - 1) {
      setCurrentResponseIndex(currentResponseIndex + 1);
    }
  };

  const currentResponse = responses[currentResponseIndex];

  // If this response is the one currently loading, show the LoadingComponent
  const isLoading = loadingResponseId === currentResponse.ai_response_id;

  // Determine if this response is still streaming
  const isStreaming = currentResponseisStreaming
    ? streamingId === currentResponse.ai_response_id
    : streamingResponseId === currentResponse.ai_response_id;

  const loadingBeforeRegenerate =
    beforeRegenerateLoaderId === currentResponse.ai_response_id;

  if (loadingBeforeRegenerate) {
    return (
      <div className="flex-1 mt-auto flex items-center w-full max-w-screen-lg lg:max-w-screen-md">
        <ChatBoxLoader className={"w-full"} />
      </div>
    );
  }
  if (isLoading) {
    // If loading, render LoadingComponent and the current response text
    return (
      <div className="flex items-start hover:[background:var(--Color-Opacity-Primary-10,rgba(186,24,236,0.10))] transition duration-500 p-3 rounded-lg group relative w-full max-w-screen-lg lg:max-w-screen-md">
        <img
          src="/logo.png"
          alt="AI Avatar"
          className="w-8 h-8 rounded-full bg-[#2c2a2c] p-1"
        />
        <div className="flex-1 inline-block w-full">
          <div className="flex-col mt-1 mb-1 ml-3">
            <p className="font-extralight text-base text-white mb-3">
              {currentResponse.text}
            </p>
            <LoadingComponent />
          </div>
        </div>
      </div>
    );
  }

  const handleOptionsClick = () => {
    setIsOptionsModalOpen(true);
  };

  const handleOptionsClose = () => {
    setTimeout(() => {
      setIsOptionsModalOpen(false);
    }, 1000);
  };

  return (
    <div
      id={id}
      className={`mb-6 gap-2 flex flex-col w-full items-center max-w-screen-lg lg:max-w-screen-md relative`}
      style={
        isHighlighted
          ? {
              borderRadius: "10px",
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.72) 100%), linear-gradient(135deg, #FC10F2 0%, #3078E4 100%)",
            }
          : {}
      }
    >
      <div
        className={`flex items-start hover:[background:var(--Color-Opacity-Primary-10,rgba(186,24,236,0.10))] transition duration-500 p-3 rounded-lg group relative w-full max-w-screen-lg lg:max-w-screen-md`}
      >
        <img
          src="/logo.png"
          alt="AI Avatar"
          className="w-8 h-8 rounded-full bg-[#2c2a2c] p-1"
        />
        {message.is_incomplete ? (
          <div className="flex flex-col gap-2 px-3 pt-1">
            <p className="text-base text-white font-extralight">
              {message.text}
            </p>
            {isLastMessage && (
              <button
                onClick={() =>
                  onBlankRegenerate(message.ai_response_id, message.requestId)
                }
                className="flex w-24 p-1.5 rounded-lg gap-1 bg-white items-center border-none"
              >
                <ArrowsRotateRight className="w-3.5 h-3.5 text-black" />
                <span className="text-xs font-medium text-[#0f0e0f]">
                  Regenerate
                </span>
              </button>
            )}
          </div>
        ) : message.is_error ? (
          <div className="flex flex-col gap-2 px-3">
            <div className="flex p-2 w-full max-w-screen-md flex-col gap-3 justify-center items-start self-stretch shrink-0 bg-[rgba(51,0,0,0.5)] rounded-lg border border-[rgba(153,30,30,0.4)] relative z-4">
              <div className="flex w-full gap-2 flex-wrap items-center text-sm font-light text-left z-8">
                <CircleExclamation className="text-[#FF5454]" />
                <span className="text-sm font-light text-[#fff]">
                  Something went wrong! Please try again or contact
                </span>
                <a href="mailto:support@wubble.ai">
                  <span className="text-sm font-light text-[#e181ff] break-words hover:underline cursor-pointer">
                    support@wubble.ai
                  </span>
                </a>
                <span className="text-sm font-light text-[#fff]">
                  if the issue persists.
                </span>
              </div>
            </div>
            {/* {(message.current_session || isLastMessage) && (
              <button
                onClick={() => onErrorRegenerate(message.ai_response_id)}
                className="flex w-24 p-1.5 rounded-lg gap-1 bg-white items-center border-none relative z-9"
              >
                <div className="text-black">
                  <ArrowsRotateRight className="w-3.5 h-3.5" />
                </div>
                <span className="text-xs font-medium text-[#0f0e0f]">
                  Regenerate
                </span>
              </button>
            )} */}
          </div>
        ) : (
          <div className="flex-1 inline-block">
            <div className="flex-col mt-1 mb-1 ml-3">
              <p
                className={`font-extralight text-base text-white ${
                  currentResponse.audio ? "mb-3" : ""
                }`}
              >
                {currentResponse.text}
              </p>
              <div className="flex flex-row items-center md:w-fit w-full">
                <div
                  className={`self-stretch md:w-fit w-full ${
                    currentResponse.audio ? "mb-1" : ""
                  } rounded-lg inline-block`}
                >
                  {currentResponse.audio && (
                    <div
                      id="audio-container"
                      className="flex items-center overflow-hidden rounded-lg border border-solid border-[#3c393c] p-1.5 pr-2.5 relative md:w-fit w-full md:max-w-[400px]"
                    >
                      {isStreaming && (
                        <div className="absolute top-0 right-0 m-2">
                          <div className="relative">
                            <div
                              style={{
                                background:
                                  "linear-gradient(135deg, rgba(252,16,242,0.90), rgba(48,120,228,0.90))",
                              }}
                              className="w-2 h-2 bg-blue-500 rounded-full animate-ping"
                            ></div>
                            <div
                              style={{
                                background:
                                  "linear-gradient(135deg, rgba(252,16,242,0.90), rgba(48,120,228,0.90))",
                              }}
                              className="w-2 h-2 bg-blue-500 rounded-full absolute top-0 left-0"
                            ></div>
                          </div>
                        </div>
                      )}
                      <img
                        src={currentResponse.avatar}
                        alt="Cover"
                        className="w-11 h-11 object-cover rounded-md mr-2.5 transition-opacity duration-500 group-hover:opacity-60"
                      />
                      <div
                        id="open-details-panel-from-ai-chatbox"
                        className="flex flex-row cursor-pointer md:w-fit w-full gap-1"
                        onClick={() => {
                          handleAudioClick();
                        }}
                      >
                        <div className="flex flex-col w-full justify-center md:w-fit">
                          <h2 className="font-normal text-base md:w-fit w-full line-clamp-1">
                            {currentResponse.audio.name}
                          </h2>
                          <div className="flex flex-row w-full items-center gap-2 max-[640px]:gap-2">
                            <span className="font-extralight text-sm text-[#878188] w-full line-clamp-1">
                              {currentResponse.musicDescription}
                            </span>
                            <span className="flex gap-1 text-[#878188] font-extralight text-sm justify-center items-center mt-auto">
                              <Clock className="w-3.5" />
                              {currentResponse.audio.duration}
                            </span>
                          </div>
                        </div>
                      </div>
                      <button
                        id="play-pause-button"
                        onClick={() => {
                          handlePlayPause();
                        }}
                        className={`absolute flex left-[1.125rem] items-center text-white transition-transform duration-500 transform group-hover:translate-y-0 ${
                          isPlaying &&
                          currentAudioSrc === currentResponse.audio.src
                            ? ""
                            : "md:translate-y-[300%] md:group-hover:opacity-100"
                        }`}
                      >
                        {isPlaying &&
                        currentAudioSrc === currentResponse.audio.src ? (
                          <PauseFill className="w-4 h-4" />
                        ) : (
                          <PlayFill className="w-4 h-4" />
                        )}
                      </button>
                    </div>
                  )}

                  {relatedMessages.length > 0 && (
                    <div className="flex justify-start items-center mt-2 text-gray-400">
                      <button
                        id="previous-response-button"
                        onClick={handlePreviousResponse}
                        disabled={currentResponseIndex === 0}
                      >
                        <ChevronLeft className="w-4 h-4" />
                      </button>
                      <span className="text-sm font-extralight">
                        {currentResponseIndex + 1}/{responses.length}
                      </span>
                      <button
                        id="next-response-button"
                        onClick={handleNextResponse}
                        disabled={currentResponseIndex === responses.length - 1}
                      >
                        <ChevronRight className="w-4 h-4" />
                      </button>
                    </div>
                  )}
                </div>
                {/* Show ellipsis icon on small screens */}
                {currentResponse.audio && (
                  <div
                    id="ai-response-action-buttons"
                    className="flex space-x-2 md:hidden ml-2.5"
                  >
                    <button
                      id="options-button"
                      onClick={handleOptionsClick}
                      className="hover:text-white p-2 rounded-md text-[#878188]"
                    >
                      <EllipsisVertical />
                    </button>
                  </div>
                )}
                <div
                  id="ai-response-action-buttons"
                  className={`hidden md:flex ${
                    relatedMessages.length > 0
                      ? `pb-9 md:ml-2.5`
                      : currentResponse.audio && `pb-0 md:ml-2.5`
                  } space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-500`}
                >
                  {isLastMessage && currentResponse.audio && (
                    <button
                      id="regenerate-button"
                      onClick={() =>
                        onRegenerate(currentResponse.ai_response_id)
                      }
                      className="hover:text-white hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] p-2 rounded-full text-[#878188]"
                    >
                      <ArrowsRotateLeft />
                    </button>
                  )}
                  {currentResponse.audio && (
                    <div className="flex space-x-2">
                      <button
                        id="download-button"
                        onClick={triggerDownloadModal}
                        className="hover:text-white hover:bg-[rgba(255,255,255,0.05)] p-2 rounded-full text-[#878188]"
                        // disabled={isStreaming}
                      >
                        {isDownloading ? (
                          <div className="w-4 h-4 border-2 border-t-2 border-white border-t-transparent rounded-full animate-spin"></div>
                        ) : (
                          <ArrowShapeDownToLine />
                        )}
                      </button>
                      <button
                        id="like-button"
                        onClick={() => handleLikeDislike(true)}
                        className="hover:text-white hover:bg-[rgba(255,255,255,0.05)] p-2 rounded-full text-[#878188]"
                      >
                        {likeStatuses[currentResponse.ai_response_id] ===
                        "like" ? (
                          <ThumbsUpFill />
                        ) : (
                          <ThumbsUp />
                        )}
                      </button>
                      <button
                        id="dislike-button"
                        onClick={() => handleLikeDislike(false)}
                        className="hover:text-white hover:bg-[rgba(255,255,255,0.05)] p-2 rounded-full text-[#878188]"
                      >
                        {likeStatuses[currentResponse.ai_response_id] ===
                        "dislike" ? (
                          <ThumbsDownFill />
                        ) : (
                          <ThumbsDown />
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {isLastMessage && currentResponse.actions && (
              <div className="flex space-x-2 ml-3">
                {currentResponse.actions.map((action, index) => (
                  <div
                    key={index}
                    className="p-px hover:bg-gradient-to-r from-[#fc10f2] to-[#3078e4] transition duration-500 rounded-full"
                  >
                    <div className="flex bg-[#100f10] border border-solid border-[#2c2a2c] rounded-full items-center">
                      <button
                        id={`action-${action.replace(" ", "-")}`}
                        onClick={() => handleActionClick(action)}
                        className="flex flex-row h-8 items-center  md:p-0 md:px-2.5 p-2 gap-1.5 hover:text-white font-normal text-sm text-[#878188]"
                      >
                        {action === "Extend duration" && (
                          <>
                            <img
                              src="/ChangeDuration.svg"
                              className="h-4 w-4"
                              alt="Change duration"
                            />
                            <div className="hidden md:flex items-center gap-3.5">
                              <span className="hidden md:flex">Extend</span>
                              <div
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                                className="relative flex items-center"
                              >
                                <CircleExclamation className="h-4 w-4 text-[#878188] hover:text-white rotate-180 ml-1" />
                                {showTooltip && (
                                  <div className="absolute bg-[#2C2A2C] text-white text-xs p-1 pl-1.5 rounded top-8 left-0 whitespace-nowrap">
                                    3 mins tops, boss! 🔥
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        {action === "Change genre" && (
                          <>
                            <FaceFun className="h-4 w-4 text-[#878188]" />
                            <span className="hidden md:flex ">
                              Change genre
                            </span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {isDownloadModalOpen && (
          <AudioProcessor
            message={message}
            currentResponse={responses[currentResponseIndex]}
            onClose={() => setIsDownloadModalOpen(false)}
          />
        )}
        {toastMessage && (
          <Toast
            type="error"
            message={toastMessage}
            onClose={() => setToastMessage(null)}
          />
        )}

        {isFeedbackModalOpen && (
          <Modal
            closeModal={() => setIsFeedbackModalOpen(false)}
            showCloseButton={false}
          >
            <FeedbackContent
              responseId={currentResponse.ai_response_id}
              onClose={() => setIsFeedbackModalOpen(false)}
              onSubmit={async () => {
                // Add your submit logic here
                setIsFeedbackModalOpen(false);
              }}
            />
          </Modal>
        )}
        {/* Options Modal for small screens */}
        {isOptionsModalOpen && (
          <Modal closeModal={handleOptionsClose}>
            <div className="flex flex-col gap-0 w-full px-2 pt-10 pb-3 bg-[#1d1b1d] rounded-lg">
              {isLastMessage && currentResponse.audio && (
                <button
                  id="regenerate-button"
                  onClick={() => {
                    onRegenerate(currentResponse.ai_response_id);
                    handleOptionsClose();
                  }}
                  className="flex items-center gap-3 hover:text-white hover:bg-[rgba(255,255,255,0.05)] p-3 rounded-md text-[#878188] transition-colors duration-300"
                >
                  <ArrowsRotateLeft className="w-5 h-5" />
                  <span>Regenerate</span>
                </button>
              )}
              <button
                onClick={() => {
                  setIsDownloadModalOpen(true);
                  handleOptionsClose();
                }}
                className="flex items-center gap-3 hover:text-white hover:bg-[rgba(255,255,255,0.05)] p-3 rounded-md text-[#878188] transition-colors duration-300"
              >
                <ArrowShapeDownToLine className="w-5 h-5" />
                <span>Download</span>
              </button>
              <button
                id="like-button"
                onClick={() => {
                  handleLikeDislike(true);
                  handleOptionsClose();
                }}
                className="flex items-center gap-3 hover:text-white hover:bg-[rgba(255,255,255,0.05)] p-3 rounded-md text-[#878188] transition-colors duration-300"
              >
                {likeStatuses[currentResponse.ai_response_id] === "like" ? (
                  <ThumbsUpFill className="w-5 h-5" />
                ) : (
                  <ThumbsUp className="w-5 h-5" />
                )}
                <span>Like</span>
              </button>
              <button
                id="dislike-button"
                onClick={() => {
                  handleLikeDislike(false);
                  handleOptionsClose();
                }}
                className="flex items-center gap-3 hover:text-white hover:bg-[rgba(255,255,255,0.05)] p-3 rounded-md text-[#878188] transition-colors duration-300"
              >
                {likeStatuses[currentResponse.ai_response_id] === "dislike" ? (
                  <ThumbsDownFill className="w-5 h-5" />
                ) : (
                  <ThumbsDown className="w-5 h-5" />
                )}
                <span>Dislike</span>
              </button>
            </div>
          </Modal>
        )}
      </div>
      {showFeedbackPrompt && !message.is_error && isLastMessage && (
        <FeedbackPrompt
          onLike={() => {
            posthog.capture("Click on Like Icon button on First Time Feedback");
            handleLikeDislike(true);
          }}
          onDislike={() => {
            posthog.capture(
              "Click on Dislike Icon button on First Time Feedback"
            );
            handleLikeDislike(false);
          }}
          onDismiss={handleFeedbackDismiss}
          likeStatus={likeStatuses[currentResponse.ai_response_id]}
        />
      )}
    </div>
  );
};

export default AIChatBox;
