import React, { useEffect } from "react";
import {
  CircleExclamation,
  CircleInfo,
  CircleCheck,
  Xmark,
} from "@gravity-ui/icons";

const Toast = ({ type, message, onClose, className }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 3000); // Auto close after 3 seconds
    return () => clearTimeout(timer);
  }, [onClose]);

  let bgColor;
  switch (type) {
    case "success":
      bgColor =
        "bg-[#00A43133] backdrop-blur-[20px] text-white font-extralight";
      break;
    case "error":
      bgColor =
        "bg-[#DD3F3F33] backdrop-blur-[20px] text-white font-extralight";
      break;
    case "info":
      bgColor = "bg-white/10 backdrop-blur-[20px] text-white font-extralight";
      break;
    case "warning":
      bgColor =
        "bg-[#BB840533] backdrop-blur-[20px] text-white font-extralight";
      break;
    default:
      bgColor = "bg-gray-800";
  }

  return (
    <div
      id="toast"
      className={`fixed text-sm ${
        className ? className : `md:top-4 top-20 left-1/2`
      } transform -translate-x-1/2 ${bgColor} px-2 pl-3 py-1.5 gap-3 rounded-lg flex items-center w-[90%] md:w-fit md:min-w-[360px] md:text-nowrap z-40`}
    >
      {type === "error" && (
        <div className="flex py-1">
          <CircleExclamation className="w-4 h-4 text-[#FF5454]" />
        </div>
      )}
      {type === "warning" && (
        <div className="flex py-1">
          <CircleExclamation className="w-4 h-4 text-[#DDA012]" />
        </div>
      )}
      {type === "info" && (
        <div className="flex py-1">
          <CircleInfo className="w-4 h-4 text-white" />
        </div>
      )}
      {type === "success" && (
        <div className="flex py-1">
          <CircleInfo className="w-4 h-4 text-[#16F659]" />
        </div>
      )}
      <div className="w-full text-left">{message}</div>
      <button
        className="w-8 h-8 rounded-full items-center justify-center flex bg-white/5 hover:bg-white/10 text-[#878188] hover:text-white"
        onClick={onClose}
      >
        <Xmark className="w-4 h-4" />
      </button>
    </div>
  );
};

export default Toast;
