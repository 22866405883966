import React from "react";
import {
  Xmark,
  CircleCheck,
  PlayFill,
  CircleExclamation,
} from "@gravity-ui/icons";

const ProgressInfoToast = ({
  type,
  message,
  onClose,
  action,
  loading,
  excludeCloseButton,
  className,
}) => {
  const isPending = type === "info";
  const isError = type === "error";
  const isWarning = type === "warning";

  return (
    <div
      className={`fixed md:top-4 top-20 z-30 flex items-center gap-2 ${
        isPending
          ? "left-[50%] bg-[var(--Color-Primary-Opacity-Primary-10,rgba(186,24,236,0.10))] backdrop-blur-[10px]"
          : isError
          ? "left-[50%] bg-[rgba(255,0,0,0.12)] backdrop-blur-[10px]"
          : isWarning
          ? "bg-[#2C1F00] left-[50%] "
          : "[background:rgba(0,164,49,0.12)] backdrop-blur-[10px] left-[50%]"
      } rounded-lg shadow-lg px-4 pl-3 py-1.5 ${className}`}
    >
      <div className="flex items-center gap-2 w-full">
        {loading && (
          <div className="animate-spin rounded-full h-5 w-5">
            <img src="/Loading.png" alt="Loading" />
          </div>
        )}
        {type === "success" && (
          <div className="text-[#16F659]">
            <CircleCheck />
          </div>
        )}
        {type === "info" && !loading && (
          <div className="text-[#ffff]">
            <CircleExclamation />
          </div>
        )}
        {type === "warning" && (
          <div className="text-[#DDA012]">
            <CircleExclamation />
          </div>
        )}
        {type === "error" && (
          <div className="text-[#FF5454]">
            <CircleExclamation />
          </div>
        )}
        <span
          className={`text-sm font-extralight w-full ${
            type === "error"
              ? "text-white"
              : type === "warning"
              ? " text-white"
              : "text-white"
          }`}
        >
          {message}
        </span>
      </div>
      {(action || !excludeCloseButton) && (
        <div className="flex items-center gap-2">
          {action && (
            <button
              onClick={action.onClick}
              className="flex flex-row items-center gap-1 ml-2 text-black bg-white h-8 px-2.5 text-nowrap rounded-full hover:bg-white/90 text-sm font-normal"
            >
              {type === "success" && (
                <div className="text-black">
                  <PlayFill className="w-3.5 h-3.5" />
                </div>
              )}
              {action.label}
            </button>
          )}
          {!excludeCloseButton && (
            <button
              onClick={onClose}
              className="-mr-2 text-white/50 hover:text-white w-8 h-8 bg-white/5 hover:bg-white/10 rounded-full items-center justify-center flex"
            >
              <Xmark className="w-4 h-4" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ProgressInfoToast;
