import { ArrowRight, PencilToSquare, Plus } from "@gravity-ui/icons";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import apiService from "../../api/apiService";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { setTeamId } from "../../store/reducers/subscriptionSlice";
import { getCookie } from "../../utils/cookieUtils";
import Toast from "../generic_components/Toast";
import TeamPurchaseSummary from "./TeamPurchaseSummary";
import TermsOfUse from "./TermsOfUse";

// Replace with your Stripe publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const CreateOrganization = () => {
  // Step 1 form state
  const [orgData, setOrgData] = useState({
    name: "",
    description: "",
    logo: null,
    acceptedTerms: false,
    acceptedPrivacyPolicy: false,
  });

  const [isEditingName, setIsEditingName] = useState(true); // Start with editing mode by default
  const [isEditingDescription, setIsEditingDescription] = useState(false); // Initially not editing description
  const [emailError, setEmailError] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const dispatch = useDispatch();
  const { localState } = useLocalState();
  const { planDetails, selectedPlan, openInviteMemberModal, teamId } =
    useSelector((state) => state.subscription);
  const { team_id } = localState?.userProfileData;

  // Step 2 form state
  const [teamMembers, setTeamMembers] = useState({
    adminEmail: "",
    invitedEmails: [],
  });

  // can view this page only after login
  useEffect(() => {
    const token = getCookie("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  const [newEmail, setNewEmail] = useState("");

  // Track form steps
  const [step, setStep] = useState(1);

  const navigate = useNavigate();

  // Check if all required fields are filled
  const isFormValid = () => {
    return orgData.name && orgData.acceptedTerms;
  };

  // Handle organization form changes
  const handleOrgChange = (e) => {
    const { name, value, type, checked } = e.target;
    setOrgData({
      ...orgData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Handle file upload
  const handleLogoUpload = (e) => {
    setOrgData({
      ...orgData,
      logo: e.target.files[0],
    });
  };

  // Handle adding new team members
  const handleAddMember = async (email) => {
    if (email && !teamMembers.invitedEmails.includes(email)) {
      const currentUserEmail = await checkCurrentUserEmail();
      if (currentUserEmail && email === currentUserEmail) {
        setEmailError("You cannot invite yourself");
        return;
      }

      const isAssociated = await checkEmailAssociation(email);
      if (!isAssociated) {
        setTeamMembers({
          ...teamMembers,
          invitedEmails: [...teamMembers.invitedEmails, email],
        });
        setNewEmail(""); // Clear input field
        setEmailError(""); // Clear any previous error
      } else {
        setEmailError("This email is already associated with a team");
      }
    } else {
      setEmailError("This email is already in the invited list");
    }
  };

  const checkEmailAssociation = async (email) => {
    try {
      const response = await apiService.handleProtectedRoute(
        "checkEmailAssociation",
        { email }
      );
      return response.isInOrganization || response.hasPendingInvitations;
    } catch (error) {
      console.error("Error checking email association:", error);
      return false;
    }
  };

  const checkCurrentUserEmail = async () => {
    try {
      const response = await apiService.handleProtectedRoute(
        "fetchUserEmail",
        {}
      );
      return response.success ? response.email : null;
    } catch (error) {
      console.error("Error fetching current user email:", error);
      return null;
    }
  };

  // Utility function to validate email addresses
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // Handle step navigation
  const handleNextStep = async () => {
    if (step === 1) {
      const response = await apiService.createTeam({
        name: orgData.name,
        description: orgData.description,
        memberEmails: teamMembers.invitedEmails,
      });

      const { team_id } = response;
      dispatch(setTeamId(team_id));
      setStep(2);
    }
  };

  const handleSubmit = async () => {
    try {
      const currentUserEmail = await checkCurrentUserEmail();
      if (currentUserEmail) {
        setTeamMembers((prevTeamMembers) => ({
          ...prevTeamMembers,
          adminEmail: currentUserEmail,
        }));
      }
      setStep(3);
    } catch (error) {
      console.error("Error fetching current user email:", error);
    }
  };

  // // Handle form submission to API
  // const handleSubmit = async () => {
  //   // Prepare payload
  //   const payload = {
  //     name: orgData.name,
  //     description: orgData.description,
  //     logo: orgData.logo,
  //     memberEmails: teamMembers.invitedEmails,
  //   };

  //   try {
  //     // Call the createOrganization API
  //     const response = await apiService.createOrganization(payload);
  //     // You can handle further actions, such as redirecting the user or showing a success message.
  //     if (response.success) {
  //       setToastMessage("Team created successfully!");
  //       setToastType("success");
  //       setTimeout(() => {
  //         navigate("/");
  //         navigate(0);
  //       }, 1000);
  //     } else {
  //       setToastMessage(response.error || "Team creation failed!");
  //       setToastType("error");
  //     }
  //   } catch (error) {
  //     console.error("Error creating team:", error);
  //     setToastMessage(error.response.data.error || "Error creating team");
  //     setToastType("error");
  //   }
  // };

  const handleUpdateTeamMembers = (updatedTeamMembers) => {
    setTeamMembers(updatedTeamMembers);
    setStep(2);
  };

  const handleSubscribe = async () => {
    try {
      const stripe = await stripePromise;
      const priceId = planDetails.price; // Basic Plan Price ID
      const payment_type = `team_${selectedPlan
        ?.split("-")[1]
        ?.toLowerCase()}_${selectedPlan?.split("-")[0].toLowerCase()}`;
      console.log("priceId+++++++++++", planDetails, payment_type);
      const upgradeType = "purchase";

      // Get checkout session
      const checkoutResponse = await apiService.CreateTeamSubmission({
        teamId: team_id ?? teamId,
        priceId,
        memberCount: 1, // Include admin
        payment_type,
        upgradeType,
        invitedEmails: teamMembers.invitedEmails,
      });

      // Redirect to Stripe checkout using sessionId
      await stripe.redirectToCheckout({
        sessionId: checkoutResponse.sessionId,
      });
    } catch (error) {
      console.log("Error creating team application:", error);
      console.error("Error creating team:", error);
      setToastMessage(error.response?.data?.error || "Error creating team");
      setToastType("error");
    }
  };

  useEffect(() => {
    if (openInviteMemberModal) {
      setStep(2);
    }
  }, [openInviteMemberModal]);
  return (
    <div className="flex flex-row bg-[#1D1B1D] w-full text-white h-screen justify-center items-start overflow-hidden relative px-2 py-2">
      <div className="w-full max-w-3xl bg-[#100F10] px-4 py-4 rounded-lg ">
        <div className="flex justify-between items-center pb-3 border-b border-b-[#2C2A2C]">
          {/* Top Left: Logo and Text */}
          <div
            className="relative h-9 flex items-center gap-2 rounded-2.5"
            id="sidebar-logo"
            onClick={() => navigate("/")}
          >
            <img
              className="w-10 h-10 object-cover rounded-full cursor-pointer"
              src="/logo@2x.png"
              alt="Logo"
              id="sidebar-logo-image"
            />
            <img
              className="w-[5.9rem] h-8 object-cover z-10 hover:opacity-0 transition duration-500 cursor-pointer"
              src="/text_only_white.svg"
              alt="White Text"
              id="sidebar-logo-text-white"
            />
            <img
              className="absolute left-12 w-[5.9rem] h-8 object-cover cursor-pointer"
              src="/text_only_gradient.svg"
              alt="Gradient Text"
              id="sidebar-logo-text-gradient"
            />
          </div>

          <div className="flex items-center gap-2">
            <div
              className={`w-3 h-3 rounded-full ${
                step === 1 ? "bg-white" : "bg-[#3C393C]"
              }`}
            ></div>{" "}
            {/* Circle 1 */}
            <div
              className={`w-3 h-3 rounded-full ${
                step === 1 ? "bg-[#3C393C]" : "bg-white"
              }`}
            ></div>{" "}
            {/* Circle 2 */}
          </div>

          {/* Right: Continue Button */}
          <div>
            {step === 1 ? (
              <button
                onClick={handleNextStep}
                disabled={!isFormValid()} // Disable button if form is not valid
                className={`flex items-center text-sm font-normal h-9 px-3 rounded-full transition-all duration-500 ${
                  isFormValid()
                    ? "bg-gradient-to-b from-[#fc10f2] to-[#3078e4] text-white hover:[background:linear-gradient(135deg,#e509e4,#256bb8)]"
                    : "bg-[#4E4A4F] text-[#878188] cursor-not-allowed"
                }`}
              >
                Continue <ArrowRight className="ml-2" />
              </button>
            ) : step === 2 ? (
              <button
                onClick={() => handleSubmit()}
                className={`flex items-center text-sm font-normal h-9 px-3 rounded-full transition-all duration-500  ${"bg-gradient-to-b from-[#fc10f2] to-[#3078e4] text-white hover:[background:linear-gradient(135deg,#e509e4,#256bb8)]"}`}
              >
                Continute <ArrowRight className="ml-2" />
              </button>
            ) : (
              <button
                onClick={() => handleSubscribe()}
                className={`flex items-center text-sm font-normal h-9 px-3 rounded-full transition-all duration-500  ${"bg-gradient-to-b from-[#fc10f2] to-[#3078e4] text-white hover:[background:linear-gradient(135deg,#e509e4,#256bb8)]"}`}
              >
                Subscribe <ArrowRight className="ml-2" />
              </button>
            )}
          </div>
        </div>
        {step === 1 && (
          <div className="flex flex-col h-full py-6 scrollable-content max-h-[calc(100vh-82px)]">
            <h2 className="text-4xl font-medium mb-6">Create your team</h2>
            <form className="">
              <div className="flex flex-col px-3 py-1 bg-[#1D1B1D] rounded-lg mb-8">
                <div
                  className={`flex md:gap-0 gap-4 items-center justify-between border-b border-b-[#2C2A2C] py-5 ${
                    isEditingName ? "flex-col md:flex-row" : "flex-row"
                  }`}
                >
                  <div className="flex flex-col w-full">
                    <label className="block text-base font-light">Name</label>
                    <p className="text-sm font-extralight text-[#878188]">
                      The name of your team
                    </p>
                  </div>
                  {/* If editing mode is true, show input field */}
                  {isEditingName ? (
                    <div className="flex md:flex-row sm:flex-row flex-col gap-2.5 md:justify-end w-full">
                      <input
                        type="text"
                        name="name"
                        value={orgData.name}
                        onChange={handleOrgChange}
                        placeholder="Enter name of team"
                        className="flex md:max-w-60 px-3 w-full placeholder-[#878188] h-8 font-extralight text-sm rounded-full border border-solid border-[#878188] bg-transparent text-white"
                      />
                      <div className="flex flex-row gap-2 justify-end">
                        <button
                          className="text-sm px-2.5 h-8 [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] rounded-full hover:bg-white/10 hover:text-white"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsEditingName(false);
                          }} // Cancel button switches to non-edit mode
                          disabled={!orgData.name.trim()}
                        >
                          Cancel
                        </button>
                        <button
                          className="h-8 font-normal text-sm bg-white hover:bg-[#E0DFE1] px-2.5 text-black rounded-full"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsEditingName(false);
                          }} // Save button switches to non-edit mode
                          disabled={!orgData.name.trim()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center gap-3">
                      <span className="font-extralight text-sm md:text-nowrap sm:text-nowrap w-full text-right">
                        {orgData.name}
                      </span>{" "}
                      {/* Display the saved name */}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setIsEditingName(true);
                        }}
                      >
                        {/* Pencil icon to enable edit mode */}
                        <PencilToSquare className="hover:text-white text-[#878188] transition duration-200" />
                      </button>
                    </div>
                  )}
                </div>

                <div className="border-b border-b-[#2C2A2C] bg-[#1D1B1D] py-5">
                  <div className="flex justify-between items-center">
                    <div className="flex flex-row items-center gap-1">
                      <label className="text-base font-light">
                        Description
                      </label>
                      <p className="text-sm font-extralight text-[#878188]">
                        (optional)
                      </p>
                    </div>

                    {/* Pencil icon to toggle editing mode */}
                    {!isEditingDescription && (
                      <button onClick={() => setIsEditingDescription(true)}>
                        <PencilToSquare className="hover:text-white text-[#878188] transition duration-200" />
                      </button>
                    )}
                  </div>

                  <p className="text-sm font-extralight text-[#878188] mb-4">
                    Brief overview of your team
                  </p>

                  {/* If not in editing mode, display saved description below */}
                  {!isEditingDescription && orgData.description && (
                    <p className="text-sm text-white mt-2 font-extralight">
                      {orgData.description}
                    </p>
                  )}

                  {/* If editing mode is enabled, show textarea */}
                  {isEditingDescription && (
                    <div>
                      <textarea
                        name="description"
                        value={orgData.description}
                        onChange={handleOrgChange}
                        placeholder="Brief overview of your team"
                        className="w-full p-3 rounded-xl text-sm font-extralight placeholder-[#878188] bg-transparent text-white border border-[#878188] h-20 focus:outline-none"
                      />

                      <div className="flex justify-end gap-2 mt-2">
                        <button
                          className="text-sm px-2.5 h-8 [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] rounded-full hover:bg-white/10 hover:text-white"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsEditingDescription(false);
                          }} // Cancel button
                        >
                          Cancel
                        </button>
                        <button
                          className="h-8 text-sm bg-white hover:bg-[#E0DFE1] px-2.5 text-black rounded-full"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsEditingDescription(false);
                          }} // Save button
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-row justify-between items-center py-5">
                  {/* Left side: Logo label */}
                  <div className="flex items-center gap-1">
                    <label className="text-base font-light">Logo</label>
                    <p className="text-sm font-extralight text-[#878188]">
                      (optional)
                    </p>
                  </div>

                  {/* Right side: Logo Image or Placeholder */}
                  <div className="flex items-center relative">
                    {/* Conditionally render the image or placeholder */}
                    {orgData.logo ? (
                      <img
                        src={URL.createObjectURL(orgData.logo)} // Display the selected image
                        alt="Avatar"
                        className="w-14 h-14 object-cover rounded-full"
                      />
                    ) : (
                      <div className="flex w-14 h-14 bg-[#3C393C] items-center justify-center rounded-full">
                        <img src="Organization.svg" />
                      </div> // Placeholder div
                    )}

                    <input
                      type="file"
                      className="hidden"
                      id="file-input-logo" // Unique ID for file input
                      onChange={handleLogoUpload} // Handle file upload
                      accept="image/png, image/jpeg, image/webp"
                    />
                    <label
                      htmlFor="file-input-logo"
                      className="absolute left-7 bottom-0 p-2 bg-[#2C2A2C] rounded-full cursor-pointer"
                    >
                      <PencilToSquare className="text-white hover:text-[#878188] transition duration-200" />
                    </label>
                  </div>
                </div>
              </div>

              <div className="flex flex-col px-3 py-4 bg-[#1D1B1D] rounded-lg">
                <div className="flex font-extralight text-sm bg-[#100F10] border border-[#3C393C] rounded-lg p-2 mb-4 scrollable-content max-h-[calc(100vh-512px)]">
                  <TermsOfUse />
                </div>
                <label className="block px-1 py-2 font-extralight">
                  <input
                    type="checkbox"
                    name="acceptedTerms"
                    checked={orgData.acceptedTerms}
                    onChange={handleOrgChange}
                    className="mr-2 rounded"
                  />
                  I have read and accept the above{" "}
                  <a
                    href="https://info.wubble.ai/terms-of-use"
                    target="_blank"
                    class="underline text-white hover:font-medium"
                  >
                    Terms and Conditions
                  </a>
                </label>
              </div>
            </form>
          </div>
        )}

        {step === 2 && (
          <div className="flex flex-col h-full pt-6 scrollable-content max-h-[calc(100vh-82px)]">
            <h2 className="text-4xl font-medium mb-6">Invite your teammates</h2>
            <form>
              <p className="font-extralight text-sm mb-2">
                Improve music efficiency by adding more members!
              </p>
              <div className="flex flex-col bg-[#1D1B1D] rounded-lg px-3 py-1">
                <div className="py-5 rounded-t-lg border-b border-b-[#2C2A2C]">
                  <div className="flex flex-row items-center justify-between mb-1 pr-2">
                    <label className="block text-base font-light mb-1">
                      Invite new member
                    </label>
                    {emailError && (
                      <span className="text-red-400 text-sm font-extralight">
                        {emailError}
                      </span>
                    )}
                  </div>

                  <div className="flex items-center space-x-2 relative">
                    <input
                      type="email"
                      className="w-full px-3 h-9 rounded-full placeholder-[#878188] text-sm font-extralight bg-[#1D1B1D] text-white border border-[#4E4A4F] focus:outline-none"
                      placeholder="Enter email address to send invite"
                      value={newEmail}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && validateEmail(newEmail)) {
                          e.preventDefault();
                          handleAddMember(newEmail);
                        }
                      }}
                      onChange={(e) => {
                        e.stopPropagation();
                        setNewEmail(e.target.value);
                        setEmailError(""); // Clear error when input changes
                      }} // Update state when input changes
                    />
                    <button
                      type="button"
                      className={`absolute right-1 top-1 p-1.5 ${
                        newEmail
                          ? `bg-gradient-to-b from-[#fc10f2] to-[#3078e4] text-white hover:[background:linear-gradient(135deg,#e509e4,#256bb8)]`
                          : `bg-[#4E4A4F] text-[#878188]`
                      } rounded-full`}
                      onClick={(e) => {
                        if (validateEmail(newEmail)) {
                          handleAddMember(newEmail);
                        } else {
                          setEmailError("Invalid email address");
                        }
                      }}
                      // disabled={!validateEmail(newEmail)}
                    >
                      <Plus />
                    </button>
                  </div>
                </div>

                <div className="w-full bg-[#1D1B1D] rounded-b-lg">
                  {teamMembers.invitedEmails.length > 0 ? (
                    <div className="w-full flex flex-col">
                      {teamMembers.invitedEmails.map((email, index) => (
                        <div
                          key={index}
                          className={`w-full flex py-5 font-light ${
                            teamMembers.invitedEmails.length - 1 !== index &&
                            `border-b border-b-[#2C2A2C]`
                          } justify-between items-center text-[#878188]`}
                        >
                          {/* Left side: Invited label */}
                          <span className="text-[#878188] text-sm font-extralight w-2/3 ml-3">
                            Invited
                          </span>
                          {/* Right side: Email */}
                          <span className="text-[#878188] w-full text-sm font-extralight">
                            {email}
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-[#878188] font-normal text-sm px-3 py-5">
                      No members invited yet.
                    </p>
                  )}
                </div>
              </div>
            </form>
          </div>
        )}
        {step === 3 && (
          <TeamPurchaseSummary
            teamMembersInvited={teamMembers}
            onUpdateTeamMembers={handleUpdateTeamMembers}
            isCreateTeam={true}
          />
        )}
      </div>
      {toastMessage && (
        <Toast
          type={toastType}
          message={toastMessage}
          onClose={() => setToastMessage(null)}
        />
      )}
    </div>
  );
};

export default CreateOrganization;
