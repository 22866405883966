import React, { useState, useEffect } from "react";
import HorizontalCards from "./HorizontalCards";
import { ChevronRight, ArrowLeft } from "@gravity-ui/icons";
import DiscoverPage from "./DiscoverPage";
import CardsCollection from "./CardsCollection";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { usePostHog } from "posthog-js/react";
import { filter } from "lodash";

// const cards = [
//   {
//     id: 1,
//     image: "/project-images/Album_Art_6.webp?height=300&width=300",
//     title: "A Bed Of Clouds",
//     description: "Dreamy",
//     discoverId: "1",
//     audioSrc: `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-discover-audio/1`,
//   },
//   {
//     id: 2,
//     image: "/project-images/Album_Art_3.webp?height=300&width=300",
//     title: "Coming Home",
//     description: "Cozy, Romantic, Soft",
//     discoverId: "5",
//     audioSrc: `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-discover-audio/5`,
//   },
//   {
//     id: 3,
//     image: "/project-images/Album_Art_2.webp?height=300&width=300",
//     title: "Behind The Ice",
//     description: "Dreamy, Melancholic",
//     discoverId: "3",
//     audioSrc: `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-discover-audio/3`,
//   },
//   {
//     id: 4,
//     image: "/project-images/Album_Art_1.webp?height=300&width=300",
//     title: "Bounce Bounce",
//     description: "Bright, Calming",
//     discoverId: "4",
//     audioSrc: `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-discover-audio/4`,
//   },
//   {
//     id: 5,
//     image: "/project-images/Album_Art_16.webp?height=300&width=300",
//     title: "Down In The Mud",
//     description: "Energetic, Uplifting",
//     discoverId: "7",
//     audioSrc: `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-discover-audio/7`,
//   },
//   {
//     id: 6,
//     image: "/project-images/Album_Art_12.webp?height=300&width=300",
//     title: "Feel The Beat",
//     description: "Groovy, Playful",
//     discoverId: "8",
//     audioSrc: `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-discover-audio/8`,
//   },
// ];

const cards3 = [
  {
    id: 1,
    image: "/CircularPattern.svg?height=300&width=300",
    title: "Ads, Promos & Trailers",
    description: "This is a description for Card 1",
    filter: "Theme",
    videoSrc:
      "https://storage.googleapis.com/wubble-discover-files/Ads & Promo.mp4",
  },
  {
    id: 2,
    image: "/CircularPattern.svg?height=300&width=300",
    title: "Arts & Crafts",
    description: "This is a description for Card 1",
    filter: "Theme",
    videoSrc:
      "https://storage.googleapis.com/wubble-discover-files/Arts & Crafts.mp4",
  },
  {
    id: 3,
    image: "/CircularPattern.svg?height=300&width=300",
    title: "Broadcasting",
    description: "This is a description for Card 1",
    filter: "Theme",
    videoSrc:
      "https://storage.googleapis.com/wubble-discover-files/Broadcasting.mp4",
  },
  {
    id: 4,
    image: "/CircularPattern.svg?height=300&width=300",
    title: "Cinematic",
    description: "This is a description for Card 1",
    filter: "Theme",
    videoSrc:
      "https://storage.googleapis.com/wubble-discover-files/Cinematic.mp4",
  },
  {
    id: 5,
    image: "/CircularPattern.svg?height=300&width=300",
    title: "Classical",
    description: "This is a description for Card 1",
    filter: "Theme",
    videoSrc:
      "https://storage.googleapis.com/wubble-discover-files/Classical Collection.mp4",
  },
  {
    id: 6,
    image: "/CircularPattern.svg?height=300&width=300",
    title: "Cooking",
    description: "This is a description for Card 1",
    filter: "Theme",
    videoSrc:
      "https://storage.googleapis.com/wubble-discover-files/Cooking.mp4",
  },
  {
    id: 7,
    title: "Comedy",
    description: "This is a description for Card 1",
    videoSrc: "https://storage.googleapis.com/wubble-discover-files/Comedy.mp4",
    filter: "Theme",
  },
  {
    id: 8,
    title: "Corporate",
    description: "This is a description for Card 1",
    videoSrc:
      "https://storage.googleapis.com/wubble-discover-files/Corporate.mp4",
    filter: "Theme",
  },
  {
    id: 9,
    title: "Documentary",
    description: "This is a description for Card 1",
    videoSrc:
      "https://storage.googleapis.com/wubble-discover-files/Documentary.mp4",
    filter: "Theme",
  },
  {
    id: 10,
    title: "Drama",
    description: "This is a description for Card 1",
    videoSrc: "https://storage.googleapis.com/wubble-discover-files/Drama.mp4",
    filter: "Theme",
  },
  {
    id: 11,
    title: "Fashion",
    description: "This is a description for Card 1",
    videoSrc:
      "https://storage.googleapis.com/wubble-discover-files/Fashion & Beauty.mp4",
    filter: "Theme",
  },
];

const genreCards = [
  {
    id: 1,
    image: "https://storage.googleapis.com/wubble-discover-files/Acoustic.png",
    title: "Acoustic",
    background:
      "[background:linear-gradient(135deg,#62491F_0%,#B59563_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Acoustic",
    filter: "Genre",
  },
  {
    id: 2,
    image: "https://storage.googleapis.com/wubble-discover-files/Ambient.png",
    title: "Ambient",
    background:
      "[background:linear-gradient(135deg,#E9C4C5_0%,#C4D6E9_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Ambient",
    filter: "Genre",
  },
  {
    id: 3,
    image: "https://storage.googleapis.com/wubble-discover-files/Ballad.png",
    title: "Ballad",
    background:
      "[background:linear-gradient(135deg,#392822_0%,#392822_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Ballad",
    filter: "Genre",
  },
  {
    id: 4,
    image:
      "https://storage.googleapis.com/wubble-discover-files/Bedroom%20Pop.png",
    title: "Bedroom pop",
    background:
      " [background:linear-gradient(135deg,#898989_0%,#585858_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Bedroom Pop",
    filter: "Genre",
  },
  {
    id: 5,
    image: "https://storage.googleapis.com/wubble-discover-files/Beats.png",
    title: "Beats",
    background:
      "[background:linear-gradient(135deg,#554AA9_0%,#B985C2_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Bedroom Pop",
    filter: "Genre",
  },
  {
    id: 6,
    image: "https://storage.googleapis.com/wubble-discover-files/Blues.png",
    title: "Blues",
    background:
      " [background:linear-gradient(135deg,#B33C3E_0%,#763CB3_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Blues",
    filter: "Genre",
  },
  {
    id: 7,
    image: "https://storage.googleapis.com/wubble-discover-files/Chill.png",
    title: "Chill",
    background:
      "[background:linear-gradient(135deg,#4EBE2F_0%,#8D7B32_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Chill",
    filter: "Genre",
  },
  {
    id: 8,
    image: "https://storage.googleapis.com/wubble-discover-files/Children.png",
    title: "Children",
    background:
      "[background:linear-gradient(135deg,#B7123A_0%,#CD5BA6_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Children",
    filter: "Genre",
  },
  {
    id: 9,
    image: "https://storage.googleapis.com/wubble-discover-files/Cinematic.png",
    title: "Cinematic",
    background:
      "[background:linear-gradient(135deg,#2C7D94_0%,#02303D_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Cinematic",
    filter: "Genre",
  },
  {
    id: 10,
    image: "https://storage.googleapis.com/wubble-discover-files/Classical.png",
    title: "Classical",
    background:
      "[background:linear-gradient(135deg,#B8640A_0%,#3B1F01_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Classical",
    filter: "Genre",
  },
  {
    id: 11,
    image: "https://storage.googleapis.com/wubble-discover-files/Corporate.png",
    title: "Corporate",
    background:
      "[background:linear-gradient(135deg,#98ADBE_0%,#35A5FF_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Corporate",
    filter: "Genre",
  },
];

const genreCollectionCards = [
  {
    id: 1,
    image: "https://storage.googleapis.com/wubble-discover-files/Acoustic.png",
    title: "Acoustic",
    background:
      "[background:linear-gradient(135deg,#62491F_0%,#B59563_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Acoustic",
    filter: "Genre",
  },
  {
    id: 2,
    image: "https://storage.googleapis.com/wubble-discover-files/Ambient.png",
    title: "Ambient",
    background:
      "[background:linear-gradient(135deg,#E9C4C5_0%,#C4D6E9_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Ambient",
    filter: "Genre",
  },
  {
    id: 3,
    image: "https://storage.googleapis.com/wubble-discover-files/Ballad.png",
    title: "Ballad",
    background:
      "[background:linear-gradient(135deg,#392822_0%,#392822_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Ballad",
    filter: "Genre",
  },
  {
    id: 4,
    image:
      "https://storage.googleapis.com/wubble-discover-files/Bedroom%20Pop.png",
    title: "Bedroom pop",
    background:
      " [background:linear-gradient(135deg,#898989_0%,#585858_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Bedroom Pop",
    filter: "Genre",
  },
  {
    id: 5,
    image: "https://storage.googleapis.com/wubble-discover-files/Beats.png",
    title: "Beats",
    background:
      "[background:linear-gradient(135deg,#554AA9_0%,#B985C2_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Bedroom Pop",
    filter: "Genre",
  },
  {
    id: 6,
    image: "https://storage.googleapis.com/wubble-discover-files/Blues.png",
    title: "Blues",
    background:
      " [background:linear-gradient(135deg,#B33C3E_0%,#763CB3_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Blues",
    filter: "Genre",
  },
  {
    id: 7,
    image: "https://storage.googleapis.com/wubble-discover-files/Chill.png",
    title: "Chill",
    background:
      "[background:linear-gradient(135deg,#4EBE2F_0%,#8D7B32_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Chill",
    filter: "Genre",
  },
  {
    id: 8,
    image: "https://storage.googleapis.com/wubble-discover-files/Children.png",
    title: "Children",
    background:
      "[background:linear-gradient(135deg,#B7123A_0%,#CD5BA6_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Children",
    filter: "Genre",
  },
  {
    id: 9,
    image: "https://storage.googleapis.com/wubble-discover-files/Cinematic.png",
    title: "Cinematic",
    background:
      "[background:linear-gradient(135deg,#2C7D94_0%,#02303D_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Cinematic",
    filter: "Genre",
  },
  {
    id: 10,
    image: "https://storage.googleapis.com/wubble-discover-files/Classical.png",
    title: "Classical",
    background:
      "[background:linear-gradient(135deg,#B8640A_0%,#3B1F01_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Classical",
    filter: "Genre",
  },
  {
    id: 11,
    image: "https://storage.googleapis.com/wubble-discover-files/Corporate.png",
    title: "Corporate",
    background:
      "[background:linear-gradient(135deg,#98ADBE_0%,#35A5FF_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Corporate",
    filter: "Genre",
  },
  {
    id: 208,
    image: "https://storage.googleapis.com/wubble-discover-files/Country.png",
    title: "Country",
    background:
      "[background:linear-gradient(135deg,#98ADBE_0%,#35A5FF_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Country",
    filter: "Genre",
  },
  {
    id: 13,
    title: "Disco",
    image: "https://storage.googleapis.com/wubble-discover-files/Disco.png",
    background:
      "[background:linear-gradient(135deg,#4C3F6A_0%,#4C2A9B_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    filter: "Genre",
  },
  {
    id: 14,
    image:
      "https://storage.googleapis.com/wubble-discover-files/Electronic.png",
    title: "Electronic",
    background:
      "[background:linear-gradient(135deg,#05720E_0%,#DB27DB_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Electronic",
    filter: "Genre",
  },
  {
    id: 15,
    image: "https://storage.googleapis.com/wubble-discover-files/Fantasy.png",
    title: "Fantasy",
    background:
      "[background:linear-gradient(135deg,#B0CCD9_0%,#87CCEB_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Fantasy",
    filter: "Genre",
  },
  {
    id: 16,
    image: "https://storage.googleapis.com/wubble-discover-files/Folk.png",
    title: "Folk",
    background:
      "[background:linear-gradient(135deg,#24340C_0%,#90A66F_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Folk",
    filter: "Genre",
  },
  {
    id: 17,
    image: "https://storage.googleapis.com/wubble-discover-files/Funk.png",
    title: "Funk",
    background:
      "[background:linear-gradient(135deg,#B55C17_0%,#760506_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Funk",
    filter: "Genre",
  },
  {
    id: 18,
    image: "https://storage.googleapis.com/wubble-discover-files/Hip%20Hop.png",
    title: "Hip Hop",
    background:
      "[background:linear-gradient(135deg,#21156D_0%,#841245_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Hip Hop",
    filter: "Genre",
  },
  {
    id: 19,
    image: "https://storage.googleapis.com/wubble-discover-files/Holiday.png",
    title: "Holiday",
    background:
      "[background:linear-gradient(135deg,#958679_0%,#E1C1A6_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Holiday",
    filter: "Genre",
  },
  {
    id: 20,
    image: "https://storage.googleapis.com/wubble-discover-files/Indie.png",
    title: "Indie",
    background:
      "[background:linear-gradient(135deg,#57737E_0%,#57737E_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Indie",
    filter: "Genre",
  },
  {
    id: 21,
    image: "https://storage.googleapis.com/wubble-discover-files/Jazz.png",
    title: "Jazz",
    background:
      "[background:linear-gradient(135deg,#BE842F_0%,#9B610B_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Jazz",
    filter: "Genre",
  },
  {
    id: 22,
    image: "https://storage.googleapis.com/wubble-discover-files/Latin.png",
    title: "Latin",
    background:
      "[background:linear-gradient(135deg,#606060_0%,#3F372C_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Latin",
    filter: "Genre",
  },
  {
    id: 23,
    image: "https://storage.googleapis.com/wubble-discover-files/Lo-fi.png",
    title: "Lo-fi",
    background:
      "[background:linear-gradient(135deg,#6E2B3E_0%,#AB19BB_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Lo-fi Beats",
    filter: "Genre",
  },
  {
    id: 24,
    image: "https://storage.googleapis.com/wubble-discover-files/Lounge.png",
    title: "Lounge",
    background:
      "[background:linear-gradient(135deg,#71644C_0%,#7B898E_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Lounge Pop",
    filter: "Genre",
  },
  {
    id: 25,
    image: "https://storage.googleapis.com/wubble-discover-files/Pop.png",
    title: "Pop",
    background:
      "[background:linear-gradient(135deg,#99BDB5_0%,#99BDB5_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Pop",
    filter: "Genre",
  },
  {
    id: 26,
    image: "https://storage.googleapis.com/wubble-discover-files/Raggae12.png",
    title: "Reggae",
    background:
      "[background:linear-gradient(135deg,#079578_0%,#21F6A8_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Reggae",
    filter: "Genre",
  },
  {
    id: 27,
    image: "https://storage.googleapis.com/wubble-discover-files/Retro.png",
    title: "Retro",
    background:
      "[background:linear-gradient(135deg,#433F43_0%,#433F43_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Retro",
    filter: "Genre",
  },
  {
    id: 28,
    image: "https://storage.googleapis.com/wubble-discover-files/Rock.png",
    title: "Rock",
    background:
      "[background:linear-gradient(135deg,#E76755_0%,#9438DE_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Rock",
    filter: "Genre",
  },
  {
    id: 29,
    image: "https://storage.googleapis.com/wubble-discover-files/RnB.png",
    title: "RnB",
    background:
      "[background:linear-gradient(135deg,#54322E_0%,#54322E_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)] ",
    description: "This is a description for RnB",
    filter: "Genre",
  },
  {
    id: 30,
    image: "https://storage.googleapis.com/wubble-discover-files/Shoegaze.png",
    title: "Shoegaze",
    background:
      "[background:linear-gradient(135deg,#CE7266_0%,#CE7266_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Shoegaze",
    filter: "Genre",
  },
  {
    id: 31,
    image: "https://storage.googleapis.com/wubble-discover-files/Soul.png",
    title: "Soul",
    background:
      "[background:linear-gradient(0deg,rgba(16,15,16,0.60)_0%,rgba(16,15,16,0.10)_100%)]",
    description: "This is a description for Soul",
    filter: "Genre",
  },
  {
    id: 32,
    image: "https://storage.googleapis.com/wubble-discover-files/Swing.png",
    title: "Swing",
    background:
      "[background:linear-gradient(135deg,#91794A_0%,#91794A_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Swing",
    filter: "Genre",
  },
  {
    id: 33,
    image: "https://storage.googleapis.com/wubble-discover-files/Techno.png",
    title: "Techno",
    background:
      "[background:linear-gradient(135deg,#0E91A7_0%,#0E4BA7_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Techno",
    filter: "Genre",
  },
  {
    id: 34,
    image: "https://storage.googleapis.com/wubble-discover-files/World.png",
    title: "World",
    background:
      "[background:linear-gradient(135deg,#CF7B66_0%,#5B1A09_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for World",
    filter: "Genre",
  },
  {
    id: 35,
    image: "https://storage.googleapis.com/wubble-discover-files/Worship.png",
    title: "Worship",
    background:
      "[background:linear-gradient(135deg,#EFB849_0%,#8D6922_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Worship",
    filter: "Genre",
  },
];

const moodCards = [
  {
    id: 1,
    title: "Happy",
    description: "This is a description for Card 1",
    filter: "Mood",
    videoSrc: "https://storage.googleapis.com/wubble-discover-files/Happy.mp4",
  },
  // {
  //   id: 2,
  //   title: "Sad",
  //   description: "This is a description for Card 1",
  //   filter: "Mood",
  //   videoSrc: "https://storage.googleapis.com/wubble-discover-files/Sad.mp4",
  // },
  {
    id: 3,
    title: "Sentimental",
    description: "This is a description for Card 1",
    filter: "Mood",
    videoSrc:
      "https://storage.googleapis.com/wubble-discover-files/Sentimental.mp4",
  },
  {
    id: 4,
    title: "Angry",
    description: "This is a description for Card 1",
    filter: "Mood",
    videoSrc: "https://storage.googleapis.com/wubble-discover-files/Angry.mp4",
  },
  {
    id: 5,
    title: "Scary",
    description: "This is a description for Card 1",
    filter: "Mood",
    videoSrc: "https://storage.googleapis.com/wubble-discover-files/Scary.mp4",
  },
  {
    id: 6,
    title: "Epic",
    description: "This is a description for Card 1",
    filter: "Mood",
    videoSrc: "https://storage.googleapis.com/wubble-discover-files/Epic.mp4",
  },
  {
    id: 7,
    title: "Chasing",
    videoSrc:
      "https://storage.googleapis.com/wubble-discover-files/Chasing.mp4",
    filter: "Mood",
  },
  {
    id: 8,
    title: "Chill",
    videoSrc: "https://storage.googleapis.com/wubble-discover-files/Chill.mp4",
    filter: "Mood",
  },
  {
    id: 9,
    title: "Cozy",
    videoSrc: "https://storage.googleapis.com/wubble-discover-files/Cozy.mp4",
    filter: "Mood",
  },
  {
    id: 10,
    title: "Dramatic",
    videoSrc:
      "https://storage.googleapis.com/wubble-discover-files/Dramatic.mp4",
    filter: "Mood",
  },
];

const moodCollectionCards = [
  {
    id: 1,
    title: "Angry",
    video: "https://storage.googleapis.com/wubble-discover-files/Angry.mp4",
    filter: "Mood",
  },
  {
    id: 2,
    title: "Bright",
    video: "https://storage.googleapis.com/wubble-discover-files/Bright 2.mp4",
    filter: "Mood",
  },
  {
    id: 3,
    title: "Busy",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Busy & Frantic.mp4",
    filter: "Mood",
  },
  {
    id: 4,
    title: "Calming",
    video: "https://storage.googleapis.com/wubble-discover-files/Calming.mp4",
    filter: "Mood",
  },
  {
    id: 5,
    title: "Carefree",
    video: "https://storage.googleapis.com/wubble-discover-files/Carefree.mp4",
    filter: "Mood",
  },
  // {
  //   id: 6,
  //   title: "Changing Tempo",
  //   video:
  //     "https://storage.googleapis.com/wubble-discover-files/Changing Tempo.mp4",
  //   filter: "Mood",
  // },
  {
    id: 7,
    title: "Chasing",
    video: "https://storage.googleapis.com/wubble-discover-files/Chasing.mp4",
    filter: "Mood",
  },
  {
    id: 8,
    title: "Chill",
    video: "https://storage.googleapis.com/wubble-discover-files/Chill.mp4",
    filter: "Mood",
  },
  {
    id: 9,
    title: "Cozy",
    video: "https://storage.googleapis.com/wubble-discover-files/Cozy.mp4",
    filter: "Mood",
  },
  // {
  //   id: 10,
  //   title: "Dark",
  //   video: "https://storage.googleapis.com/wubble-discover-files/Dark.mp4",
  //   filter: "Mood",
  // },
  {
    id: 11,
    title: "Dramatic",
    video: "https://storage.googleapis.com/wubble-discover-files/Dramatic.mp4",
    filter: "Mood",
  },
  {
    id: 12,
    title: "Dreamy",
    video: "https://storage.googleapis.com/wubble-discover-files/Dreamy.mp4",
    filter: "Mood",
  },
  {
    id: 13,
    title: "Eccentric",
    video: "https://storage.googleapis.com/wubble-discover-files/Eccentric.mp4",
    filter: "Mood",
  },
  {
    id: 14,
    title: "Elegant",
    video: "https://storage.googleapis.com/wubble-discover-files/Elegant.mp4",
    filter: "Mood",
  },
  {
    id: 15,
    title: "Energetic",
    video: "https://storage.googleapis.com/wubble-discover-files/Energetic.mp4",
    filter: "Mood",
  },
  {
    id: 16,
    title: "Epic",
    video: "https://storage.googleapis.com/wubble-discover-files/Epic.mp4",
    filter: "Mood",
  },
  {
    id: 17,
    title: "Euphoric",
    video: "https://storage.googleapis.com/wubble-discover-files/Euphoric.mp4",
    filter: "Mood",
  },
  {
    id: 18,
    title: "Fear",
    video: "https://storage.googleapis.com/wubble-discover-files/Fear.mp4",
    filter: "Mood",
  },
  {
    id: 19,
    title: "Floating",
    video: "https://storage.googleapis.com/wubble-discover-files/Floating.mp4",
    filter: "Mood",
  },
  {
    id: 20,
    title: "Funny",
    video: "https://storage.googleapis.com/wubble-discover-files/Funny.mp4",
    filter: "Mood",
  },
  {
    id: 20,
    title: "Glamorous",
    video: "https://storage.googleapis.com/wubble-discover-files/Glamorous.mp4",
    filter: "Mood",
  },
  {
    id: 22,
    title: "Groovy",
    video: "https://storage.googleapis.com/wubble-discover-files/Groovy.mp4",
    filter: "Mood",
  },
  {
    id: 23,
    title: "Grunge",
    video: "https://storage.googleapis.com/wubble-discover-files/Grunge.mp4",
    filter: "Mood",
  },
  {
    id: 24,
    title: "Happy",
    video: "https://storage.googleapis.com/wubble-discover-files/Happy.mp4",
    filter: "Mood",
  },
  {
    id: 25,
    title: "Heavy",
    video: "https://storage.googleapis.com/wubble-discover-files/Heavy.mp4",
    filter: "Mood",
  },
  {
    id: 27,
    title: "Hopeful",
    video: "https://storage.googleapis.com/wubble-discover-files/Hopeful.mp4",
    filter: "Mood",
  },
  // {
  //   id: 28,
  //   title: "Horror",
  //   video: "https://storage.googleapis.com/wubble-discover-files/Horror.mp4",
  //   filter: "Mood",
  // },
  {
    id: 29,
    title: "Laid back",
    video: "https://storage.googleapis.com/wubble-discover-files/Laid Back.mp4",
    filter: "Mood",
  },
  {
    id: 30,
    title: "Love",
    video: "https://storage.googleapis.com/wubble-discover-files/Love.mp4",
    filter: "Mood",
  },
  {
    id: 300,
    title: "Marching",
    video: "https://storage.googleapis.com/wubble-discover-files/Marching.mp4",
    filter: "Mood",
  },
  {
    id: 32,
    title: "Melancholic",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Melancholic.mp4",
    filter: "Mood",
  },
  {
    id: 33,
    title: "Mysterious",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Mysterious-1.mp4",
    filter: "Mood",
  },
  {
    id: 34,
    title: "Peaceful",
    video: "https://storage.googleapis.com/wubble-discover-files/Peaceful.mp4",
    filter: "Mood",
  },
  {
    id: 35,
    title: "Playful",
    video: "https://storage.googleapis.com/wubble-discover-files/Playful.mp4",
    filter: "Mood",
  },
  {
    id: 36,
    title: "Powerful",
    video: "https://storage.googleapis.com/wubble-discover-files/Powerful.mp4",
    filter: "Mood",
  },
  {
    id: 37,
    title: "Quirky",
    video: "https://storage.googleapis.com/wubble-discover-files/Quirky.mp4",
    filter: "Mood",
  },
  {
    id: 38,
    title: "Relaxing",
    video: "https://storage.googleapis.com/wubble-discover-files/Relaxing.mp4",
    filter: "Mood",
  },
  {
    id: 39,
    title: "Restless",
    video: "https://storage.googleapis.com/wubble-discover-files/Restless.mp4",
    filter: "Mood",
  },
  // {
  //   id: 40,
  //   title: "Romantic",
  //   video: "https://storage.googleapis.com/wubble-discover-files/Romantic.mp4",
  //   filter: "Mood",
  // },
  // {
  //   id: 41,
  //   title: "Running",
  //   video: "https://storage.googleapis.com/wubble-discover-files/Running.mp4",
  //   filter: "Mood",
  // },
  // {
  //   id: 42,
  //   title: "Sad",
  //   video: "https://storage.googleapis.com/wubble-discover-files/Sad.mp4",
  //   filter: "Mood",
  // },
  {
    id: 43,
    title: "Scary",
    video: "https://storage.googleapis.com/wubble-discover-files/Scary.mp4",
    filter: "Mood",
  },
  {
    id: 44,
    title: "Sentimental",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Sentimental.mp4",
    filter: "Mood",
  },
  {
    id: 45,
    title: "Serious",
    video: "https://storage.googleapis.com/wubble-discover-files/Serious.mp4",
    filter: "Mood",
  },
  {
    id: 46,
    title: "Sexy",
    video: "https://storage.googleapis.com/wubble-discover-files/Sexy.mp4",
    filter: "Mood",
  },
  {
    id: 47,
    title: "Smooth",
    video: "https://storage.googleapis.com/wubble-discover-files/Smooth.mp4",
    filter: "Mood",
  },
  {
    id: 48,
    title: "Sneaking",
    video: "https://storage.googleapis.com/wubble-discover-files/Sneaking.mp4",
    filter: "Mood",
  },
  {
    id: 49,
    title: "Soft",
    video: "https://storage.googleapis.com/wubble-discover-files/Soft.mp4",
    filter: "Mood",
  },
  {
    id: 50,
    title: "Suspense",
    video: "https://storage.googleapis.com/wubble-discover-files/Suspense.mp4",
    filter: "Mood",
  },
  {
    id: 51,
    title: "Tense",
    video: "https://storage.googleapis.com/wubble-discover-files/Tense.mp4",
    filter: "Mood",
  },
  {
    id: 52,
    title: "Uplifting",
    video: "https://storage.googleapis.com/wubble-discover-files/Uplifting.mp4",
    filter: "Mood",
  },
  {
    id: 53,
    title: "Weird",
    video: "https://storage.googleapis.com/wubble-discover-files/Weird.mp4",
    filter: "Mood",
  },
];

const instrumentCards = [
  {
    id: 1,
    image:
      "https://storage.googleapis.com/wubble-discover-files/Acoustic Guitar.png",
    title: "Acoustic Guitar",
    background:
      "[background:linear-gradient(135deg,#180A08_0%,#BE331F_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Card 1",
    filter: "Instrument",
  },
  {
    id: 2,
    image:
      "https://storage.googleapis.com/wubble-discover-files/Acoustic Drums.png",
    title: "Drums",
    background:
      "[background:linear-gradient(135deg,#B6948A_0%,#AE9167_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Card 1",
    filter: "Instrument",
  },
  {
    id: 3,
    image: "https://storage.googleapis.com/wubble-discover-files/Banjo.png",
    title: "Banjo",
    background:
      "[background:linear-gradient(135deg,#2C3931_0%,#415C4C_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Card 1",
    filter: "Instrument",
  },
  {
    id: 4,
    image: "https://storage.googleapis.com/wubble-discover-files/Bass.png",
    title: "Bass",
    background:
      "[background:linear-gradient(135deg,#B9B9B9_0%,#2B2B2B_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Card 1",
    filter: "Instrument",
  },
  {
    id: 5,
    image: "https://storage.googleapis.com/wubble-discover-files/Bells.png",
    title: "Bells",
    background:
      "[background:linear-gradient(135deg,#A78357_0%,#805C30_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Card 1",
    filter: "Instrument",
  },
  {
    id: 6,
    image: "https://storage.googleapis.com/wubble-discover-files/Brass.png",
    title: "Brass",
    background:
      "[background:linear-gradient(135deg,#816F5E_0%,#D8BC5E_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Card 1",
    filter: "Instrument",
  },
  {
    id: 600,
    image: "https://storage.googleapis.com/wubble-discover-files/Clap.png",
    title: "Clap",
    background:
      "[background:linear-gradient(135deg,#816F5E_0%,#D8BC5E_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Card 1",
    filter: "Instrument",
  },
  {
    id: 8,
    image:
      "https://storage.googleapis.com/wubble-discover-files/Electric Guitar.png",
    title: "Electric Guitar",
    background:
      "[background:linear-gradient(135deg,#8D7B32_0%,#736735_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Electric Guitar",
    filter: "Instrument",
  },
  {
    id: 9,
    image:
      "https://storage.googleapis.com/wubble-discover-files/Electronic Drums.png",
    title: "Electric Drums",
    background:
      "[background:linear-gradient(135deg,#4D0561_0%,#430431_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Electronic Drums",
    filter: "Instrument",
  },
  {
    id: 10,
    image: "https://storage.googleapis.com/wubble-discover-files/Ethnic.png",
    title: "Ethnic",
    background:
      "[background:linear-gradient(135deg,#5B2C0F_0%,#E3712A_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Ethnic instruments",
    filter: "Instrument",
  },
  {
    id: 11,
    image: "https://storage.googleapis.com/wubble-discover-files/Keys.png",
    title: "Keys",
    background:
      "[background:linear-gradient(135deg,#A5A7AC_0%,#A5A7AC_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Keys",
    filter: "Instrument",
  },
];

const instrumentCollectionCards = [
  {
    id: 1,
    image:
      "https://storage.googleapis.com/wubble-discover-files/Acoustic Guitar.png",
    title: "Acoustic Guitar",
    background:
      "[background:linear-gradient(135deg,#180A08_0%,#BE331F_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Card 1",
    filter: "Instrument",
  },
  {
    id: 2,
    image:
      "https://storage.googleapis.com/wubble-discover-files/Acoustic Drums.png",
    title: "Drums",
    background:
      "[background:linear-gradient(135deg,#B6948A_0%,#AE9167_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Card 1",
    filter: "Instrument",
  },
  {
    id: 3,
    image: "https://storage.googleapis.com/wubble-discover-files/Banjo.png",
    title: "Banjo",
    background:
      "[background:linear-gradient(135deg,#2C3931_0%,#415C4C_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Card 1",
    filter: "Instrument",
  },
  {
    id: 4,
    image: "https://storage.googleapis.com/wubble-discover-files/Bass.png",
    title: "Bass",
    background:
      "[background:linear-gradient(135deg,#B9B9B9_0%,#2B2B2B_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Card 1",
    filter: "Instrument",
  },
  {
    id: 5,
    image: "https://storage.googleapis.com/wubble-discover-files/Bells.png",
    title: "Bells",
    background:
      "[background:linear-gradient(135deg,#A78357_0%,#805C30_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Card 1",
    filter: "Instrument",
  },
  {
    id: 6,
    image: "https://storage.googleapis.com/wubble-discover-files/Brass.png",
    title: "Brass",
    background:
      "[background:linear-gradient(135deg,#816F5E_0%,#D8BC5E_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Card 1",
    filter: "Instrument",
  },
  {
    id: 600,
    image: "https://storage.googleapis.com/wubble-discover-files/Clap.png",
    title: "Clap",
    background:
      "[background:linear-gradient(135deg,#816F5E_0%,#D8BC5E_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Card 1",
    filter: "Instrument",
  },
  {
    id: 8,
    image:
      "https://storage.googleapis.com/wubble-discover-files/Electric Guitar.png",
    title: "Electric Guitar",
    background:
      "[background:linear-gradient(135deg,#8D7B32_0%,#736735_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Electric Guitar",
    filter: "Instrument",
  },
  {
    id: 9,
    image:
      "https://storage.googleapis.com/wubble-discover-files/Electronic Drums.png",
    title: "Electric Drums",
    background:
      "[background:linear-gradient(135deg,#4D0561_0%,#430431_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Electronic Drums",
    filter: "Instrument",
  },
  {
    id: 10,
    image: "https://storage.googleapis.com/wubble-discover-files/Ethnic.png",
    title: "Ethnic",
    background:
      "[background:linear-gradient(135deg,#5B2C0F_0%,#E3712A_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Ethnic instruments",
    filter: "Instrument",
  },
  {
    id: 11,
    image: "https://storage.googleapis.com/wubble-discover-files/Keys.png",
    title: "Keys",
    background:
      "[background:linear-gradient(135deg,#A5A7AC_0%,#A5A7AC_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Keys",
    filter: "Instrument",
  },
  {
    id: 12,
    image: "https://storage.googleapis.com/wubble-discover-files/Mandolin.png",
    title: "Mandolin",
    background:
      " [background:linear-gradient(135deg,#6550BD_0%,#6550BD_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)",
    description: "This is a description for Mandolin",
    filter: "Instrument",
  },
  // {
  //   id: 13,
  //   image: "https://storage.googleapis.com/wubble-discover-files/Ukulele.png",
  //   title: "Ukulele",
  //   background:
  //     "[background:linear-gradient(135deg,#B99F64_0%,#D98F20_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
  //   description: "This is a description for Ukulele",
  //   filter: "Instrument",
  // },
  {
    id: 14,
    image: "https://storage.googleapis.com/wubble-discover-files/Orchestra.png",
    title: "Orchestra",
    background:
      "[background:linear-gradient(135deg,#7A2209_0%,#B83611_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Orchestra",
    filter: "Instrument",
  },
  {
    id: 15,
    image: "https://storage.googleapis.com/wubble-discover-files/Pads.png",
    title: "Pads",
    background:
      "[background:linear-gradient(135deg,#7E4A5C_0%,#544A7E_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Pads",
    filter: "Instrument",
  },
  // {
  //   id: 16,
  //   image:
  //     "https://storage.googleapis.com/wubble-discover-files/Percussion.png",
  //   title: "Percussion",
  //   background:
  //     "[background:linear-gradient(135deg,#B35E41_0%,#EB6C40_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
  //   description: "This is a description for Percussion",
  //   filter: "Instrument",
  // },
  {
    id: 17,
    image: "https://storage.googleapis.com/wubble-discover-files/Piano.png",
    title: "Piano",
    background:
      "[background:linear-gradient(135deg,#1B0C08_0%,#9D2404_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Piano",
    filter: "Instrument",
  },
  {
    id: 18,
    image: "https://storage.googleapis.com/wubble-discover-files/Saxophone.png",
    title: "Saxaphone",
    background:
      "[background:linear-gradient(135deg,#8D693B_0%,#EDAB56_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Saxophone",
    filter: "Instrument",
  },
  {
    id: 19,
    image:
      "https://storage.googleapis.com/wubble-discover-files/Wind Instruments.png",
    title: "Wind Instruments",
    background:
      "[background:linear-gradient(135deg,#B29680_0%,#CCA78A_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Special Wind Instruments",
    filter: "Instrument",
  },
  {
    id: 20,
    image: "https://storage.googleapis.com/wubble-discover-files/Strings.png",
    title: "Strings",
    background:
      "[background:linear-gradient(135deg,#657258_0%,#455B2E_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Strings",
    filter: "Instrument",
  },
  {
    id: 21,
    image: "https://storage.googleapis.com/wubble-discover-files/Synth.png",
    title: "Synth",
    background:
      "[background:linear-gradient(135deg,#6F8998_0%,#063D75_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Synth",
    filter: "Instrument",
  },
  {
    id: 22,
    image: "https://storage.googleapis.com/wubble-discover-files/Trumpets.png",
    title: "Trumpets",
    background:
      "[background:linear-gradient(135deg,#C85E50_0%,#A22211_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Trumpets",
    filter: "Instrument",
  },
  {
    id: 23,
    image:
      "https://storage.googleapis.com/wubble-discover-files/Upright Bass.png",
    title: "Upright bass",
    background:
      "[background:linear-gradient(135deg,#7C7C50_0%,#5B5B26_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Upright Bass",
    filter: "Instrument",
  },
  {
    id: 24,
    image: "https://storage.googleapis.com/wubble-discover-files/Violin.png",
    title: "Violin",
    background:
      "[background:linear-gradient(135deg,#053957_0%,#777E90_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Violin",
    filter: "Instrument",
  },
  // {
  //   id: 25,
  //   image: "https://storage.googleapis.com/wubble-discover-files/Whistle.png",
  //   title: "Whistle",
  //   background:
  //     "[background:linear-gradient(135deg,#8AACC2_0%,#599DCA_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
  //   description: "This is a description for Whistle",
  //   filter: "Instrument",
  // },
  {
    id: 26,
    image: "https://storage.googleapis.com/wubble-discover-files/Woodwinds.png",
    title: "Woodwinds",
    background:
      "[background:linear-gradient(135deg,#B28938_0%,#2F6321_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15),0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
    description: "This is a description for Woodwinds",
    filter: "Instrument",
  },
];

const themeCards = [
  {
    id: 1,
    title: "Ads, Promos & Trailers",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Ads & Promo.mp4",
    filter: "Theme",
  },
  {
    id: 2,
    title: "Arts & Crafts",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Arts & Crafts.mp4",
    filter: "Theme",
  },
  {
    id: 3,
    title: "Broadcasting",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Broadcasting.mp4",
    filter: "Theme",
  },
  {
    id: 4,
    title: "Cinematic",
    video: "https://storage.googleapis.com/wubble-discover-files/Cinematic.mp4",
    filter: "Theme",
  },
  {
    id: 5,
    title: "Classical",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Classical Collection.mp4",
    filter: "Theme",
  },
  {
    id: 6,
    title: "Cooking",
    video: "https://storage.googleapis.com/wubble-discover-files/Cooking.mp4",
    filter: "Theme",
  },
  {
    id: 200,
    title: "Comedy",
    video: "https://storage.googleapis.com/wubble-discover-files/Comedy.mp4",
    filter: "Theme",
  },
  {
    id: 7,
    title: "Corporate",
    video: "https://storage.googleapis.com/wubble-discover-files/Corporate.mp4",
    filter: "Theme",
  },
  {
    id: 8,
    title: "Documentary",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Documentary.mp4",
    filter: "Theme",
  },
  {
    id: 9,
    title: "Drama",
    video: "https://storage.googleapis.com/wubble-discover-files/Drama.mp4",
    filter: "Theme",
  },
  {
    id: 10,
    title: "Fashion",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Fashion & Beauty.mp4",
    filter: "Theme",
  },
  {
    id: 11,
    title: "Gaming",
    video: "https://storage.googleapis.com/wubble-discover-files/Gaming.mp4",
    filter: "Theme",
  },
  {
    id: 12,
    title: "Holiday",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Holiday Seasons.mp4",
    filter: "Theme",
  },
  {
    id: 13,
    title: "Horror",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Horror & Thriller.mp4",
    filter: "Theme",
  },
  {
    id: 14,
    title: "Motivational",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Motivation & Inspiring.mp4",
    filter: "Theme",
  },
  {
    id: 15,
    title: "Nature",
    video: "https://storage.googleapis.com/wubble-discover-files/Nature.mp4",
    filter: "Theme",
  },
  {
    id: 16,
    title: "Photography",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Photography.mp4",
    filter: "Theme",
  },
  {
    id: 17,
    title: "Podcast",
    video: "https://storage.googleapis.com/wubble-discover-files/Podcast.mp4",
    filter: "Theme",
  },
  {
    id: 18,
    title: "Storytelling",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Storytelling Techniques.mp4",
    filter: "Theme",
  },
  {
    id: 19,
    title: "Sports",
    video: "https://storage.googleapis.com/wubble-discover-files/Sports.mp4",
    filter: "Theme",
  },
  {
    id: 20,
    title: "Technology",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Technology.mp4",
    filter: "Theme",
  },
  {
    id: 21,
    title: "Travel",
    video: "https://storage.googleapis.com/wubble-discover-files/Travel.mp4",
    filter: "Theme",
  },
  {
    id: 22,
    title: "Tutorial",
    video: "https://storage.googleapis.com/wubble-discover-files/Tutorials.mp4",
    filter: "Theme",
  },
  {
    id: 23,
    title: "Vlogs",
    video: "https://storage.googleapis.com/wubble-discover-files/Vlogs.mp4",
    filter: "Theme",
  },
  {
    id: 24,
    title: "Romance",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Wedding & Romance.mp4",
    filter: "Theme",
  },
  {
    id: 25,
    title: "Wellness",
    video:
      "https://storage.googleapis.com/wubble-discover-files/Workout & Wellness.mp4",
    filter: "Theme",
  },
];

const Discover = () => {
  const posthog = usePostHog();
  const [showDiscoverPage, setShowDiscoverPage] = useState(false);
  const [showThemesPage, setShowThemesPage] = useState(false);
  const [showMoodsPage, setShowMoodsPage] = useState(false);
  const [showGenresPage, setShowGenresPage] = useState(false);
  const [showInstrumentsPage, setShowInstrumentsPage] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const { updateLocalState, localState } = useLocalState();

  useEffect(() => {
    localState.isAudioPlaying
      ? setIsAudioPlaying(true)
      : setIsAudioPlaying(false);
  }, [localState.isAudioPlaying]);

  const handleExploreAllTracks = () => {
    posthog.capture("Explore All Tracks");
    setSelectedCard(null);

    setShowDiscoverPage(true);
  };

  const handleExploreAllThemes = () => {
    posthog.capture("Explore All Themes");
    setShowThemesPage(true);
  };

  const handleCardClick = (card) => {
    posthog.capture("Click Discover Page Category", { card: card });
    setSelectedCard(card);
    setShowDiscoverPage(true);
  };

  if (showThemesPage && !showDiscoverPage) {
    return (
      <CardsCollection
        cards={themeCards}
        title="All Themes"
        onGoBackClick={() => setShowThemesPage(false)}
        onCardClick={handleCardClick}
      />
    );
  }

  if (showGenresPage && !showDiscoverPage) {
    return (
      <CardsCollection
        cards={genreCollectionCards}
        title="All Genres"
        onGoBackClick={() => setShowGenresPage(false)}
        cardType="vinyl"
        onCardClick={handleCardClick}
      />
    );
  }

  if (showMoodsPage && !showDiscoverPage) {
    return (
      <CardsCollection
        cards={moodCollectionCards}
        title="All Moods"
        onGoBackClick={() => setShowMoodsPage(false)}
        cardType="video"
        onCardClick={handleCardClick}
      />
    );
  }

  if (showInstrumentsPage && !showDiscoverPage) {
    return (
      <CardsCollection
        cards={instrumentCollectionCards}
        title="All Instruments"
        onGoBackClick={() => setShowInstrumentsPage(false)}
        cardType="vinyl"
        onCardClick={handleCardClick}
      />
    );
  }

  if (showDiscoverPage && selectedCard) {
    return (
      <DiscoverPage
        card={{
          image: selectedCard.image,
          background: selectedCard.background,
        }}
        video={selectedCard?.video || selectedCard?.videoSrc}
        description={selectedCard.description}
        title={selectedCard.title}
        filter={selectedCard.filter}
        onGoBackClick={() => setShowDiscoverPage(false)}
      />
    );
  }

  // if (showDiscoverPage) {
  //   return (
  //     <DiscoverPage
  //       card={{
  //         image:
  //           "https://storage.googleapis.com/wubble-discover-files/Trending.png",
  //         background:
  //           "[background:linear-gradient(135deg,#D29A37_0%,#C7BD86_100%)] shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.15)]",
  //       }}
  //       description="This is the description for all tracks"
  //       title="Trending"
  //       onGoBackClick={() => setShowDiscoverPage(false)}
  //     />
  //   );
  // }

  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full px-4">
        <div className="w-full hidden md:block relative mt-4 h-[9rem] overflow-hidden">
          {/* Pseudo-element for rounded border */}
          <div className="absolute inset-0 rounded-t-lg pointer-events-none darker-filter [background:linear-gradient(135deg,#8CD9D3_0%,#5F81B5_100%)] z-10"></div>

          {/* Folder SVG in the center with lower z-index */}
          <div className="absolute -bottom-[9rem] left-1/2 -translate-x-1/2 flex items-center justify-center z-10">
            <img
              src="/Subtract.svg"
              alt="Subtract Icon"
              className="w-[16rem] h-[16rem] animate-slow-rotate-left"
            />
          </div>

          {/* right circles */}
          <div className="absolute -top-[23rem] -right-[26rem] w-[45rem] h-[45rem] bg-[#7A1998] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move-reverse z-20" />
          <div className="absolute -bottom-[35rem] -right-[10rem] w-[45rem] h-[45rem] bg-[#7A1998] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move-reverse z-20" />

          {/* left circles */}
          <div className="absolute -bottom-80 -left-[40rem] w-[56rem] h-[56rem] bg-[#7A1998] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move z-20" />
          <div className="absolute -bottom-[42rem] -left-[10em] w-[48rem] h-[48rem] bg-[#7A1998] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move z-20" />

          {/* Bottom-left title */}
          <h1 className="absolute bottom-6 left-5 text-4xl font-normal text-white z-20">
            Discover
          </h1>
        </div>
      </div>

      <div
        className={`flex flex-col px-4 gap-2 pt-4 ${
          isAudioPlaying
            ? `max-h-[calc(100vh-252px)]`
            : `max-h-[calc(100vh-180px)]`
        } scrollable-content h-full`}
      >
        {/* <HorizontalCards
          cardType="standard"
          data={cards}
          title="Trending"
          exploreMoreText={"Explore trending"}
          onExploreClick={handleExploreAllTracks}
        /> */}
        <HorizontalCards
          cardType="video"
          data={cards3}
          title="Theme"
          exploreMoreText={"Explore themes"}
          onCardClick={handleCardClick}
          onExploreClick={handleExploreAllThemes}
        />
        <HorizontalCards
          cardType="vinyl"
          data={genreCards}
          title="Genre"
          exploreMoreText={"Explore genres"}
          onCardClick={handleCardClick}
          onExploreClick={() => {
            posthog.capture("Explore All Genres");
            setShowGenresPage(true);
          }}
        />
        <HorizontalCards
          cardType="video"
          data={moodCards}
          title="Mood"
          exploreMoreText={"Explore moods"}
          onCardClick={handleCardClick}
          onExploreClick={() => {
            posthog.capture("Explore All Moods");
            setShowMoodsPage(true);
          }}
        />
        <HorizontalCards
          cardType="vinyl"
          data={instrumentCards}
          title="Instrument"
          exploreMoreText={"Explore instruments"}
          onCardClick={handleCardClick}
          onExploreClick={() => {
            posthog.capture("Explore All Instruments");
            setShowInstrumentsPage(true);
          }}
        />
      </div>
    </div>
  );
};

export default Discover;
