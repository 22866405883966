import React, { useRef, useState } from "react";
import { ArrowShapeUpFromLine, Xmark } from "@gravity-ui/icons";
import Toast from "../generic_components/Toast";

const ModalContent = ({
  title,
  fileTypes,
  handleFileChange,
  closeModal,
  toastMessage,
  setToastMessage,
}) => {
  const dropRef = useRef(null);

  const validateFileType = (file) => {
    const isAudio = file.type.startsWith("audio/");
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");

    switch (title) {
      case "Attach Audio":
        return isAudio;
      case "Attach Image":
        return isImage;
      case "Attach Video":
        return isVideo;
      default:
        return false;
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];

    if (validateFileType(droppedFile)) {
      const fileEvent = {
        target: { files: [droppedFile] },
      };
      handleFileChange(fileEvent);
    } else {
      // You can add toast notification here for invalid file type
      console.error("Invalid file type");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className="relative text-left bg-[#1d1b1d] border border-solid border-[#3c393c] rounded-lg w-full md:w-[28rem] self-center z-10">
      <button
        onClick={closeModal}
        className="absolute top-2 right-2 p-2 z-10 justify-center rounded-full hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-white"
      >
        <Xmark className="w-4 h-4" />
      </button>
      <h2 className="text-lg font-normal text-left w-full px-3 py-3">
        {title}
      </h2>
      <div className="w-full border-t border-t-[#3c393c] mb-3"></div>
      <div
        ref={dropRef}
        className="border-dashed m-3 border-2 border-[#3c393c] px-5 py-5 rounded-md text-center"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <ArrowShapeUpFromLine className="w-[35px] h-[34.999515533447266px] text-white mx-auto mb-3" />
        <p className="mb-1 text-base font-extralight">
          Drop a file here or{" "}
          <label
            htmlFor="fileInput"
            className="text-white cursor-pointer underline"
          >
            Browse
          </label>
        </p>
        <input
          type="file"
          accept={fileTypes.join(",")}
          onChange={handleFileChange}
          className="hidden"
          id="fileInput"
        />
        <p className="text-gray-400 font-extralight text-sm">
          File supported: {fileTypes.join(", ")}
        </p>
        <p className="text-gray-400 font-extralight text-sm">File size: max 20 MB</p>
        {/* <div className="flex space-x-2 justify-center mt-4">
          <button className="h-9 w-9 md:w-fit sm:w-fit flex gap-2 justify-center items-center font-medium text-sm text-[#878188] bg-[#100f10] md:px-3 sm:px-2 rounded-lg border border-solid border-[#2c2a2c]">
            <img src="/dropbox.png" alt="Dropbox" className="w-4 h-4" />
            <span className="hidden md:flex sm:flex">Upload via Dropbox</span>
          </button>
          <button className="h-9 w-9 md:w-fit sm:w-fit flex gap-2 justify-center items-center font-medium text-sm text-[#878188] bg-[#100f10] md:px-3 sm:px-2 rounded-lg border border-solid border-[#2c2a2c]">
            <img src="/GDrive.png" alt="Google Drive" className="w-4 h-4" />
            <span className="hidden sm:flex md:flex">
              Upload via Google Drive
            </span>
          </button>
        </div> */}
      </div>
      {toastMessage && (
        <Toast
          type={"error"}
          message={toastMessage}
          onClose={() => setToastMessage("")}
        />
      )}
    </div>
  );
};

export default ModalContent;
