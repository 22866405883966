import React from "react";
import BaseCard from "./BaseCard";
import correct from "../.././../assets/images/redcorrect.png";

const FilmAndBroadcastCard = ({ isAnnual }) => (
  <BaseCard
    tier="Film & Broadcast"
    monthlyPrice="Custom"
    annualPrice="Custom"
    period="Monthly"
    buttonText="Talk to us"
    features={[
      "Dedicated production services",
      "High Fidelity music output",
      "Effects + Voiceovers",
      "Stem separation"
    ]}
    isAnnual={isAnnual}
    bgStyle="linear-gradient(135deg, #6EF7EC40 0%, #7C0CA235 100%)"
    cardStyle={{
      border: "1px solid var(--Color-Light-Opacity-White-10, #FFFFFF1A)",
    }}
    path={correct}
  />
);

export default FilmAndBroadcastCard;
