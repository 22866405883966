import React from "react";
import { Xmark } from "@gravity-ui/icons";

const Modal = ({ closeModal, children, showCloseButton = true }) => {
  return (
    <div
      className="text-center fixed inset-0 flex items-center justify-center text-white bg-black bg-opacity-80 z-50 p-4"
    >
      <div
        className="flex flex-col md:max-w-96 w-full gap-5 relative"
        onClick={(e) => e.stopPropagation()}
      >
        {showCloseButton && (
          <button
            className="absolute top-2 right-2 p-2 z-10 justify-center rounded-full hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-white"
            onClick={closeModal}
          >
            <Xmark className="w-4 h-4" />
          </button>
        )}
        <div className="flex flex-col px-0 py-0 relative w-full">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
