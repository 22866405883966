import { useState } from "react";

import { ArrowRight, Plus } from "@gravity-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { setOpenInviteMemberModal } from "../../store/reducers/subscriptionSlice";
import TeamPurchaseSummary from "./TeamPurchaseSummary";
import { useNavigate } from "react-router-dom";

const InviteMembers = ({ handleAddMemberData, isCreateTeam = false }) => {
  const navigate = useNavigate();
  const [newEmail, setNewEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isPurchaseSummary, setIsPurchaseSummary] = useState(false);
  const { openInviteMemberModal, subscriptionType } = useSelector(
    (state) => state.subscription
  );
  const dispatch = useDispatch();
  const handleUpdateTeamMembers = (updatedTeamMembers) => {
    dispatch(setOpenInviteMemberModal(true));
    setTeamMembers(updatedTeamMembers);
    setIsPurchaseSummary(false);
  };
  // Step 2 form state
  const [teamMembers, setTeamMembers] = useState({
    adminEmail: "",
    invitedEmails: [],
  });
  // Handle adding new team members
  const handleAddMember = async (email) => {
    if (email && !teamMembers.invitedEmails.includes(email)) {
      const currentUserEmail = await checkCurrentUserEmail();
      if (currentUserEmail && email === currentUserEmail) {
        setEmailError("You cannot invite yourself");
        return;
      }

      const isAssociated = await checkEmailAssociation(email);
      if (!isAssociated) {
        setTeamMembers({
          ...teamMembers,
          invitedEmails: [...teamMembers.invitedEmails, email],
        });
        setNewEmail(""); // Clear input field
        setEmailError(""); // Clear any previous error
      } else {
        setEmailError("This email is already associated with a team");
      }
    } else {
      setEmailError("This email is already in the invited list");
    }
  };

  const checkEmailAssociation = async (email) => {
    try {
      const response = await apiService.handleProtectedRoute(
        "checkEmailOrganization",
        { email }
      );
      return response.isInOrganization;
    } catch (error) {
      console.error("Error checking email association:", error);
      return false;
    }
  };

  const checkCurrentUserEmail = async () => {
    try {
      const response = await apiService.handleProtectedRoute(
        "fetchUserEmail",
        {}
      );
      return response.success ? response.email : null;
    } catch (error) {
      console.error("Error fetching current user email:", error);
      return null;
    }
  };

  // Utility function to validate email addresses
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleContinue = () => {};

  return (
    <>
      {openInviteMemberModal && (
        <div className="fixed inset-0 z-50 flex justify-center bg-[#1D1B1D] bg-opacity-100 overflow-auto p-2">
          <div className="bg-[#100F10] rounded-lg w-full max-w-3xl p-4 relative border border-[#2c2a2c]">
            {/* Close Button */}
            {!isCreateTeam && (
              <div className="flex justify-between items-center pb-3 border-b border-b-[#2C2A2C]">
                <div
                  className="relative h-9 flex items-center gap-2 rounded-2.5"
                  id="sidebar-logo"
                  onClick={() => {
                    dispatch(setOpenInviteMemberModal(false));
                    navigate("/");
                  }}
                >
                  <img
                    className="w-10 h-10 object-cover rounded-full cursor-pointer"
                    src="/logo@2x.png"
                    alt="Logo"
                    id="sidebar-logo-image"
                  />
                  <img
                    className="w-[5.9rem] h-8 object-cover z-10 hover:opacity-0 transition duration-500 cursor-pointer"
                    src="/text_only_white.svg"
                    alt="White Text"
                    id="sidebar-logo-text-white"
                  />
                  <img
                    className="absolute left-12 w-[5.9rem] h-8 object-cover cursor-pointer"
                    src="/text_only_gradient.svg"
                    alt="Gradient Text"
                    id="sidebar-logo-text-gradient"
                  />
                </div>
                <button
                  onClick={() => {
                    dispatch(setOpenInviteMemberModal(false));
                    setIsPurchaseSummary(true);
                  }}
                  className={`flex items-center text-sm font-normal h-9 px-3 rounded-full transition-all duration-500 ${"bg-gradient-to-b from-[#fc10f2] to-[#3078e4] text-white hover:[background:linear-gradient(135deg,#e509e4,#256bb8)]"}`}
                >
                  Continue <ArrowRight className="ml-2" />
                </button>
              </div>
            )}
            {/* Modal Content */}
            <div className="flex flex-col h-full pt-6 scrollable-content max-h-[calc(100vh-82px)]">
              <h2 className="text-4xl font-medium text-white mb-6">
                Invite your teammates
              </h2>
              <form>
                <p className="font-extralight text-white text-sm mb-2">
                  Improve music efficiency by adding more members!
                </p>
                <div className="flex flex-col bg-[#1c1b1c] rounded-lg px-3 py-1">
                  <div className="py-5 rounded-t-lg border-b border-[#2c2a2c]">
                    <div className="flex flex-row items-center justify-between mb-1 pr-2">
                      <label className="block text-base text-white font-light mb-1">
                        Invite new member
                      </label>
                      {emailError && (
                        <span className="text-red-400 text-sm font-extralight">
                          {emailError}
                        </span>
                      )}
                    </div>

                    <div className="flex items-center space-x-2 relative">
                      <input
                        type="email"
                        className="w-full px-3 h-9 rounded-full placeholder-[#878188] text-sm font-extralight bg-[#1D1B1D] text-white border border-[#4E4A4F] focus:outline-none"
                        placeholder="Enter email address to send invite"
                        value={newEmail}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && validateEmail(newEmail)) {
                            e.preventDefault();
                            handleAddMemberData
                              ? handleAddMemberData(newEmail)
                              : handleAddMember(newEmail);
                          }
                        }}
                        onChange={(e) => {
                          e.stopPropagation();
                          setNewEmail(e.target.value);
                          setEmailError(""); // Clear error when input changes
                        }} // Update state when input changes
                      />
                      <button
                        type="button"
                        className={`absolute right-1 top-1 p-1.5 ${
                          newEmail
                            ? `bg-gradient-to-b from-[#fc10f2] to-[#3078e4] text-white hover:[background:linear-gradient(135deg,#e509e4,#256bb8)]`
                            : `bg-[#4E4A4F] text-[#878188]`
                        } rounded-full`}
                        onClick={(e) => {
                          if (validateEmail(newEmail)) {
                            handleAddMemberData
                              ? handleAddMemberData(newEmail)
                              : handleAddMember(newEmail);
                          } else {
                            setEmailError("Invalid email address");
                          }
                        }}
                        // disabled={!validateEmail(newEmail)}
                      >
                        <Plus />
                      </button>
                    </div>
                  </div>

                  <div className="w-full bg-[#1D1B1D] rounded-b-lg">
                    {teamMembers.invitedEmails.length > 0 ? (
                      <div className="w-full flex flex-col">
                        {teamMembers.invitedEmails.map((email, index) => (
                          <div
                            key={index}
                            className={`w-full flex py-5 font-light ${
                              teamMembers.invitedEmails.length - 1 !== index &&
                              `border-b border-b-[#2C2A2C]`
                            } justify-between items-center text-[#878188]`}
                          >
                            {/* Left side: Invited label */}
                            <span className="text-[#878188] text-sm font-extralight w-2/3 ml-3">
                              Invited
                            </span>
                            {/* Right side: Email */}
                            <span className="text-[#878188] w-full text-sm font-extralight">
                              {email}
                            </span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-[#878188] font-normal text-sm px-3 py-5">
                        No members invited yet.
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {isPurchaseSummary && (
        <TeamPurchaseSummary
          teamMembersInvited={teamMembers}
          onUpdateTeamMembers={handleUpdateTeamMembers}
          isCreateTeam={subscriptionType !== "upgrade"}
        />
      )}
    </>
  );
};

export default InviteMembers;
