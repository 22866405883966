import React, { useState, useEffect, useRef } from "react";
import { componentMapper } from "../../utils/componentMapper";
import useSidebarState from "../hooks/useSidebarState";
import { useAuth } from "../../contexts/AuthContext";
import { conditionalRender } from "../../utils/conditionalRender";
import Modal from "../modal/Modal";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import fetchDataAndBuildJson from "../../utils/fetchDataAndBuildJson";
import { Navigate, useLocation } from "react-router-dom";
import AudioPlayer from "../custom_components/AudioPlayer";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { setCookie, getCookie } from "../../utils/cookieUtils";
import Toast from "../generic_components/Toast";
import ProgressInfoToast from "../custom_components/ProgressInfoToast";
import { useNavigate } from "react-router-dom";
import apiService from "../../api/apiService";
import CookieConsent from "../custom_components/CookieConsent";
import { usePostHog } from "posthog-js/react";

const Page = ({ jsonData, taskName, jsObjectPath }) => {
  const posthog = usePostHog();
  const [isShortSidebar, setIsShortSidebar] = useSidebarState(false);
  const { isLoggedIn, logout } = useAuth();
  const [loginStatus, setLoginStatus] = useState(isLoggedIn);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const { audioSrc, stopAudio } = useAudioPlayer();
  const [dynamicJsonData, setDynamicJsonData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const projectId = location.search.slice(11);
  const { localState, updateLocalState, clearEntireState } = useLocalState();
  const [infoMessage, setInfoMessage] = useState(null);
  const navigate = useNavigate();
  const [isTeamMember, setIsTeamMember] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [invitationToken, setInvitationToken] = useState("");
  const [teamName, setTeamName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentStep, setCurrentStep] = useState("verifyToken"); // Steps: verifyToken, acceptInvitation

  const [generationStatus, setGenerationStatus] = useState(null);
  const [currentRequestId, setCurrentRequestId] = useState(null);
  const pollIntervalRef = useRef(null);

  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [isLongSidebar, setIsLongSidebar] = useState(!isShortSidebar);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (!isInitialRender) {
      setIsLongSidebar(localState.longSidebar);
    } else {
      setIsInitialRender(false);
    }
  }, [localState.longSidebar]);

  useEffect(() => {
    const fetchAndIdentifyUser = async () => {
      const userId = getCookie("user_id"); // Retrieve user_id from cookies

      if (userId) {
        try {
          // Fetch the user's email using the API service
          const response = await apiService.handleProtectedRoute(
            "fetchUserEmail",
            {
              user_id: userId,
            }
          );

          if (response.success && response.email) {
            // Identify the user with PostHog
            posthog.identify(userId, {
              email: response.email,
              isLoggedIn: true,
              isAdmin: localState?.isAdmin ? true : false,
            });
            posthog.capture("Logged in user", {
              email: response.email,
              isLoggedIn: true,
              isAdmin: localState?.isAdmin ? true : false,
            });
          } else if (!response.success) {
            logout();
            navigate("/");
          }
        } catch (error) {
          logout();
          navigate("/");
          console.error("Error fetching user email:", error);
        }
      } else {
        if (location.pathname !== "/accept-invitation") {
          logout();
          navigate("/");
        }
      }
    };

    fetchAndIdentifyUser();
  }, [isLoggedIn]);

  useEffect(() => {
    // Capture pageview with the current URL
    posthog.capture("$pageview", {
      path: location.pathname,
      url: window.location.href,
    });
  }, [location.pathname]);

  const handleAcceptCookies = () => {
    setShowCookieConsent(false);
    posthog.capture("Accepted Cookie Policy");
    // Store consent in localStorage
    localStorage.setItem("cookieConsent", "accepted");
    setCookie("cookieConsent", "accepted", 90);
    updateLocalState("profileCreated", false);
  };

  useEffect(() => {
    if (localState.profileCreated) {
      setShowCookieConsent(true);
    }
  }, [localState.profileCreated]);

  // useEffect(() => {
  //   if (
  //     localState.currentRequestId &&
  //     !localState.generatedRequestAudio &&
  //     localState.projectId &&
  //     location.pathname !== "/chatPage"
  //   ) {
  //     pollIntervalRef.current = setInterval(async () => {
  //       try {
  //         const response = await fetch(
  //           `${process.env.REACT_APP_SERVER_ENDPOINT}/api/check-generation-status?requestId=${localState.currentRequestId}`,
  //           {
  //             method: "GET",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${getCookie("token")}`,
  //             },
  //           }
  //         );

  //         const data = await response.json();

  //         // console.log("Response from server:", data);

  //         if (data.status === "ready") {
  //           updateLocalState("currentRequestId", null);
  //           updateLocalState("ai_response_id", null);
  //           setGenerationStatus({
  //             type: "success",
  //             message: "Your track is ready to play. Check it out now!",
  //             action: {
  //               label: "Play track",
  //               onClick: () => {
  //                 // Set the response ID to play
  //                 updateLocalState(
  //                   "playLatestTrack",
  //                   localState.ai_response_id
  //                 );
  //                 navigate(`/chatPage?projectId=${localState.projectId}`);
  //               },
  //             },
  //           });
  //           clearInterval(pollIntervalRef.current);
  //         } else if (data.status === "pending") {
  //           // Only update the status if it hasn't been set to "pending"
  //           setGenerationStatus((prev) => {
  //             if (!prev || prev.type !== "info") {
  //               return {
  //                 type: "info",
  //                 message:
  //                   "Your track is in the works. We'll notify you when it's ready!",
  //                 loading: true,
  //               };
  //             }
  //             return prev; // Prevent redundant state updates
  //           });
  //         }
  //       } catch (error) {
  //         console.error("Error checking generation status:", error);
  //       }
  //     }, 2000);
  //   }

  //   if (!localState.currentRequestId) {
  //     setGenerationStatus(null);
  //     clearInterval(pollIntervalRef.current);
  //   }

  //   return () => clearInterval(pollIntervalRef.current);
  // }, [
  //   localState.currentRequestId,
  //   location.pathname,
  //   localState.generatedRequestAudio,
  // ]);

  useEffect(() => {
    if (
      localState.currentRequestId &&
      !localState.generatedRequestAudio &&
      localState.projectId &&
      location.pathname !== "/chatPage"
    ) {
      const timeoutId = setTimeout(() => {
        console.log("Timeout triggered");

        clearInterval(pollIntervalRef.current);
        setGenerationStatus({
          type: "error",
          message: "Something went wrong with your track :(",
          action: {
            label: "Go to project",
            onClick: () => {
              navigate(`/chatPage?projectId=${localState.projectId}`);
            },
          },
        });
        updateLocalState("currentRequestId", null);
        updateLocalState("ai_response_id", null);
      }, 20000); // 10 second timeout

      pollIntervalRef.current = setInterval(async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_ENDPOINT}/api/check-generation-status?requestId=${localState.currentRequestId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          );

          const data = await response.json();

          if (data.status === "ready") {
            clearTimeout(timeoutId);
            clearInterval(pollIntervalRef.current);
            updateLocalState("currentRequestId", null);
            updateLocalState("ai_response_id", null);
            setGenerationStatus({
              type: "success",
              message: "Your track is ready to play. Check it out now!",
              action: {
                label: "Play track",
                onClick: () => {
                  navigate(`/chatPage?projectId=${localState.projectId}`);
                },
              },
            });
          } else if (data.status === "pending") {
            setGenerationStatus((prev) => {
              if (!prev || prev.type !== "info") {
                return {
                  type: "info",
                  message:
                    "Your track is in the works. We'll notify you when it's ready!",
                  loading: true,
                };
              }
              return prev;
            });
          }
        } catch (error) {
          console.error("Error checking generation status:", error);
        }
      }, 2000);

      return () => {
        clearTimeout(timeoutId);
        clearInterval(pollIntervalRef.current);
      };
    }

    // Removed the block that resets generationStatus

    return () => {
      clearInterval(pollIntervalRef.current);
    };
  }, [localState.currentRequestId, localState.projectId]);

  const handleToastClose = () => {
    clearInterval(pollIntervalRef.current); // Clear polling interval
    updateLocalState("currentRequestId", null); // Reset localState variables
    updateLocalState("ai_response_id", null); // Reset localState variables
    setGenerationStatus(null); // Clear toast state
  };

  useEffect(() => {
    let isMounted = true;

    const fetchAndBuildJson = async () => {
      if (taskName && jsObjectPath) {
        setIsLoading(true);
        try {
          const data = await fetchDataAndBuildJson(taskName, jsObjectPath, {
            projectId,
          });
          if (isMounted) {
            setDynamicJsonData(data);
          }
        } catch (error) {
          console.error("Error fetching and building JSON:", error);
        } finally {
          if (isMounted) setIsLoading(false);
        }
      } else {
        setDynamicJsonData(jsonData);
        setIsLoading(false);
      }
    };

    fetchAndBuildJson();

    return () => {
      isMounted = false; // Cleanup function to avoid state updates on unmounted components
    };
  }, [taskName, jsObjectPath, projectId, jsonData]);

  useEffect(() => {
    const fetchUserAdminStatus = async () => {
      if (isLoggedIn && !localState.isAdmin) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_ENDPOINT}/api/check-admin-status`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${getCookie("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          setIsAdmin(data.isAdmin);
          updateLocalState("isAdmin", data.isAdmin);
        } catch (error) {
          console.error("Error checking admin status:", error);
        }
      } else if (localState.isAdmin) {
        setIsAdmin(localState.isAdmin);
      }
    };

    fetchUserAdminStatus();
  }, [isLoggedIn, location.search]);

  useEffect(() => {
    if (localState.isTeamMember) setIsTeamMember(true);
  }, [isLoggedIn, location.search]);

  const checkTeamMembership = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/check-team-membership`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getCookie("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();

      console.log("user is team member", data);

      if (data.isTeamMember) {
        setIsTeamMember(true);
        updateLocalState("isTeamMember", true);
      } else {
        setIsTeamMember(false);
      }
    } catch (error) {
      console.error("Error checking team membership:", error);
      setIsTeamMember(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      checkTeamMembership();
    } else {
      setIsTeamMember(false);
    }
  }, [isLoggedIn]);

  // useEffect(() => {
  //   console.log("location.search", location.search);

  //   const queryParams = new URLSearchParams(location.search);
  //   const token = queryParams.get("token");

  //   if (token) {
  //     // Trigger reset password modal if token exists
  //     setModalContent({
  //       inputJson: "resetPassword3.json", // The modal content with the reset password form
  //     });
  //     setShowModal(true);
  //   }
  // }, [location.search]);

  const handleAcceptInvitation = () => {
    if (isLoggedIn) {
      acceptInvitation();
    } else {
      // Store the token for later use
      localStorage.setItem("invitationToken", invitationToken);
      // Navigate to signup page
      setModalContent({
        inputJson: "signup1.json", // The modal content with the reset password form
        props: {
          showCloseButton: false,
        },
      });
      setShowModal(true);
    }
  };

  useEffect(() => {
    const token = getCookie("token");
    if (!token) {
      clearEntireState();
      stopAudio();
    } else if (token) updateLocalState("songs", null);
  }, [isLoggedIn]);

  const acceptInvitation = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/acceptInvitation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Include the authorization header if the user is logged in
            ...(isLoggedIn && {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            }),
          },
          body: JSON.stringify({ token: invitationToken }),
        }
      );

      const result = await response.json();

      if (result.success) {
        // Invitation accepted
        // Redirect to home or team page
        navigate("/home");
      } else {
        setErrorMessage(result.message);
      }
    } catch (error) {
      console.error("Error accepting invitation:", error);
      setErrorMessage("Failed to accept invitation.");
    }
  };

  useEffect(() => {
    if (location.pathname === "/accept-invitation") {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get("token");
      setInvitationToken(token);

      if (token) {
        // Verify token with the backend
        const verifyToken = async () => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_SERVER_ENDPOINT}/api/validateInvitationToken?token=${token}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            const result = await response.json();

            if (result.success) {
              setTeamName(result.teamName);
              setCurrentStep("acceptInvitation");
              setIsLoading(false);
            } else {
              setErrorMessage(result.message);
              setIsLoading(false);
            }
          } catch (error) {
            console.error("Error verifying invitation token:", error);
            setErrorMessage("Failed to verify invitation.");
            setIsLoading(false);
          }
        };
        verifyToken();
      } else {
        setErrorMessage("Invalid invitation link.");
        setIsLoading(false);
      }
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    console.log(token);

    if (token && location.pathname !== "/accept-invitation") {
      // Make the API request to verify the token
      const verifyToken = async () => {
        try {
          const response = await apiService.sendRequest("verifyResetToken", {
            token,
          });

          if (response.success) {
            // If the token is valid, set the token validation status to true
            setModalContent({
              inputJson: "resetPassword3.json", // The modal content with the reset password form
            });
            setShowModal(true);
          } else {
            // If the token is invalid, show an error message
            // console.log("Invalid or expired token");
          }
        } catch (error) {
          // Handle anyors
          // console.log(error);
          setInfoMessage("Invalid or expired token password reset link.");
          navigate("/");
        }
      };

      verifyToken();
    } else {
      // console.log("No token found in the URL.");
    }
  }, [location.search]);

  // useEffect(() => {
  //   const token = getCookie("token");
  //   if (!token) {
  //     updateLocalState("userNameAndAvatar", null);
  //     updateLocalState("userAvatar", null);
  //     updateLocalState("cachedProjects", null);
  //     updateLocalState("totalProjects", null);
  //     updateLocalState("teamNameAndAvatar", null);
  //     updateLocalState("teamSettingsData", null);
  //     updateLocalState("userRole", null);
  //     updateLocalState("adminSettings", null);
  //     updateLocalState("userProfileData", null);
  //     updateLocalState("isTeamMember", null);
  //     updateLocalState("isAdmin", null);
  //     stopAudio();
  //   }
  // }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn && localState.showLoginModal) {
      setModalContent({
        inputJson: "signIn.json",
      });
      setShowModal(true);
    }
  }, [localState.showLoginModal]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // For Windows/Linux: Ctrl+I
      // For macOS: Command+I
      if ((event.ctrlKey || event.metaKey) && event.key === "i") {
        event.preventDefault(); // Prevent the default behavior if needed
        posthog.capture("Click on Create New Button through keyboard");
        if (!isLoggedIn) {
          setModalContent({
            inputJson: "signIn.json",
          });
          setShowModal(true);
        } else {
          // Set the modal content when Ctrl+I or Cmd+I is pressed
          setModalContent({
            name: "customInput",
            props: {
              className: "md:w-[40rem] w-full",
              showCloseButton: false,
              requestData: {
                createProject: true,
              },
              chatPage: false,
            },
          });
          setShowModal(true);
        }
      }
    };

    // Attach the event listener
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isLoggedIn]);

  // Utility function to convert a buffer to a Data URL for images
  const bufferToDataURL = (buffer, mimeType) => {
    const binary = Array.from(buffer)
      .map((b) => String.fromCharCode(b))
      .join("");
    const base64 = btoa(binary);
    return `data:${mimeType};base64,${base64}`;
  };

  const renderComponents = (data) => {
    if (!Array.isArray(data)) {
      console.error("Invalid jsonData format or empty array:", data);
      if (taskName === "fetchChatHistory") {
        navigate("/");
      }
      return null;
    }

    return data.map((item, index) => {
      // If item.inputDynamicJson exists, handle dynamic JSON fetching
      // if (item.inputDynamicJson) {
      //   return (
      //     <DynamicComponent
      //       key={index}
      //       taskName={item.inputDynamicJson.taskName}
      //       jsObjectPath={item.inputDynamicJson.jsObjectPath}
      //       projectId={projectId}
      //       renderComponents={renderComponents}
      //     />
      //   );
      // }

      const conditionalResult = conditionalRender(
        item,
        isShortSidebar,
        setIsShortSidebar,
        renderComponents,
        isLoggedIn,
        location,
        isTeamMember,
        isAdmin,
        localState
      );

      if (conditionalResult) {
        return <div key={index}>{conditionalResult}</div>;
      }

      const { name, props, children } = item;

      if (name === "AudioPlayerPlaceholder") {
        return audioSrc ? <AudioPlayer key={index} /> : null;
      }

      const ComponentToRender = componentMapper[name];

      if (!ComponentToRender) {
        return null;
      }

      const componentProps = {
        ...props,
        ...(name === "div" && props.trigger === "toggleModal"
          ? {
              onClick: () => {
                setModalContent(props.modalContent);
                setShowModal(true);
              },
            }
          : {}),
        ...((name === "InputWithButton" || name === "button") &&
        props.trigger === "toggleModal"
          ? {
              onClick: () => {
                setModalContent(props.modalContent);
                setShowModal(true);
              },
            }
          : {}),
        ...((name === "InputWithButton" || name === "button") &&
        props.trigger === "toggleModal" &&
        props.modalContent1
          ? {
              onClickOption1: () => {
                setModalContent(props.modalContent1);
                setShowModal(true);
              },
            }
          : {}),
        ...((name === "InputWithButton" || name === "button") &&
        props.trigger === "toggleModal" &&
        props.modalContent2
          ? {
              onClickOption2: () => {
                setModalContent(props.modalContent2);
                setShowModal(true);
              },
            }
          : {}),
        ...(props?.trigger === "closeModal"
          ? {
              onClick: () => {
                closeModal(); // Trigger modal close when cancel button is clicked
              },
            }
          : {}),
        ...(name === "InputWithButton"
          ? { closeModal: () => setShowModal(false) }
          : {}),
        // ...((props?.userNameAndAvatar || props?.userAvatar) &&
        //   props.dropdownOptions[1]?.label === "Sign out" && {
        //     onClickOption: () => {
        //       setShowModal(true);
        //       setModalContent(props.dropdownOptions[1]?.modalContent);
        //     },
        //   }),
        // ...(props?.userNameAndAvatar === true ||
        //   (props?.userAvatar === true && {
        //     renderComponents,
        //   })),
      };

      return (
        <>
          {infoMessage && (
            <Toast
              key={index}
              type="info"
              message={infoMessage}
              onClose={() => setInfoMessage("")}
            />
          )}
          <ComponentToRender key={index} {...componentProps}>
            {children && renderComponents(children)}
          </ComponentToRender>
        </>
      );
    });
  };

  const closeModal = () => {
    setShowModal(false);
    setModalContent(null);
    updateLocalState({ showLoginModal: false });
  };

  return (
    <div>
      {isLoading ? (
        taskName === "fetchChatHistory" ? (
          <>
            {/* Load the JSON file and render its components */}
            {(() => {
              const jsonFile = require(`../../jsonFiles/chatPage.json`);
              return renderComponents(jsonFile);
            })()}
          </>
        ) : location.pathname === "/settings" ? (
          (() => {
            const jsonFile = require(`../../jsonFiles/settings.json`);
            return renderComponents(jsonFile);
          })()
        ) : (
          (() => {
            const jsonFile = require(`../../jsonFiles/loaderPage.json`);
            return renderComponents(jsonFile);
          })()
        )
      ) : (
        renderComponents(dynamicJsonData || jsonData)
      )}
      {showCookieConsent && <CookieConsent onAccept={handleAcceptCookies} />}
      {showModal && (
        <Modal
          closeModal={closeModal}
          showCloseButton={modalContent?.props?.showCloseButton}
        >
          {modalContent &&
            renderComponents([
              { ...modalContent, props: { ...modalContent.props, closeModal } },
            ])}
        </Modal>
      )}
      {location.pathname == "/" && localState.lowCreditsWarning && (
        <ProgressInfoToast
          type={localState.lowCreditsWarning.type}
          message={localState.lowCreditsWarning.message}
          action={localState.lowCreditsWarning.action}
          onClose={() => updateLocalState("lowCreditsWarning", null)}
          excludeCloseButton={true}
          className={`md:text-nowrap ${
            !isLongSidebar
              ? `md:top-4 top-20 md:left-[84px] md:max-w-[calc(100vw-100px)] w-[90%] md:translate-x-0 -translate-x-1/2 left-[50%]`
              : `md:top-4 top-20 md:left-[228px] md:max-w-[calc(100vw-244px)] w-[90%] md:translate-x-0 -translate-x-1/2 left-[50%]`
          } `}
        />
      )}
      {!showModal && location.pathname == "/" && !isLoggedIn && (
        <ProgressInfoToast
          type={"info"}
          message={"🎸 Beta unlocked! AI leveled up. Sign up! 🎉"}
          excludeCloseButton={true}
          className={`md:w-fit w-[90%] md:text-nowrap md:top-4 top-20 md:left-[57%] -translate-x-1/2 left-[50%] ${
            !isLongSidebar
              ? `md:top-4 top-20 md:left-[52.5%] -translate-x-1/2 left-[50%]`
              : `md:top-4 top-20 md:left-[57%] -translate-x-1/2 left-[50%]`
          } `}
        />
      )}
      {generationStatus && (
        <ProgressInfoToast
          type={generationStatus?.type}
          message={generationStatus?.message}
          action={generationStatus?.action}
          loading={generationStatus?.loading}
          onClose={handleToastClose}
          className={`md:w-fit w-[90%] md:text-nowrap ${
            !isLongSidebar
              ? `md:top-4 top-20 md:left-[52.5%] -translate-x-1/2 left-[50%]`
              : `md:top-4 top-20 md:left-[57.5%] -translate-x-1/2 left-[50%]`
          } `}
        />
      )}
    </div>
  );
};

// const DynamicComponent = ({
//   taskName,
//   jsObjectPath,
//   projectId,
//   renderComponents,
// }) => {
//   const [data, setData] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const { localState, updateLocalState } = useLocalState();

//   // useEffect(() => {
//   //   const fetchData = async () => {
//   //     try {
//   //       const data = await fetchDataAndBuildJson({
//   //         taskName,
//   //         jsObjectPath,
//   //       });
//   //       // Do something with the data, e.g., set it to state
//   //       setDynamicJsonData(data);
//   //     } catch (error) {
//   //       console.error("Error fetching data:", error);
//   //     }
//   //     updateLocalState("profileCreated", null);
//   //   };

//   //   localState.organizationCreated && fetchData();
//   //   localState.profileCreated && fetchData();
//   // }, [localState.profileCreated, localState.organizationCreated]);

//   // useEffect(() => {
//   //   let isMounted = true; // Flag to check if component is still mounted

//   //   const fetchDynamicData = async () => {
//   //     try {
//   //       let jsonData;

//   //       // Check if user name and avatar data is already in local state
//   //       if (
//   //         taskName === "fetchUserNameAndAvatar" &&
//   //         localState.userNameAndAvatar
//   //       ) {
//   //         jsonData = await fetchDataAndBuildJson(
//   //           taskName,
//   //           jsObjectPath,
//   //           { localStateData: localState.userNameAndAvatar },
//   //           true
//   //         );
//   //       } else if (taskName === "fetchUserAvatar" && localState.userAvatar) {
//   //         jsonData = await fetchDataAndBuildJson(
//   //           taskName,
//   //           jsObjectPath,
//   //           { localStateData: localState.userAvatar },
//   //           true
//   //         );
//   //       } else if (
//   //         taskName === "fetchTeamNameAndAvatar" &&
//   //         localState.teamNameAndAvatar
//   //       ) {
//   //         jsonData = await fetchDataAndBuildJson(
//   //           taskName,
//   //           jsObjectPath,
//   //           { localStateData: localState.teamNameAndAvatar },
//   //           true
//   //         );
//   //       } else {
//   //         jsonData = await fetchDataAndBuildJson(taskName, jsObjectPath, {
//   //           projectId,
//   //           localState,
//   //           updateLocalState,
//   //         });
//   //       }

//   //       if (isMounted) {
//   //         setData(jsonData); // Only update state if component is still mounted
//   //       }
//   //     } catch (error) {
//   //       if (isMounted) {
//   //         console.error("Error fetching dynamic data:", error);
//   //       }
//   //     } finally {
//   //       if (isMounted) {
//   //         setIsLoading(false); // Only update state if component is still mounted
//   //       }
//   //     }
//   //   };

//   //   fetchDynamicData();

//   //   return () => {
//   //     isMounted = false; // Cleanup function sets flag to false on unmount
//   //   };
//   // }, []);

//   // if (isLoading) return <p>Loading ...</p>;

//   return <>{data && renderComponents(data)}</>;
// };

export default Page;
