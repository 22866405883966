import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Page from "./components/component_builder/Page";
import Cancel from "./components/custom_components/Cancel";
import CreateOrganization from "./components/custom_components/CreateOrganization";
import InviteMembers from "./components/custom_components/InviteMembers";
import Onboarding from "./components/custom_components/Onboarding";
import Subscription from "./components/custom_components/Subscription/Subscription";
import Success from "./components/custom_components/Success";
import { AudioPlayerProvider } from "./contexts/AudioPlayerContext";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { LocalStateProvider } from "./contexts/LocalStateProvider";
import { UserProvider } from "./contexts/UserContext";
import routes from "./jsonFiles/routes.json";
import store from "./store";
import ResponsiveNotification from "./components/custom_components/ResponsiveNotification";

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? children : <Navigate to="/" />;
};

const App = () => {
  const [routeComponents, setRouteComponents] = useState([]);

  useEffect(() => {
    const initializeRoutes = async () => {
      const dynamicRoutePromises = routes.map(async (route) => {
        if (route.taskName) {
          return { ...route };
        } else {
          const jsonData = require(`./jsonFiles/${route.json}`);
          return { ...route, jsonData };
        }
      });

      const resolvedRoutes = await Promise.all(dynamicRoutePromises);
      setRouteComponents(resolvedRoutes.filter((route) => route !== null));
    };

    initializeRoutes();
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <UserProvider>
            <AuthProvider>
              <LocalStateProvider>
                <Subscription />
                <Onboarding />
                <InviteMembers />
                <AudioPlayerProvider>
                  <Routes>
                    {routeComponents.map((route, index) => (
                      <Route
                        key={index}
                        path={route.path}
                        element={
                          route.taskName ? (
                            <ProtectedRoute>
                              <Page
                                taskName={route.taskName}
                                jsObjectPath={route.jsObjectPath}
                                path={route.path}
                              />
                            </ProtectedRoute>
                          ) : (
                            <Page jsonData={route.jsonData} />
                          )
                        }
                      />
                    ))}
                    <Route
                      path="/create-team"
                      element={<CreateOrganization />}
                    />
                    <Route path="/onboarding" element={<Onboarding />} />
                    <Route path="/success" element={<Success />} />
                    <Route path="/cancel" element={<Cancel />} />
                    <Route
                      path="/responsiveNotification"
                      element={<ResponsiveNotification />}
                    />
                  </Routes>
                </AudioPlayerProvider>
              </LocalStateProvider>
            </AuthProvider>
          </UserProvider>
        </Router>
      </Provider>
    </div>
  );
};

export default App;
