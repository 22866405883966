import React, { useState } from "react";
import {
  Bars,
  Xmark,
  House,
  Rectangles4,
  Globe,
  ThumbsUp,
  Persons,
  PersonPlus,
} from "@gravity-ui/icons";
import { useNavigate, useLocation } from "react-router-dom";
import TeamNameAndAvatar from "./TeamNameAndAvatar";
import { useLocalState } from "../../contexts/LocalStateProvider";
import FeedbackForm from "./FeedbackForm";
import CreditsCard from "./CreditsCard";

const MobileMenu = ({ header, footerLinks }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { localState, updateLocalState } = useLocalState();

  const isActivePath = (path) => {
    return location.pathname === path;
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  const getNavItemClass = (path) => {
    return `flex items-center w-full p-3 ${
      isActivePath(path)
        ? "text-white"
        : "text-[#878188] hover:text-white hover:bg-white/5"
    } rounded-lg`;
  };

  return (
    <div className="md:hidden">
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className="flex items-center justify-center p-2 text-white hover:text-white"
      >
        {isOpen ? (<Xmark className="w-6 h-6 pt-1" />) : (<Bars className="w-6 h-6 pt-1" />)}
        
      </button>

      {isOpen && (
        <div className="fixed flex-col gap-0 inset-0 top-[4.1rem] pt-2 bg-[#100f10] h-full w-full max-h-[calc(100vh-4rem)] z-40">
          <nav className={`flex-col px-2 space-y-1 w-full h-full scrollable-content grow ${localState.isAdmin ? "max-h-[calc(100vh-24rem)]" : "max-h-[calc(100vh-21rem)]"}`}>
            <button
              onClick={() => handleNavigation("/")}
              className={getNavItemClass("/")}
            >
              <House className="w-5 h-5 mr-3" />
              <span>Home</span>
            </button>
            <button
              onClick={() => handleNavigation("/projects")}
              className={getNavItemClass("/projects")}
            >
              <Rectangles4 className="w-5 h-5 mr-3" />
              <span>Projects</span>
            </button>
            <button
              onClick={() => handleNavigation("/discover")}
              className={getNavItemClass("/discover")}
            >
              <Globe className="w-5 h-5 mr-3" />
              <span>Discover</span>
            </button>
            <button
              onClick={() => handleNavigation("/liked-tracks")}
              className={getNavItemClass("/liked-tracks")}
            >
              <ThumbsUp className="w-5 h-5 mr-3" />
              <span>Liked Tracks</span>
            </button>
            <button
              onClick={() => {
                setIsOpen(false);
                setShowFeedback(true);
              }}
              className="flex items-center w-full p-3 text-[#878188] hover:text-white hover:bg-white/5 rounded-lg"
            >
              <img src="/SideBarFeedback.svg" className="w-5 h-5 mr-3 mt-1" />
              <span>Feedback</span>
            </button>
            
          </nav>
          <div className="px-4 w-full">
              <CreditsCard />
            </div>
          {/* Team Section */}

          <div className="flex-col w-full p-4">
            {localState.isAdmin && (
              <button
                onClick={() => {
                  updateLocalState("organizationClicked", true);
                  updateLocalState("organizationInviteClicked", true);
                  navigate("/settings");
                }}
                className="flex items-center w-full gap-2 px-3 h-11 bg-white/5 rounded-full font-normal text-sm text-[#878188] hover:text-white transition duration-200 mb-3"
              >
                <PersonPlus className="w-4 h-4" />
                <span>Add team members</span>
              </button>
            )}
            <TeamNameAndAvatar />
            {!localState.isTeamMember && (
              <button
                onClick={() => {
                  handleNavigation("/create-team");
                }}
                className="w-full flex gap-2 items-center h-11 px-3 bg-white/5 rounded-full text-[#878188] hover:text-white"
              >
                <Persons className="w-5 h-5"/>
                <span className="text-base">Create a team</span>
              </button>
            )}
          </div>

          <div className="flex flex-col w-full p-4 gap-1 border-t border-[#2e2e2d]">
            <h2 className="text-xl font-medium text-white">Quick Links</h2>
            <div className="flex flex-wrap gap-4">
              {footerLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-extralight hover:text-[#EDB4FF] text-[#d54dff] hover:font-normal transition duration-500"
                >
                  {link.caption}
                </a>
              ))}
            </div>
          </div>
        </div>
      )}
      {showFeedback && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <FeedbackForm closeModal={() => setShowFeedback(false)} />
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
