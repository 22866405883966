import React, { useState } from "react";
import { Person, CircleExclamation, PencilToLine } from "@gravity-ui/icons";
import Modal from "../modal/Modal";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import InputWithButton from "../generic_components/InputWithButton";
import { usePostHog } from "posthog-js/react";
import Toast from "../generic_components/Toast";

const Account = ({ userData }) => {
  const posthog = usePostHog();
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("info");
  const navigate = useNavigate();
  const { logout } = useAuth();

  return (
    <div className="flex md:flex-row flex-col bg-zinc-900 text-white relative">
      <div className="flex-1 flex-col justify-center bg-[#100f10] scrollable-content-team-settings overflow-y-auto rounded-lg relative w-full">
        <div
          id="account-settings-container"
          className="flex flex-col items-center justify-top w-full"
        >
          <div className="relative max-w-3xl items-center w-full h-full">
            {/* General Section */}
            <div className="px-4 py-4">
              <h2 className="font-normal text-base text-white mb-2 ml-2">
                General
              </h2>
              <div className="space-y-4">
                <div className="py-1 px-3 text-base text-white bg-[#1d1b1d] rounded-lg">
                  <div className="flex font-light justify-between items-center py-5">
                    <div className="flex flex-col">
                      <span>Language</span>
                      <span className="text-sm font-extralight text-[#878188]">
                        We're adding more languages soon!
                      </span>
                    </div>
                    <span className="font-extralight text-sm">
                      English (US)
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Billing Section */}
            {/* <div className="px-4 py-4">
              <h2 className="font-normal text-base text-white mb-2 ml-2">
                Billing
              </h2>
              <div className="space-y-4">
                <div className="py-1 px-3 text-base text-white bg-[#1d1b1d] rounded-lg">
                  <div className="flex font-light justify-between items-center py-5">
                    <div className="flex flex-col">
                      <span>Manage Billing</span>
                      <span className="text-sm font-extralight text-[#878188]">
                        {`You are currently subscribed to the ${userData?.subscription_type} plan.`}
                      </span>
                    </div>
                    <div className="flex font-light justify-between items-center py-4">
                      <button
                        className="h-8 bg-white/5 hover:bg-white/10 px-2.5 text-[#878188] text-sm font-normal rounded-full hover:text-white duration-200"
                        // onClick={() => setShowChangePasswordModal(true)}
                        id="account-change-password-button"
                      >
                        Manage
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* Accounts Section */}
            <div className="px-4 py-4">
              <h2 className="font-normal text-base text-white mb-2 ml-2">
                Accounts
              </h2>
              <div className="space-y-4">
                <div className="py-1 px-3 text-base text-white bg-[#1d1b1d] rounded-lg">
                  <div className="flex font-light justify-between items-center border-b border-solid border-[#2c2a2c] py-5">
                    <span>Email address</span>
                    <span className="font-extralight text-sm">
                      {userData?.email}
                    </span>
                  </div>
                  <div className="flex font-light justify-between items-center py-4">
                    <span>Password</span>
                    <button
                      className="h-8 bg-white/5 hover:bg-white/10 px-2.5 text-[#878188] text-sm font-normal rounded-full hover:text-white duration-200"
                      onClick={() => {
                        posthog.capture("Initiate Password Change");
                        setShowChangePasswordModal(true);
                      }}
                      id="account-change-password-button"
                    >
                      Change password
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* System Section */}
            <div className="px-4 py-4">
              <h2 className="font-normal text-base text-white mb-2 ml-2">
                System
              </h2>
              <div className="pb-10">
                <div className="py-1 px-3 text-base text-white bg-[#1d1b1d] rounded-lg">
                  <div className="flex font-light justify-between items-center border-b border-solid border-[#2c2a2c] py-4">
                    <span>Active account</span>
                    <button
                      className="h-8 bg-white/5 hover:bg-white/10 px-2.5 text-[#878188] text-sm font-normal rounded-full hover:text-white duration-200"
                      onClick={() => {
                        posthog.capture("Click to open Sign Out modal");
                        setShowSignOutModal(true);
                      }}
                    >
                      Sign out
                    </button>
                  </div>
                  <div className="flex font-light justify-between items-center py-4">
                    <span>Delete account</span>
                    <button
                      className="h-8 bg-[#dd3f3f] hover:bg-[#BB2D2D] px-2.5 text-white text-sm font-normal rounded-full"
                      onClick={() => {
                        posthog.capture("Initiate Account Deletion");
                        setShowDeleteAccountModal(true);
                      }}
                      id="account-delete-button"
                    >
                      Delete
                    </button>
                  </div>
                </div>

                <div className="md:flex md:flex-row flex px-4 md:space-y-0 md:py-4 py-4 space-y-4 flex-col md:justify-between md:items-center bg-[#1d1b1d] rounded-lg mt-6">
                  <div className="flex space-x-6">
                    <a
                      href="https://info.wubble.ai/faqs"
                      className="flex flex-row items-center gap-1 text-[#878188] text-sm font-normal rounded-full hover:text-white duration-200"
                      id="help-faq-button"
                    >
                      <CircleExclamation className="w-4 h-4" />
                      Help and FAQ
                    </a>
                    <a
                      href="https://info.wubble.ai/feedback"
                      className="flex flex-row items-center gap-1 text-[#878188] text-sm font-normal rounded-full hover:text-white duration-200"
                      id="feedback-button"
                    >
                      <PencilToLine className="w-4 h-4" />
                      Give us feedback
                    </a>
                  </div>
                  <p className="text-[#878188] font-extralight text-sm">
                    © 2024 Wubble AI
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}
      {showSignOutModal && (
        <Modal closeModal={() => setShowSignOutModal(false)}>
          <div className="text-white w-full bg-[#1d1b1d] border border-solid border-[#3c393c] rounded-lg">
            <h2 className="text-lg text-left px-3 py-3">Sign out</h2>
            <div className="w-full border-t border-t-[#2d2a2d]" />
            <p className="text-base font-extralight text-left pt-3 pb-5 px-3">
              Are you sure you want to sign out?
            </p>
            <div className="flex justify-end space-x-3 mx-3">
              <button
                onClick={() => setShowSignOutModal(false)}
                className="px-3 h-9 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.10))] text-sm text-[#878188] hover:text-white bg-white/5 rounded-full"
                id="sign-out-modal-cancel-button"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  logout();
                  setShowSignOutModal(false);
                  navigate("/");
                  posthog.capture("Click on Sign Out");
                }}
                className="px-3 h-9 mb-3 mr-3 hover:bg-[#E0DFE1] bg-white text-[#100f10] text-sm font-normal rounded-full"
                id="sign-out-modal-confirm-button"
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}

      {showChangePasswordModal && (
        <Modal closeModal={() => setShowChangePasswordModal(false)}>
          <div className="w-full bg-[#1d1b1d] border border-solid border-[#3c393c] rounded-lg">
            <p className="text-left text-lg px-3 py-3">Change Password</p>
            <div className="w-full border-t border-t-[#2d2a2d] mb-3" />
            <InputWithButton
              formClassName="space-y-4 mb-5 bg-[#1d1b1d] items-center w-full px-3"
              inputFields={[
                {
                  type: "password",
                  name: "password",
                  className:
                    "w-full h-9 px-3 font-extralight text-sm bg-transparent rounded-full border border-solid border-[#878188] text-white placeholder-[#878188] focus:outline-none appearance-none focus:ring-0",
                  placeholder: "Enter your current password",
                  label: "Current Password",
                  labelClassName: "block text-left ml-2 mb-2 text-sm font-extralight",
                },
                {
                  type: "password",
                  name: "newPassword",
                  className:
                    "w-full h-9 px-3 font-extralight text-sm bg-transparent rounded-full border border-solid border-[#878188] text-white placeholder-[#878188] focus:outline-none appearance-none focus:ring-0",
                  placeholder: "Enter your new password",
                  label: "New Password",
                  passwordRequirements: {
                    uppercase: "Contains 1 or more Uppercase characters",
                    numeric: "Contains 1 or more Numeric characters",
                    special: "Contains 1 or more Special characters",
                    isValidLength: "Minimum 8 characters",
                  },
                  labelClassName: "block text-left ml-2 mb-2 text-sm font-extralight",
                },
                {
                  type: "password",
                  name: "confirmPassword",
                  className:
                    "w-full h-9 px-3 font-extralight text-sm bg-transparent rounded-full border border-solid border-[#878188] text-white placeholder-[#878188] focus:outline-none appearance-none focus:ring-0",
                  placeholder: "Confirm your new password",
                  label: "Confirm New Password",
                  labelClassName: "block text-left ml-2 mb-2 text-sm font-extralight",
                },
              ]}
              buttonClassName="px-3 h-9 mb-3 mr-3 text-[#878188] text-sm font-normal rounded-full disabled:cursor-not-allowed"
              buttonText="Change"
              onButtonClick="validateChangePassword"
              onCancelClick={() => setShowChangePasswordModal(false)}
              closeModal={() => setShowChangePasswordModal(false)}
              cancelButtonClassName="px-3 h-9 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.10))] text-sm text-[#878188] hover:text-white bg-white/5 rounded-full"
              cancelButtonText="Cancel"
              trigger="closeModal"
              requestData={{
                taskName: "changePassword",
                payload: {},
              }}
              enableConditionalBackgroundWhite={true}
            />
          </div>
        </Modal>
      )}

      {showDeleteAccountModal && (
        <Modal closeModal={() => setShowDeleteAccountModal(false)}>
          <div className="w-full bg-[#1d1b1d] border border-solid border-[#3c393c] rounded-lg">
            <p className="text-left text-lg px-3 py-3">Delete Account</p>
            <div className="w-full border-t border-t-[#2d2a2d] mb-3" />
            <InputWithButton
              formClassName="space-y-4 text-left w-full bg-[#1d1b1d] px-3 mb-5"
              inputFields={[
                {
                  type: "select",
                  name: "reason",
                  className:
                    "w-full h-9 px-3 py-1 font-extralight text-sm bg-[#1d1b1d] mt-2 rounded-full border border-solid border-[#878188] text-[#878188]",
                  options: [
                    {
                      value: "",
                      label: "Select a reason for deleting your account",
                    },
                    {
                      value: "no_longer_need",
                      label: "No longer need the service",
                    },
                    {
                      value: "found_alternative",
                      label: "Found an alternative solution",
                    },
                    {
                      value: "did_not_meet_expectations",
                      label: "Service didn't meet my expectations",
                    },
                    {
                      value: "technical_issues",
                      label: "Technical issues or bugs",
                    },
                    {
                      value: "privacy_concerns",
                      label: "Concerned about privacy or data security",
                    },
                    {
                      value: "too_expensive",
                      label: "Too expensive or not worth the cost",
                    },
                    {
                      value: "infrequent_use",
                      label: "Don't use it as often as anticipated",
                    },
                    {
                      value: "dissatisfied_support",
                      label: "Dissatisfied with customer support",
                    },
                    {
                      value: "temporary_account",
                      label: "Temporary account for a specific project",
                    },
                    { value: "other", label: "Other (please specify)" },
                  ],
                  label: "Tell us why you want to delete this account",
                  labelClassName: "text-sm font-extralight",
                },
                {
                  type: "info",
                  content: [
                    "Deleting this account will:",
                    "Delete all the songs you have generated",
                    "Delete all the projects",
                    "You will miss the opportunity to get new exciting updates",
                  ],
                  className: "text-sm font-extralight",
                },
                {
                  type: "text",
                  name: "confirmDelete",
                  className:
                    "w-full h-9 px-3 font-extralight text-sm bg-transparent rounded-full border border-solid border-[#878188] text-white placeholder-[#878188] focus:outline-none appearance-none focus:ring-0",
                  placeholder: "Please type 'CONFIRM DELETE' to proceed",
                  label: "Confirm Delete",
                  labelClassName: "block text-sm mb-2 font-extralight",
                },
              ]}
              buttonClassName="px-3 h-9 mb-3 mr-3 bg-[#4e4a4f] text-sm font-normal rounded-full disabled:cursor-not-allowed"
              buttonText="Delete"
              onButtonClick="validateDeleteAccount"
              onCancelClick={() => setShowDeleteAccountModal(false)}
              closeModal={() => setShowDeleteAccountModal(false)}
              cancelButtonClassName="px-3 h-9 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.10))] text-sm font-normal text-[#878188] hover:text-white bg-white/5 rounded-full"
              cancelButtonText="Cancel"
              trigger="closeModal"
              enableConditionalBackgroundRed={true}
              requestData={{
                taskName: "deleteAccount",
                payload: {},
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Account;
