import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { useLocalState } from "./LocalStateProvider";

const AudioPlayerContext = createContext();
export const AudioPlayerProvider = ({ children }) => {
  const [audioSrc, setAudioSrc] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1); // default volume
  const [isBuffering, setIsBuffering] = useState(false); // New state for buffering
  const audioRef = useRef(null);
  const [streamingSrc, setStreamingSrc] = useState(null);
  const [aiResponseId, setAiResponseId] = useState(null);
  const [discoverId, setDiscoverId] = useState(null);
  const { localState, updateLocalState } = useLocalState();

  const convertDurationToSeconds = (durationStr) => {
    const parts = durationStr.split(":");
    let seconds = 0;
    if (parts.length === 2) {
      // mm:ss format
      const [minutes, secs] = parts;
      seconds = parseInt(minutes) * 60 + parseFloat(secs);
    } else if (parts.length === 3) {
      // hh:mm:ss format (optional)
      const [hours, minutes, secs] = parts;
      seconds =
        parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseFloat(secs);
    }
    return seconds;
  };

  const playAudio = (src, title, description, imageSrc, passedDuration) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
      audioRef.current.removeEventListener(
        "loadedmetadata",
        handleLoadedMetadata
      );
      audioRef.current.removeEventListener("error", handleError);
      audioRef.current.removeEventListener("ended", handleEnded);
      // audioRef.current.removeEventListener("waiting", handleWaiting); // Added listener
      // audioRef.current.removeEventListener("playing", handlePlaying); // Added listener
    }

    // Create a new Audio instance for streaming
    audioRef.current = new Audio(src);
    audioRef.current.volume = volume; // set initial volume
    // Set the preload attribute to ensure audio is fetched immediately
    audioRef.current.preload = "auto";
    // Add event listeners
    audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
    audioRef.current.addEventListener("ended", handleEnded);

    audioRef.current.addEventListener("error", handleError);
    if (typeof passedDuration === "string") {
      passedDuration = convertDurationToSeconds(passedDuration);
    }

    if (Number.isFinite(passedDuration) && passedDuration > 0) {
      // If a valid duration is passed, use it and do not attach the loadedmetadata event listener
      setDuration(passedDuration);
    } else {
      // If no valid duration is passed, rely on the loadedmetadata event
      audioRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);
    }

    // audioRef.current.addEventListener("waiting", handleWaiting); // Add listener for buffering
    // audioRef.current.addEventListener("playing", handlePlaying); // Add listener for buffering end
    // console.log(audioRef.current.duration, duration);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      setCurrentTime(0);
      audioRef.current.currentTime = 0;
    }

    // Start playback
    audioRef.current.play().catch((error) => {
      console.error("Error playing audio:", error);
    });

    // Update state
    setAudioSrc(src);
    setTitle(title);
    setDescription(description);
    setImageSrc(imageSrc);
    setIsPlaying(true);
    updateLocalState("isAudioPlaying", true);
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const resumeAudio = () => {
    if (audioRef.current) {
      // console.log(duration);

      audioRef.current.play().catch((error) => {
        console.error("Error resuming audio:", error);
      });
      setIsPlaying(true);
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // reset playback position
      audioRef.current = null;
      setAudioSrc(null);
      setTitle("");
      setDescription("");
      setImageSrc("");
      setIsPlaying(false);
      setCurrentTime(0);
      setDuration(0);
      setAiResponseId(null);
      setDiscoverId(null);
      updateLocalState("isAudioPlaying", false);
      // setIsBuffering(false); // Stop buffering indicator when stopped
    }
  };

  const handleEnded = () => {
    setIsPlaying(false);
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      const audioDuration = audioRef.current.duration;

      // Check if audioDuration is a valid number
      if (Number.isFinite(audioDuration) && audioDuration > 0) {
        // Use audioRef's duration if it's valid and greater than 0
        setDuration(audioDuration);
      }
    }
  };

  const handleError = (error) => {
    console.error("Audio error:", error);
    // Implement further error handling if needed
    // setIsBuffering(false); // Stop buffering indicator on error
  };

  // const handleWaiting = () => {
  //   setIsBuffering(true); // Start buffering indicator
  // };

  // const handlePlaying = () => {
  //   setIsBuffering(false); // Stop buffering indicator
  // };

  const seekAudio = (time) => {
    if (isBuffering) return;

    if (audioRef.current) {
      // Ensure time doesn't exceed the duration
      const safeTime = Math.min(time, duration);

      // Ensure time is not negative
      const clampedTime = Math.max(0, safeTime);

      audioRef.current.currentTime = clampedTime;
      setCurrentTime(clampedTime);
    }
  };

  const changeVolume = (newVolume) => {
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
    setVolume(newVolume);
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  return (
    <AudioPlayerContext.Provider
      value={{
        audioSrc,
        title,
        description,
        imageSrc,
        isPlaying,
        currentTime,
        duration,
        volume,
        isBuffering,
        streamingSrc,
        setStreamingSrc,
        setAiResponseId,
        setDiscoverId,
        aiResponseId,
        discoverId,
        setIsBuffering,
        playAudio,
        pauseAudio,
        resumeAudio,
        stopAudio,
        seekAudio,
        changeVolume,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};

export const useAudioPlayer = () => useContext(AudioPlayerContext);
