import React, { useEffect, useRef, useState } from "react";
import UserChatBox from "./UserChatBox";
import AIChatBox from "./AIChatBox";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import { getCookie } from "../../utils/cookieUtils";
import axios from "axios";
import ChatBoxLoader from "./ChatBoxLoader";
import apiService from "../../api/apiService";
import Toast from "../generic_components/Toast";
import { setUserData } from "../../store/reducers/userSlice";
import { useDispatch } from "react-redux";
import { usePostHog } from "posthog-js/react";
import {
  setSubscriptionModal,
  setSubscriptionTypeModal,
  setRedirectAfterSelectedPlan,
} from "../../store/reducers/subscriptionSlice";

const ChatInterface = ({ initialMessages = [], projectId }) => {
  const posthog = usePostHog();
  const { localState, updateLocalState } = useLocalState();
  const [messages, setMessages] = useState(initialMessages);
  const [lastUserInput, setLastUserInput] = useState(null);
  const [isRegeneration, setIsRegeneration] = useState(false);
  const [regeneratingResponseId, setRegeneratingResponseId] = useState(null);
  const [beforeResponseLoader, setBeforeResponseLoader] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [beforeRegenerateLoaderId, setBeforeRegenerateLoaderId] =
    useState(null);

  const messagesEndRef = useRef(null);
  const {
    playAudio,
    setIsBuffering,
    setStreamingSrc,
    setAiResponseId,
    setDiscoverId,
    stopAudio,
  } = useAudioPlayer();
  const [loadingResponseId, setLoadingResponseId] = useState(null);
  const [streamingResponseId, setStreamingResponseId] = useState(null);
  const [toastType, setToastType] = useState("info");
  const [currentRequestId, setCurrentRequestId] = useState(null);
  const dispatch = useDispatch();
  const [projectName, setProjectName] = useState(null);
  const [generationCount, setGenerationCount] = useState(0);

  // Inside useEffect that handles new messages
  useEffect(() => {
    if (localState.newMessage && !isRegeneration) {
      setGenerationCount((prev) => prev + 1);
    }
  }, [localState.newMessage, isRegeneration]);

  useEffect(() => {
    // Count existing AI messages with audio on component mount
    const existingAudioMessages = messages.filter(
      (msg) => msg.sender === "Wubble" && msg.audio
    );
    setGenerationCount(existingAudioMessages.length);
  }, []);

  useEffect(() => {
    const fetchProjectName = async () => {
      try {
        const response = await apiService.handleProtectedRoute(
          "fetchProjectName",
          {
            projectId: projectId,
          }
        );
        if (response.success) {
          setProjectName(response.projectName);
        }
      } catch (error) {
        console.error("Error fetching project name:", error);
      }
    };

    if (projectId) {
      fetchProjectName();
    }
  }, [projectId]);

  const userId = getCookie("user_id");

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const filterAIResponsesWithAudio = (messages) => {
    return messages.filter((msg) => msg.sender === "Wubble" && msg.audio);
  };

  // const scrollToMessage = (responseId) => {
  //   const messageElement = document.getElementById(responseId);
  //   if (messageElement) {
  //     messageElement.scrollIntoView({ behavior: "smooth" });
  //     updateLocalState("scrollToResponseId", null);
  //   }
  // };

  useEffect(() => {
    if (localState.scrollToUserInput) {
      const userInputElement = document.getElementById(
        `user-input-${localState.scrollToUserInput}`
      );
      if (userInputElement) {
        userInputElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [localState.scrollToUserInput]);

  useEffect(() => {
    localState.isAudioPlaying
      ? setIsAudioPlaying(true)
      : setIsAudioPlaying(false);
  }, [localState.isAudioPlaying]);

  useEffect(() => {
    const fetchProjectSetByUser = async () => {
      try {
        const response = await apiService.sendRequest("fetchProjectSetByUser", {
          project_id: projectId,
        });
        if (response.success) {
          updateLocalState("projectNameSetByUser", response.set_by_user);
        }
      } catch (error) {
        console.error("Error fetching project set_by_user status:", error);
      }
    };

    fetchProjectSetByUser();
  }, [projectId]);

  // useEffect(() => {
  //   if (localState.scrollToResponseId) {
  //     scrollToMessage(localState.scrollToResponseId);
  //   }
  // }, [localState.scrollToResponseId]);

  useEffect(() => {
    const aiResponsesWithAudio = filterAIResponsesWithAudio(messages);
    const groupedVersionHistory = groupVersionHistory(aiResponsesWithAudio);
    updateLocalState("versionHistory", groupedVersionHistory);
  }, [messages, localState.newMessage]);

  // const groupVersionHistory = (responses) => {
  //   const generationGroups = {};
  //   let currentGroupId = null;

  //   responses.forEach((response, index) => {
  //     if (response.responseType === "generation") {
  //       if (response.regenerated && response.parent_response_id) {
  //         // Handle regenerated responses by adding them to parent's variations
  //         const parentId = response.parent_response_id;
  //         if (!generationGroups[parentId]) {
  //           generationGroups[parentId] = {
  //             original: null,
  //             variations: [],
  //           };
  //         }
  //         generationGroups[parentId].variations.push(response);
  //       } else {
  //         // Handle normal generations
  //         currentGroupId = response.parent_response_id || response.id;
  //         generationGroups[currentGroupId] = {
  //           original: response,
  //           variations: [],
  //         };
  //       }
  //     } else if (response.responseType === "extension") {
  //       // Handle extensions
  //       if (currentGroupId && generationGroups[currentGroupId]) {
  //         generationGroups[currentGroupId].variations.push(response);
  //       }
  //     } else if (response.responseType === "edit") {
  //       // Handle edits
  //       const previousResponse = responses[index - 1];
  //       if (previousResponse) {
  //         const groupId = previousResponse.parent_response_id || currentGroupId;
  //         if (groupId && generationGroups[groupId]) {
  //           generationGroups[groupId].variations.push(response);
  //         } else if (groupId) {
  //           generationGroups[groupId] = {
  //             original: null,
  //             variations: [response],
  //           };
  //         }
  //       }
  //     }
  //   });

  //   return Object.values(generationGroups)
  //     .filter((group) => group.original || group.variations.length > 0)
  //     .map((group) => {
  //       const sortedVariations = group.variations.sort(
  //         (a, b) => new Date(b.created_at) - new Date(a.created_at)
  //       );

  //       return {
  //         latest: sortedVariations[0] || group.original,
  //         hasVariations: group.variations.length > 0,
  //         versions: group.original
  //           ? [...sortedVariations, group.original]
  //           : sortedVariations,
  //       };
  //     })
  //     .sort(
  //       (a, b) => new Date(b.latest.created_at) - new Date(a.latest.created_at)
  //     );
  // };

  const groupVersionHistory = (responses) => {
    const generationGroups = {};
    let currentGroupId = null;

    responses.forEach((response, index) => {
      if (response.is_error) return;
      if (response.responseType === "generation") {
        if (response.regenerated && response.parent_response_id) {
          // Handle regenerated responses
          const parentId = response.parent_response_id;
          if (!generationGroups[parentId]) {
            generationGroups[parentId] = {
              original: null,
              variations: [],
            };
          }

          // If original is null, set current response as original
          if (!generationGroups[parentId].original) {
            generationGroups[parentId].original = response;
          } else {
            // Else, add to variations
            generationGroups[parentId].variations.push(response);
          }
        } else {
          // Handle normal generations
          currentGroupId =
            response.parent_response_id || response.ai_response_id;
          generationGroups[currentGroupId] = {
            original: response,
            variations: [],
          };
        }
      } else if (
        response.responseType === "edit" ||
        response.responseType === "extension"
      ) {
        // For edit or extension responses, add them to the current group
        if (currentGroupId && generationGroups[currentGroupId]) {
          generationGroups[currentGroupId].variations.push(response);
        } else {
          let foundParent = false;

          // If no current group, look back for the last generation response
          for (let i = index - 1; i >= 0; i--) {
            const prevResponse = responses[i];
            if (prevResponse.responseType === "generation") {
              const groupId =
                prevResponse.parent_response_id || prevResponse.ai_response_id;
              if (generationGroups[groupId]) {
                generationGroups[groupId].variations.push(response);
                foundParent = true;
                break;
              }
            }
          }

          // If no parent generation is found, create a standalone group for the extension
          if (!foundParent && response.responseType === "extension") {
            const standaloneGroupId = response.ai_response_id;
            generationGroups[standaloneGroupId] = {
              original: response, // Treat the extension as the original
              variations: [], // No variations for standalone extensions
              isStandalone: true, // Flag to indicate this is a standalone entry
            };
          }
        }
      } else {
        // Handle other response types if necessary
      }
    });

    console.log("generationGroups:", generationGroups);

    return (
      Object.values(generationGroups)
        .filter((group) => group.original || group.variations.length > 0)
        .map((group) => {
          // Sort variations with latest first
          const sortedVariations = group.variations.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );

          // Build the versions array with latest first, including the original at the end
          const versions =
            sortedVariations.length > 0
              ? [...sortedVariations, group.original].filter(
                  (v) => v !== null && v !== undefined
                )
              : [group.original].filter((v) => v !== null && v !== undefined);

          // Get the latest response in the group
          const latest = versions[0];

          return {
            latest: latest,
            hasVariations: group.variations.length > 0,
            versions: versions,
          };
        })
        // Sort groups by the created_at of the latest response, latest first
        .sort(
          (a, b) =>
            new Date(b.latest.created_at) - new Date(a.latest.created_at)
        )
    );
  };

  const formatTime = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  useEffect(() => {
    if (!localState.scrollToAiResponse) {
      scrollToBottom();
    }
  }, [messages, isRegeneration, beforeResponseLoader, localState.newMessage]);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (localState.textInputHeight > 24) {
      setScrollHeight(localState.textInputHeight);
    } else setScrollHeight(0);
  }, [localState.textInputHeight]);

  useEffect(() => {
    if (
      localState.newMessage &&
      !isRegeneration &&
      localState.newMessage?.get("project_id") === projectId
    ) {
      const formData = localState.newMessage;
      const messageObject = {};

      // Extract data from FormData
      formData.forEach((value, key) => {
        if (key === "file_name" || key === "mime_type") {
          // Convert the value (which should be a File or Blob) to a file object
          // const file = formData.get("media");

          messageObject.file = {
            name: formData.get("file_name"),
            type: formData.get("mime_type"),
            // content: file, // Store the actual file object
          };
        } else if (key === "message_id") {
          messageObject.id = value;
        } else if (key === "sentAt") {
          messageObject.sentAt = value;
        } else if (key === "prompt") {
          messageObject.prompt = value;
        } else {
          messageObject[key] = value;
        }
      });

      // Add additional fields to the message object
      messageObject.sender = "User";
      messageObject.text = messageObject.prompt || "";
      messageObject.avatar = "https://via.placeholder.com/50";

      // Avoid adding duplicate messages
      if (!messages.some((msg) => msg.id === Number(messageObject.id))) {
        // console.log("Adding new message:", messageObject);

        setMessages((prevMessages) => [...prevMessages, messageObject]);
        scrollToBottom();
      } else {
        // console.log("Duplicate message detected:", messageObject);
      }

      // console.log(messages);

      // Update last user input and reset new message
      setLastUserInput(messageObject);
      updateLocalState("newMessage", null);

      // Generate requestId
      const requestId = `${Date.now()}-${Math.random()
        .toString(36)
        .slice(2, 11)}`;

      // Append requestId to the existing FormData
      formData.append("requestId", requestId);
      setTimeout(() => {
        setBeforeResponseLoader(true);
      }, 300);
      updateLocalState("responseInProgress", true);
      // Send AI response request with updated FormData
      sendAiResponseRequest(formData);
    }
  }, [localState.newMessage, isRegeneration]);

  useEffect(() => {
    // If lastUserInput is null, retrieve it from the last message in messages
    if (!lastUserInput) {
      const lastUserMessage = messages.findLast((msg) => msg.sender === "User");
      // console.log("lastUserMessage:", lastUserMessage);

      if (lastUserMessage) {
        const populatedLastUserInput = {
          ...lastUserMessage,
          user_id: userId, // Get userId from the cookie
          project_id: projectId, // Get projectId from props
          prompt: lastUserMessage.text, // Use text as the prompt
          userInput: {
            image_bytes: null, // Default to null
            audio_bytes: null, // Default to null
            video_bytes: null, // Default to null
          },
        };
        setLastUserInput(populatedLastUserInput);
      }
    }
  }, [messages, lastUserInput]);

  useEffect(() => {
    const lastMessageWithAudio = [...messages]
      .reverse()
      .find((msg) => msg.audio);

    if (lastMessageWithAudio && !lastMessageWithAudio.is_error) {
      const detailsData = {
        name: lastMessageWithAudio.audio.name,
        description: lastMessageWithAudio.musicDescription,
        coverImage: lastMessageWithAudio.avatar,
        duration: lastMessageWithAudio.audio.duration,
        audioSrc: lastMessageWithAudio.audio.src,
        ai_response_id: lastMessageWithAudio.ai_response_id,
        projectId: lastMessageWithAudio.projectId,
      };

      updateLocalState("currentSongDetails", detailsData);
    }

    // Cleanup function that runs when component unmounts
    return () => {
      updateLocalState("stopGeneration", false);
      setBeforeResponseLoader(false);
      setStreamingResponseId(null);
      setLoadingResponseId(null);
    };
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await apiService.handleProtectedRoute(
        localState.isTeamMember ? "fetchTeamUserProfile" : "fetchUserProfile",
        {}
      );
      updateLocalState("userProfileData", response);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const sendAiResponseRequest = (requestPayload) => {
    const requestId = requestPayload.get("requestId");
    setCurrentRequestId(requestId);
    updateLocalState("projectId", projectId);
    const regeneratingResponseIdCurrent = requestPayload.get(
      "regeneratingResponseId"
    );
    updateLocalState("newSongGenerated", true);

    axios
      .post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/ai-response`,
        requestPayload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(async (response) => {
        const data = response.data;
        fetchUserData();
        // console.log("Response data:", data);

        if (data.success) {
          let aiResponse = {
            sender: "Wubble",
            text: data.data.generatedText,
            ai_response_id: data.data?.responseId,
            parent_response_id: data.data?.parentResponseId,
            regenerated:
              data.data?.regenerated ||
              requestPayload.get("regeneration") === true,
            requestId: requestId,
          };
          setBeforeResponseLoader(false);
          setBeforeRegenerateLoaderId(null);

          // updateLocalState("currentRequestId", null);

          // console.log("AI Response before:", aiResponse);

          const requestId = requestPayload.get("requestId");

          if (
            data?.data?.songTitle ||
            data?.data?.extendAudio ||
            data?.data?.responseType !== "text"
          ) {
            // Utility function to check if the browser is Safari
            const isSafari = /^((?!chrome|android).)*safari/i.test(
              navigator.userAgent
            );

            // Function to get the streaming URL
            const getStreamingUrl = (baseUrl) => {
              return isSafari ? `${baseUrl}&format=mp3` : baseUrl;
            };

            const audioStreamUrl = getStreamingUrl(
              `${process.env.REACT_APP_SERVER_ENDPOINT}/api/audio-stream?requestId=${requestId}`
            );

            updateLocalState("currentRequestId", requestId);
            updateLocalState("generatedRequestAudio", false);
            updateLocalState("ai_response_id", data.data?.responseId);

            // Start checking the generation status
            // checkGenerationStatus(requestId, aiResponse?.ai_response_id);

            aiResponse = {
              ...aiResponse,
              avatar: `data:image/png;base64,${data.data.coverImage?.data}`,
              audio: {
                name: data.data?.songTitle,
                duration: formatTime(data.data?.music_duration),
                src: audioStreamUrl,
                cover: `data:image/png;base64,${data.data.coverImage?.data}`,
              },
              actions: ["Extend duration", "Change genre"],
              musicCaption: data.data?.musicCaption,
              coverDescription: data.data?.coverDescription,
              ai_response_id: data.data?.responseId,
              projectId: data.data?.projectId,
              musicDescription: data.data.musicDescription,
              parent_response_id: data.data?.parentResponseId,
              responseType: data.data?.responseType,
              regenerated: data.data?.regenerated,
              created_at: new Date().toISOString(),
              requestId: requestId,
            };

            const detailsData = {
              name: aiResponse.audio.name,
              description: aiResponse.musicDescription,
              coverImage: aiResponse.avatar,
              duration: aiResponse.audio.duration,
              audioSrc: aiResponse.audio.src,
              ai_response_id: aiResponse.ai_response_id,
              projectId: aiResponse.projectId,
              responseType: aiResponse.responseType,
              regenerated: aiResponse.regenerated,
              created_at: new Date().toISOString(),
            };

            updateLocalState("currentSongDetails", detailsData);

            posthog.capture("Music generation in progress");

            // playAudioWithLoadingIndicator(
            //   audioStreamUrl,
            //   data.data.songTitle,
            //   aiResponse.musicDescription,
            //   aiResponse.avatar,
            //   aiResponse.ai_response_id,
            //   aiResponse.audio.duration
            // );
            playAudioWithLoadingIndicator(
              audioStreamUrl,
              data.data.songTitle,
              aiResponse.musicDescription,
              aiResponse.avatar,
              aiResponse.ai_response_id,
              aiResponse.audio.duration
            );
          } else {
            updateLocalState("responseInProgress", false);
          }

          // console.log("AI Response after:", aiResponse);
          // console.log("Regenration response id", regeneratingResponseIdCurrent);

          setMessages((prevMessages) => {
            let updatedMessages;

            if (regeneratingResponseIdCurrent) {
              // Find if we're regenerating from an error message
              const isErrorRegeneration = prevMessages.some(
                (msg) =>
                  Number(msg.ai_response_id) ==
                    Number(regeneratingResponseIdCurrent) && msg.is_error
              );

              if (isErrorRegeneration) {
                // Replace the error message with the successful response
                updatedMessages = prevMessages.map((msg) =>
                  Number(msg.ai_response_id) ===
                  Number(regeneratingResponseIdCurrent)
                    ? aiResponse
                    : msg
                );
              } else {
                // Regular regeneration - add as new message
                updatedMessages = [...prevMessages, aiResponse];
              }
            } else {
              // Normal flow - add new message
              updatedMessages = [...prevMessages, aiResponse];
            }

            // Group AI messages while keeping user messages intact
            const groupedMessages = groupRelatedMessages(updatedMessages);

            // Flatten the grouped messages array and return for state update
            return groupedMessages.flat();
          });

          setBeforeResponseLoader(false);

          scrollToBottom();

          if (data?.data?.songTitle && !localState.projectNameSetByUser) {
            const updateProjectNamePayload = {
              projectId,
              project_name: data?.data?.songTitle,
              set_by_user: false,
            };
            await apiService.sendRequest(
              "updateProjectName",
              updateProjectNamePayload
            );
            updateLocalState("projectName", data?.data?.songTitle);
            updateLocalState("projectNameUpdated", true);
          }
        } else {
          console.error("Error: AI response generation failed.");
        }
        if (isRegeneration) {
          setIsRegeneration(false);
          setRegeneratingResponseId(null);
        }
        setCurrentRequestId(null);
      })
      .catch(async (error) => {
        posthog.capture("Model Failure");

        if (error.name === "AbortError") {
          console.log("AI generation stopped");
          setBeforeResponseLoader(false);
          updateLocalState("responseInProgress", false);
          return;
        }
        console.error("Error sending AI response request:", error);
        if (error.response?.status === 504 || error.response?.status === 500) {
          const errorResponse = error.response?.data?.data;
          await new Promise((resolve) => setTimeout(resolve, 1000));

          if (
            errorResponse?.responseId ||
            errorResponse?.regeneratingResponseId
          ) {
            if (isRegeneration) {
              setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                  msg.ai_response_id === errorResponse.regeneratingResponseId
                    ? {
                        ...msg,
                        text: errorResponse.generatedText,
                        is_error: true,
                        error_message: errorResponse.error_message,
                      }
                    : msg
                )
              );
              setBeforeRegenerateLoaderId(null);
            } else {
              const aiErrorResponse = {
                sender: "Wubble",
                text: errorResponse.generatedText,
                ai_response_id: errorResponse.responseId,
                is_error: true,
                error_message: errorResponse.error_message,
              };
              setMessages((prevMessages) => [...prevMessages, aiErrorResponse]);
              setBeforeRegenerateLoaderId(null);
            }
            scrollToBottom();
          } else {
            setToastMessage(
              "The server is currently unavailable. Please try again later."
            );
          }
        }
        setBeforeResponseLoader(false);
        setBeforeRegenerateLoaderId(null);
        updateLocalState("responseInProgress", false);

        if (isRegeneration) {
          setIsRegeneration(false);
          setRegeneratingResponseId(null);
        }

        setCurrentRequestId(null);
      });

    updateLocalState("newMessage", null);
  };

  // this is the code that doesn't supports during generation error
  // const playAudioWithLoadingIndicator = (
  //   audioStreamUrl,
  //   title,
  //   description,
  //   avatar,
  //   aiResponseId,
  //   duration
  // ) => {
  //   setLoadingResponseId(aiResponseId);
  //   setStreamingResponseId(aiResponseId);
  //   setStreamingSrc(audioStreamUrl);
  //   setAiResponseId(aiResponseId);

  //   setIsBuffering(true);

  //   fetch(audioStreamUrl)
  //     .then(async (response) => {
  //       const reader = response.body.getReader();
  //       let firstChunkReceived = false;
  //       let chunkCounter = 0; // Counter to track number of chunks received
  //       const minChunks = 8;

  //       while (true) {
  //         const { done, value } = await reader.read();

  //         // console.log("Received chunk:", value);

  //         if (done) {
  //           setStreamingResponseId(null);
  //           setStreamingSrc(null);
  //           setIsBuffering(false);
  //           updateLocalState("responseInProgress", false);
  //           posthog.capture("Music Generation Completed");
  //           break;
  //         }

  //         if (value) {
  //           chunkCounter++;

  //           if (!firstChunkReceived && chunkCounter >= minChunks) {
  //             firstChunkReceived = true;
  //             updateLocalState("generatedRequestAudio", true);
  //             setLoadingResponseId(null);
  //             setBeforeResponseLoader(false);
  //             scrollToBottom();
  //             playAudio(audioStreamUrl, title, description, avatar, duration);

  //             pageWidth >= 768 && updateLocalState("isDetailsPanelOpen", true);
  //             continue;
  //           }
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error streaming audio:", error);
  //       setLoadingResponseId(null);
  //       setStreamingResponseId(null);
  //       setStreamingSrc(null);
  //       setIsBuffering(false);
  //       updateLocalState("responseInProgress", false);
  //       posthog.capture("Music Generation Failed");
  //     });
  // };

  const checkGenerationStatus = (requestId, aiResponseId) => {
    const pollingInterval = 5000; // 5 seconds
    let intervalId;

    // console.log(
    //   `[Check-Generation-Status] Monitoring started for requestId: ${requestId}`
    // );

    const updateMessageWithError = () => {
      console.warn(
        `[Check-Generation-Status] Polling stopped due to error for requestId: ${requestId}. Updating UI with error.`
      );
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.requestId === requestId
            ? {
                ...msg,
                is_error: true,
                error_message:
                  "Generation timed out or failed. Please try again.",
                text: "Generation timed out or failed. Please try again.",
              }
            : msg
        )
      );
      if (localState.versionHistory) {
        const updatedVersionHistory = localState.versionHistory
          .map((group) => ({
            ...group,
            versions: group.versions.filter(
              (v) => v.ai_response_id !== aiResponseId
            ),
          }))
          .filter((group) => group.versions.length > 0);

        updateLocalState("versionHistory", updatedVersionHistory);
      }
      // Clear current song details if it matches the failed generation
      updateLocalState("currentSongDetails", null);

      updateLocalState("responseInProgress", false);

      // Close audio player
      stopAudio();
    };

    const pollStatus = async () => {
      // console.log(
      //   `[Check-Generation-Status] Polling status for requestId: ${requestId}`
      // );
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/api/chunk-generation-status`,
          { params: { requestId } }
        );
        const { status } = response.data;
        if (status === "completed") {
          // console.log(
          //   `[Check-Generation-Status] Generation completed for requestId: ${requestId}`
          // );
          clearInterval(intervalId);
          // No UI update needed; response will handle updating the message
        } else if (status === "error") {
          console.error(
            `[Check-Generation-Status] Generation error for requestId: ${requestId}. Stopping polling.`
          );
          clearInterval(intervalId);
          updateMessageWithError();
        }
      } catch (error) {
        console.error(
          `[Check-Generation-Status] Error polling status for requestId: ${requestId}. Error: ${error.message}`
        );
        clearInterval(intervalId);
        updateMessageWithError();
      }
    };

    // Start polling
    // console.log(
    //   `[Check-Generation-Status] Starting polling every ${
    //     pollingInterval / 1000
    //   } seconds for requestId: ${requestId}`
    // );
    intervalId = setInterval(pollStatus, pollingInterval);
  };

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const playAudioWithLoadingIndicator = (
    audioStreamUrl,
    title,
    description,
    avatar,
    aiResponseId,
    duration
  ) => {
    setLoadingResponseId(aiResponseId);
    setStreamingResponseId(aiResponseId);
    setStreamingSrc(audioStreamUrl);
    setAiResponseId(aiResponseId);
    setIsBuffering(true);

    const timeoutMs = 40000; // Timeout for first chunk
    const controller = new AbortController();
    const signal = controller.signal;

    let firstChunkReceived = false;
    const timeoutHandle = setTimeout(() => {
      if (!firstChunkReceived) {
        controller.abort();
        setToastMessage("Streaming timed out. Please try regenerating.");
        updateLocalState("responseInProgress", false);
      }
    }, timeoutMs);

    const safariStreamUrl = isSafari
      ? `${audioStreamUrl}&format=mp3`
      : audioStreamUrl;

    fetch(safariStreamUrl, { signal })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const reader = response.body.getReader();
        let chunkCounter = 0;

        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            clearTimeout(timeoutHandle);
            setStreamingResponseId(null);
            setStreamingSrc(null);
            setIsBuffering(false);
            updateLocalState("responseInProgress", false);
            posthog.capture("Music Generation Completed");
            break;
          }

          if (value) {
            chunkCounter++;

            let chunkCondition = isSafari
              ? chunkCounter >= 10
              : chunkCounter >= 8;

            // First chunk logic
            if (
              !firstChunkReceived &&
              chunkCondition &&
              location.pathname === "/chatPage"
            ) {
              firstChunkReceived = true;
              clearTimeout(timeoutHandle);

              updateLocalState("generatedRequestAudio", true);
              setLoadingResponseId(null);
              scrollToBottom();
              setDiscoverId(null);

              if (!isSafari) {
                playAudio(
                  safariStreamUrl,
                  title,
                  description,
                  avatar,
                  duration
                );
              }
              if (pageWidth >= 768) {
                updateLocalState("isDetailsPanelOpen", true);
              }
              continue;
            }
          }
        }
      })
      .catch((error) => {
        clearTimeout(timeoutHandle);

        if (error.name === "AbortError") {
          console.error("Streaming aborted due to timeout:", error);
          setToastMessage("Streaming timed out. Please try regenerating.");
        } else {
          console.error("Error streaming audio:", error);
          setToastMessage("An error occurred while streaming audio.");
        }

        setLoadingResponseId(null);
        setStreamingResponseId(null);
        setStreamingSrc(null);
        setIsBuffering(false);
        updateLocalState("responseInProgress", false);
        posthog.capture("Music Generation Failed");
        handleStreamingError(aiResponseId, error.message);
      });
  };

  const handleStreamingError = (aiResponseId, errorMessage) => {
    // Find the AI response in the messages
    setMessages((prevMessages) =>
      prevMessages.map((msg) => {
        if (msg.ai_response_id === aiResponseId) {
          return {
            ...msg,
            is_error: true,
            error_message: "Streaming timed out. Please try again.",
            text: "Streaming timed out. Please try again.",
          };
        }
        return msg;
      })
    );

    // Update version history
    if (localState?.versionHistory) {
      const updatedVersionHistory = localState.versionHistory
        .map((group) => ({
          ...group,
          versions: group.versions.filter(
            (v) => v.ai_response_id !== aiResponseId
          ),
        }))
        .filter((group) => group.versions.length > 0);

      updateLocalState("versionHistory", updatedVersionHistory);
    }

    // Clear current song details if it matches the failed generation
    updateLocalState("currentSongDetails", null);

    // Close audio player
    stopAudio();

    // Optionally, show a toast message
    setToastMessage("Streaming timed out. Please try again.");
    setToastType("error");

    // Scroll to the updated message
    scrollToBottom();
  };

  const handleGenerationError = (aiResponseId, errorMessage) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) => {
        if (Number(msg.ai_response_id) === Number(aiResponseId)) {
          return {
            ...msg,
            is_error: true,
            error_message: errorMessage,
            text: "Generation failed. Please try again.",
          };
        }
        return msg;
      })
    );

    // Update version history
    if (localState.versionHistory) {
      const updatedVersionHistory = localState.versionHistory
        .map((group) => ({
          ...group,
          versions: group.versions.filter(
            (v) => Number(v.ai_response_id) !== Number(aiResponseId)
          ),
        }))
        .filter((group) => group.versions.length > 0);

      updateLocalState("versionHistory", updatedVersionHistory);
    }

    // Clear current song details if it matches the failed generation
    if (localState.currentSongDetails?.ai_response_id === aiResponseId) {
      updateLocalState("currentSongDetails", null);
    }

    // Close audio player
    stopAudio();

    setToastMessage(errorMessage);
    setToastType("error");
  };

  const handleRegenerate = (aiResponseId) => {
    if (localState?.userProfileData?.remaining_credits == 0) {
      if (localState.isAdmin && localState.isTeamMember) {
        dispatch(setRedirectAfterSelectedPlan("/create-team"));
        dispatch(setSubscriptionModal(true));
        dispatch(setSubscriptionTypeModal("upgrade"));
      } else if (!localState.isTeamMember) {
        dispatch(setRedirectAfterSelectedPlan("/create-team"));
        dispatch(setSubscriptionModal(true));
      }
      return;
    }

    if (streamingResponseId === aiResponseId) {
      setToastMessage("Please wait for the current response to finish.");
      setToastType("warning");
      return;
    }

    // console.log("Regenerating response with ID:", aiResponseId);
    posthog.capture("Click on Regenerate Button");

    // Ensure no duplicate requests
    if (currentRequestId) {
      setToastMessage("Request already in progress");
      setToastType("warning");
      return;
    }

    // Proceed with regeneration if not already in progress
    if (!isRegeneration) {
      // posthog.capture("Regenerate AI Response");
      setIsRegeneration(true);
      setRegeneratingResponseId(aiResponseId);
      setBeforeRegenerateLoaderId(aiResponseId);

      // Generate a new requestId
      const requestId = `${Date.now()}-${Math.random()
        .toString(36)
        .slice(2, 11)}`;

      // Create FormData for regeneration request
      const formData = new FormData();

      // if (lastUserInput.file) {
      //   Object.keys(lastUserInput).forEach((key) => {
      //     // if (key === "file") {
      //     //   // Convert the Buffer data back to a Blob
      //     //   const fileBuffer = new Uint8Array(
      //     //     lastUserInput.file?.content?.data
      //     //   );
      //     //   const fileBlob = new Blob([fileBuffer], {
      //     //     type: lastUserInput.file.type,
      //     //   });

      //     //   // Append the Blob to FormData as 'media'
      //     //   formData.append("media", fileBlob, lastUserInput.file.name);
      //     // } else {
      //     formData.append(key, lastUserInput[key]);
      //     // }
      //   });
      // } else {
      Object.keys(lastUserInput).forEach((key) => {
        formData.append(key, lastUserInput[key]);
      });
      // }

      if (lastUserInput.file?.url) {
        const mediaFileType = lastUserInput.file?.type?.split("/")[0];
        const urlsObject = {
          audios: mediaFileType === "audio" ? lastUserInput.file.url : [],
          images: mediaFileType === "image" ? lastUserInput.file.url : [],
          videos: mediaFileType === "video" ? lastUserInput.file.url : [],
        };
        formData.append("mediaUrls", JSON.stringify(urlsObject));
      }

      // Append additional fields
      formData.append("message_id", lastUserInput.id);
      formData.append("requestId", requestId);
      formData.append("regeneration", true);
      formData.append("regeneratingResponseId", aiResponseId);

      // if (lastUserInput.file?.content) {
      //   const fileBuffer = new Uint8Array(lastUserInput.file.content.data);
      //   const fileBlob = new Blob([fileBuffer], {
      //     type: lastUserInput.file.type,
      //   });
      //   formData.append("media", fileBlob, lastUserInput.file.name);
      // }
      // Update state and send request
      updateLocalState("responseInProgress", true);
      sendAiResponseRequest(formData);

      // Reset regeneration state
      setIsRegeneration(false);
      setRegeneratingResponseId(null);
    }
  };

  const handleErrorRegenerate = async (aiResponseId) => {
    if (!lastUserInput) {
      setToastMessage("Unable to retry. Please enter a new prompt.");
      return;
    }

    // Generate a new requestId
    const requestId = `${Date.now()}-${Math.random()
      .toString(36)
      .slice(2, 11)}`;

    setIsRegeneration(true);
    setRegeneratingResponseId(aiResponseId);
    setBeforeRegenerateLoaderId(aiResponseId);

    const formData = new FormData();
    formData.append("message_id", lastUserInput.id);
    formData.append("requestId", requestId);
    formData.append("regeneration", true);
    formData.append("regeneratingResponseId", aiResponseId);
    formData.append("isErrorRetry", true);

    // if (lastUserInput.file) {
    //   Object.keys(lastUserInput).forEach((key) => {
    //     if (key === "file") {
    //       // Convert the Buffer data back to a Blob
    //       const fileBuffer = new Uint8Array(lastUserInput.file?.content?.data);
    //       const fileBlob = new Blob([fileBuffer], {
    //         type: lastUserInput.file.type,
    //       });

    //       // Append the Blob to FormData as 'media'
    //       formData.append("media", fileBlob, lastUserInput.file.name);
    //     } else {
    //       formData.append(key, lastUserInput[key]);
    //     }
    //   });
    // } else {
    Object.keys(lastUserInput).forEach((key) => {
      formData.append(key, lastUserInput[key]);
    });
    // }

    if (lastUserInput.file?.url) {
      const mediaFileType = lastUserInput.file?.type?.split("/")[0];
      const urlsObject = {
        audios: mediaFileType === "audio" ? lastUserInput.file.url : [],
        images: mediaFileType === "image" ? lastUserInput.file.url : [],
        videos: mediaFileType === "video" ? lastUserInput.file.url : [],
      };
      formData.append("mediaUrls", JSON.stringify(urlsObject));
    }

    updateLocalState("responseInProgress", true);
    sendAiResponseRequest(formData);
    setIsRegeneration(false);
    setRegeneratingResponseId(null);
  };

  const handleBlankRegenerate = (aiResponseId, requestId) => {
    console.log("Regenerating response with ID:", aiResponseId);

    if (!lastUserInput) {
      setToastMessage("Unable to retry. Please enter a new prompt.");
      return;
    }
    setIsRegeneration(true);
    setRegeneratingResponseId(aiResponseId);
    setBeforeRegenerateLoaderId(aiResponseId);
    setCurrentRequestId(requestId);

    const formData = new FormData();

    // Add all required fields that match the backend route requirements
    formData.append("message_id", lastUserInput.id);
    formData.append("requestId", requestId);
    formData.append("regeneration", true);
    formData.append("regeneratingResponseId", aiResponseId);

    Object.keys(lastUserInput).forEach((key) => {
      formData.append(key, lastUserInput[key]);
    });

    // // Handle media if present in lastUserInput
    // if (lastUserInput.file?.content) {
    //   const fileBuffer = new Uint8Array(lastUserInput.file.content.data);
    //   const fileBlob = new Blob([fileBuffer], {
    //     type: lastUserInput.file.type,
    //   });
    //   formData.append("media", fileBlob, lastUserInput.file.name);
    // }

    if (lastUserInput.file?.url) {
      const mediaFileType = lastUserInput.file?.type?.split("/")[0];
      const urlsObject = {
        audios: mediaFileType === "audio" ? lastUserInput.file.url : [],
        images: mediaFileType === "image" ? lastUserInput.file.url : [],
        videos: mediaFileType === "video" ? lastUserInput.file.url : [],
      };
      formData.append("mediaUrls", JSON.stringify(urlsObject));
    }

    updateLocalState("responseInProgress", true);
    sendAiResponseRequest(formData);
    setIsRegeneration(false);
    setRegeneratingResponseId(null);
  };

  // Group related AI responses based on parent_response_id
  const groupRelatedMessages = (messages) => {
    const responseMap = {};

    // Step 1: Group AI messages by their parent_response_id or ai_response_id
    messages.forEach((msg) => {
      if (msg.sender === "Wubble") {
        const groupId =
          msg.parent_response_id && !msg.is_incomplete
            ? msg.parent_response_id + "sksres"
            : msg.ai_response_id;

        if (!responseMap[groupId]) {
          responseMap[groupId] = [];
        }

        responseMap[groupId].push(msg);
      }
    });

    // Step 2: Consolidate the grouped messages, while maintaining order
    const groupedMessages = messages.reduce((acc, msg) => {
      if (msg.sender === "User") {
        acc.push(msg); // Directly add user messages without grouping
      } else if (msg.sender === "Wubble") {
        const groupId =
          msg.parent_response_id && !msg.is_incomplete
            ? msg.parent_response_id + "sksres"
            : msg.ai_response_id;
        if (responseMap[groupId] && responseMap[groupId].length > 0) {
          acc.push(responseMap[groupId]);
          delete responseMap[groupId]; // Prevent re-adding the same group
        }
      }
      return acc;
    }, []);

    // console.log("Grouped Messages:", messages);

    // console.log("Messages:", messages);
    return groupedMessages;
  };

  const groupedMessages = groupRelatedMessages(messages);

  return (
    <>
      <div
        className={`flex-1 flex flex-col ${
          isAudioPlaying
            ? `max-h-[calc(100vh-16rem-${scrollHeight / 10}rem)]`
            : `max-h-[calc(100vh-12rem-${scrollHeight / 10}rem)]`
        } scrollable-content overflow-y-auto items-center justify-top w-full pb-8`}
      >
        {groupedMessages.map((groupOrMessage, index) => {
          if (Array.isArray(groupOrMessage)) {
            // This is a group of AI messages
            const mainMessage = groupOrMessage[0];
            const relatedMessages = groupOrMessage?.slice(1);

            const isLastMessage =
              index === groupedMessages.length - 1 &&
              groupOrMessage.includes(messages[messages.length - 1]);

            return (
              <AIChatBox
                key={mainMessage.ai_response_id}
                message={mainMessage}
                isLastMessage={isLastMessage}
                loadingResponseId={loadingResponseId}
                beforeRegenerateLoaderId={beforeRegenerateLoaderId}
                streamingResponseId={streamingResponseId}
                onRegenerate={handleRegenerate}
                relatedMessages={relatedMessages}
                id={mainMessage.ai_response_id}
                passedFromChatInterface={true}
                onErrorRegenerate={handleErrorRegenerate}
                onBlankRegenerate={handleBlankRegenerate}
                projectName={projectName}
                generationCount={generationCount}
                projectId={projectId}
                onGenerationError={handleGenerationError}
                // checkGenerationStatus={checkGenerationStatus}
              />
            );
          } else {
            // This is a single user message
            return <UserChatBox key={index} message={groupOrMessage} />;
          }
        })}
        {beforeResponseLoader && (
          <div className="flex-1 mt-auto flex items-center w-full max-w-screen-lg lg:max-w-screen-md">
            <ChatBoxLoader className={"w-full"} />
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      {toastMessage && (
        <Toast
          type={toastType}
          message={toastMessage}
          onClose={() => setToastMessage(null)}
        />
      )}
    </>
  );
};

export default ChatInterface;
