import { createSlice } from "@reduxjs/toolkit";

export const planMapper = {
  monthly: {
    basic: {
      price: "price_1QVQas2MK4knwytneEMgm8yr",
      amount: 10,
      minutes: 30,
    },
    business: {
      price: "price_1QlCRf2MK4knwytnTdq794Aq",
      amount: 30,
      minutes: 120,
    },
    free: {
      price: "price_1QVQeS2MK4knwytndlaZzBrD",
      amount: 0,
      minutes: 10,
    },
  },
  annual: {
    basic: {
      price: "price_1QlCRz2MK4knwytnHoSiAdkU",
      amount: 110,
      minutes: 360,
    },
    business: {
      price: "price_1QlCRB2MK4knwytnigbRo5oQ",
      amount: 330,
      minutes: 1440,
    },
    free: {
      price: "price_1QVQeS2MK4knwytndlaZzBrD",
      amount: 0,
      minutes: 10,
    },
  },
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    openSubscriptionModal: false,
    redirectAfterSelectedPlan: "",
    selectedPlan: "",
    planDetails: {},
    openOnboardingModal: false,
    openInviteMemberModal: false,
    teamId: "",
  },
  reducers: {
    setSubscriptionModal: (state, action) => {
      state.openSubscriptionModal = action.payload;
    },
    setRedirectAfterSelectedPlan: (state, action) => {
      state.redirectAfterSelectedPlan = action.payload;
    },
    setOpenOnboardingModal: (state, action) => {
      state.openOnboardingModal = action.payload;
    },
    setOpenInviteMemberModal: (state, action) => {
      state.openInviteMemberModal = action.payload;
    },
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
      state.planDetails =
        planMapper[action.payload.split("-")[0].toLowerCase()][
          action.payload.split("-")[1].toLowerCase()
        ];
    },
    setSubscriptionTypeModal: (state, action) => {
      state.subscriptionType = action.payload;
    },
    setTeamId: (state, action) => {
      state.teamId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchData.fulfilled, (state, action) => {
    //   state.data = action.payload;
    // });
  },
});

export const {
  setSubscriptionModal,
  setRedirectAfterSelectedPlan,
  setSelectedPlan,
  setOpenOnboardingModal,
  setOpenInviteMemberModal,
  setSubscriptionTypeModal,
  setTeamId,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
