import React, { useEffect, useState } from "react";
import { CreditCard, Persons } from "@gravity-ui/icons";
import { Building } from "lucide-react";
import { useNavigate } from "react-router-dom";
import apiService from "../../api/apiService";
import timeAgo from "../../utils/getFormattedDate";
import notificationImg from "../../assets/images/notification.png";
import { getCookie } from "../../utils/cookieUtils";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { loadStripe } from "@stripe/stripe-js";
import { update } from "lodash";

const ResponsiveNotification = () => {
  const [notifications, setNotifications] = useState([]);
  const { updateLocalState, localState } = useLocalState();
  // Replace with your Stripe publishable key
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  const navigate = useNavigate();

  useEffect(() => {
    getNotifications();
  }, []);
  const getNotifications = async () => {
    const data = await apiService.handleProtectedRoute(
      "fetchNotifications",
      {}
    );
    // Sort notifications from latest to oldest
    const sortedNotifications = data.notifications.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });
    console.log(sortedNotifications);

    setNotifications(sortedNotifications);
  };

  const markAllAsRead = async () => {
    setNotifications(
      notifications.map((notif) => ({ ...notif, is_read: true }))
    );
    await apiService.handleProtectedRoute("markAsReadNotifications", {});
    getNotifications();
  };

  const handleUpgradeMinutes = async (member) => {
    const planType = localState?.userProfileData?.subscription_type;
    const teamId = localState?.userProfileData?.team_id;

    let priceId;

    console.log(planType);

    if (planType?.toLowerCase()?.includes("basic")) {
      priceId = "price_1QiwrF2MK4knwytnQTLk5a3m"; // Basic Plan Price ID
    } else if (planType?.toLowerCase()?.includes("business")) {
      priceId = "price_1Qiwr92MK4knwytn4TpyPJO6"; // Business Plan Price ID
    } else {
      console.error("Unknown plan type");
      return;
    }

    try {
      const response = await apiService.UpgradeMemberCheckout({
        teamUserId: member.user_id,
        teamId: teamId,
        priceId: priceId,
      });
      const sessionId = response.sessionId;

      // Redirect to Stripe Checkout
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error("Error upgrading minutes:", error);
    }
  };

  const handleCreditRequestAction = (action, creditRequestId) => {
    console.log(action, creditRequestId);

    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/api/handle-credit-request`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("token")}`,
        },
        body: JSON.stringify({ action, creditRequestId }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (action === "accept") {
            // Redirect to Stripe checkout
            handleUpgradeMinutes({ user_id: data?.teamMemberId });
          } else {
            // Refresh notifications
            getNotifications();
          }
        }
      })
      .catch((error) => {
        console.error("Error handling credit request action:", error);
      });
  };

  const handleNotificationClick = (notification) => {
    const { notification_type, metadata } = notification;

    // Ensure metadata is a valid object
    const metadataObj =
      typeof metadata === "string" ? JSON.parse(metadata) : metadata;

    if (notification_type === "Team Invite" && metadataObj?.token) {
      updateLocalState("fromNotificatioClickAccept", true);
      navigate(`/accept-invitation?token=${metadataObj?.token}`);
    }
  };

  return (
    <div className="flex flex-col sm:w-[380px] h-[60vh] mt-8 w-full backdrop-brightness-50 bg-[#FFFFFF] backdrop-blur-[50px] bg-opacity-10 text-white rounded-lg  border border-white/10 mx-auto overflow-hidden">
      {/* Header */}
      <div className="flex gap-2 p-4 md:p-3 md:pl-4 border-b border-white/10 justify-between items-center">
        <h2 className="text-lg md:text-lg font-normal">
          {notifications.filter((n) => !n?.is_read).length === 0
            ? "Notifications"
            : `Notifications (${
                notifications.filter((n) => !n?.is_read).length
              } new)`}
        </h2>
        <button
          onClick={markAllAsRead}
          className="text-sm text-white/40 hover:text-white hover:bg-white/5 px-2.5 h-8 rounded-full"
        >
          Mark all as read
        </button>
      </div>

      {/* Notification Content */}
      <div
        className={`flex flex-col w-full ${
          notifications.length > 0
            ? "scrollable-content-notifications h-full"
            : ""
        }`}
      >
        {notifications.length > 0 ? (
          notifications.map((notification) => {
            const hasToken =
              notification?.notification_type === "Team Invite" &&
              notification?.metadata;

            return (
              <div
                key={notification?.id}
                className={`flex sm:flex-row gap-2 p-3 md:p-3 border-b border-white/10 min-h-[66px] w-full hover:bg-white/5 ${
                  hasToken ? "cursor-pointer" : ""
                }`}
                onClick={
                  hasToken ? () => handleNotificationClick(notification) : null
                }
              >
                {/* Notification Icon */}
                <div className="w-8 h-8 flex items-center justify-center md:w-10 md:h-10 shrink-0 bg-white/10 rounded-full">
                  <Building className="w-4 h-4 text-white" />
                </div>

                {/* Notification Content */}
                <div className="flex-1 flex flex-col sm:flex-row gap-4 relative">
                  {/* Notification Text */}
                  <div className="flex-1 w-full break-words overflow-hidden">
                    <p className="text-xs md:text-sm font-extralight text-white leading-tight">
                      {notification?.message}
                    </p>
                    <div className="flex items-center gap-1 mt-1">
                      <div className="w-[18px] h-[18px] flex items-center justify-center bg-gradient-to-br from-[#6ef7ec] to-[#7c0ca2] rounded-full">
                        <CreditCard className="w-2.5 h-2.5 text-white" />
                      </div>
                      <span className="text-[10px] md:text-xs text-gray-400">
                        {timeAgo(notification?.created_at)}
                      </span>
                    </div>
                  </div>

                  {/* Actions */}
                  {notification?.credit_request_id &&
                    notification?.credit_request_status === "pending" && (
                      <div className="flex gap-3 items-center justify-start">
                        <button
                          onClick={() =>
                            handleCreditRequestAction(
                              "deny",
                              notification.credit_request_id
                            )
                          }
                          className="px-2 h-8 rounded-full text-sm font-normal bg-white/5 hover:bg-white/10 text-[#878188] hover:text-white duration-200"
                        >
                          Deny
                        </button>

                        <button
                          onClick={() =>
                            handleCreditRequestAction(
                              "accept",
                              notification.credit_request_id
                            )
                          }
                          className="px-2 h-8 rounded-full text-sm hover:bg-[#E0DFE1] font-normal bg-white text-[#100f10]"
                        >
                          Accept
                        </button>
                      </div>
                    )}

                  {/* Read Indicator */}
                  {!notification?.is_read &&
                    !notification?.credit_request_id && (
                      <div className="w-2 h-2 bg-purple-500 rounded-full absolute right-0"></div>
                    )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex flex-col items-center justify-center h-full mt-[40%]">
            <img
              src={notificationImg}
              alt="Notification Icon"
              className="w-[39px] h-[42px] object-contain "
            />
            <p className="text-xl font-extralight text-center py-4 text-white/80">
              No notifications yet
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResponsiveNotification;
