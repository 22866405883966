import React, { useState, useEffect, useCallback } from "react";
import { PlayFill, PauseFill, ThumbsUp, ThumbsUpFill } from "@gravity-ui/icons";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import Modal from "../modal/Modal"; // Import the Modal component
import CustomInput from "../custom_components/CustomInput"; // Import the CustomInput component
import apiService from "../../api/apiService";
import { getCookie } from "../../utils/cookieUtils";
import { useAuth } from "../../contexts/AuthContext";
import Waveform from "./Waveform";
import { usePostHog } from "posthog-js/react";
import { useLocalState } from "../../contexts/LocalStateProvider";

const fetchBlobFromUrl = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
};

const SongList = ({ songs, onUnlike }) => {
  const {
    isPlaying,
    playAudio,
    pauseAudio,
    resumeAudio,
    audioSrc: currentAudioSrc,
    currentTime,
    duration,
    seekAudio,
    setDiscoverId,
  } = useAudioPlayer();
  const posthog = usePostHog();
  const [activeSong, setActiveSong] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSong, setSelectedSong] = useState(null);
  const [likeStatus, setLikeStatus] = useState({});
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);
  const { localState, updateLocalState } = useLocalState();

  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (!currentAudioSrc) {
      setActiveSong(null);
    }
  }, [currentAudioSrc]);

  const handlePlayPause = useCallback(
    (audioSrc, name, description, imageSrc, duration) => {
      if (isPlaying && currentAudioSrc === audioSrc) {
        pauseAudio();
        setActiveSong(null);
      } else if (currentAudioSrc === audioSrc) {
        resumeAudio();
        setActiveSong(audioSrc);
      } else {
        posthog.capture("Click to Play Discover Track");
        playAudio(audioSrc, name, description, imageSrc, duration);
        setActiveSong(audioSrc);
      }
    },
    [isPlaying, currentAudioSrc, pauseAudio, resumeAudio, playAudio]
  );

  const handleOpenModal = async (song, e) => {
    e.stopPropagation();

    if (!isLoggedIn) {
      updateLocalState("showLoginModal", (prev) => !prev);
      return;
    }

    // Check if the song has an associated audio source (aiResponseId or discoverId)
    if (!song.id) {
      return;
    }

    posthog.capture("Click to Customize Discover Audio", {
      song: song?.name,
    });

    const songData = {
      audioFile: {
        name: song.name,
        type: "audio/wav",
        url: `gs://discover_songs/${song.fileName}`,
      },
      image: song.image,
      mood: song.mood,
    };

    setSelectedSong(songData);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSong(null);
  };

  useEffect(() => {
    // Initialize likeStatus state with the initial isLiked values of the songs
    const initialLikeStatus = songs.reduce((acc, song) => {
      acc[song.id] = song.isLiked ? "like" : "unlike";
      return acc;
    }, {});
    setLikeStatus(initialLikeStatus);
  }, [songs]);

  const handleLike = async (discoverId, songName) => {
    const userId = getCookie("user_id");

    if (!userId || !discoverId) return;

    const payload = {
      discover_id: discoverId,
      user_id: userId,
      is_like: likeStatus[discoverId] !== "like", // Toggle like status
    };

    try {
      if (likeStatus[discoverId] === "like") {
        // If already liked, remove the like
        await apiService.sendRequest("removeDiscoverLike", payload);
        setLikeStatus((prevState) => ({
          ...prevState,
          [discoverId]: "unlike", // Update status to unlike
        }));

        const currentLikedTracks = localState.likedDiscoverTracks || [];
        const updatedLikedTracks = currentLikedTracks.filter(
          (track) => track.id !== discoverId
        );
        updateLocalState("likedDiscoverTracks", updatedLikedTracks);

        posthog.capture("Discover Song Click to Unlike", {
          song: songName,
        });

        // Update songs in LocalState
        const currentSongs = localState.songs || [];
        const updatedSongs = currentSongs.map((song) =>
          song.id === discoverId ? { ...song, isLiked: false } : song
        );
        updateLocalState("songs", updatedSongs);
      } else {
        // Add the like
        await apiService.sendRequest("updateDiscoverLike", payload);
        setLikeStatus((prevState) => ({
          ...prevState,
          [discoverId]: "like", // Update status to like
        }));

        // Add the newly liked song to likedDiscoverTracks in LocalState
        const likedSong = songs.find((song) => song.id === discoverId);
        posthog.capture("Discover Page Song Click to Like", { song: songName });

        if (likedSong) {
          const currentLikedTracks = localState.likedDiscoverTracks || [];
          const updatedLikedSong = { ...likedSong, isLiked: true };
          updateLocalState("likedDiscoverTracks", [
            ...currentLikedTracks,
            updatedLikedSong,
          ]);

          // Update songs in LocalState
          const currentSongs = localState.songs || [];
          const updatedSongs = currentSongs.map((song) =>
            song.id === discoverId ? { ...song, isLiked: true } : song
          );
          updateLocalState("songs", updatedSongs);
        }
      }
    } catch (error) {
      console.error("Error updating like:", error);
    }
  };

  return (
    <div>
      <ul className="list-none p-0 m-0">
        {songs.map((song, index) => (
          <li key={`${song.id}-${index}`} className="mb-2">
            <div
              onClick={() => {
                handlePlayPause(
                  song.audioSrc,
                  song.name,
                  song.displayTags,
                  song.image,
                  song.duration
                );
                setDiscoverId(song.id);
              }}
              className={`flex items-center p-1.5 pr-4 m-1.5 rounded-xl cursor-pointer group ${
                activeSong === song.audioSrc
                  ? "bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 text-white"
                  : "hover:bg-opacity-10 transition duration-500 hover:bg-white"
              }`}
              style={
                activeSong === song.audioSrc
                  ? {
                      background:
                        "linear-gradient(0deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.72) 100%), linear-gradient(135deg, #FC10F2 0%, #3078E4 100%)",
                    }
                  : {}
              }
            >
              <div className="flex items-center relative overflow-hidden mr-4">
                <img
                  src={song.image}
                  alt={song.name}
                  className="w-12 h-12 object-cover rounded-lg"
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePlayPause(
                      song.audioSrc,
                      song.name,
                      song.displayTags,
                      song.image,
                      song.duration
                    );
                    setDiscoverId(song.id);
                  }}
                  className={`absolute inset-0 flex items-center justify-center text-white transition-transform duration-500 transform group-hover:opacity-100 ${
                    isPlaying && currentAudioSrc === song.audioSrc
                      ? "md:translate-y-0"
                      : "md:translate-y-[70%] md:group-hover:translate-y-0"
                  }`}
                >
                  {isPlaying && currentAudioSrc === song.audioSrc ? (
                    <PauseFill className="w-4 h-4" />
                  ) : (
                    <PlayFill className="w-4 h-4" />
                  )}
                </button>
              </div>
              <div className="flex-1 min-w-0">
                <p className="font-medium text-base truncate">{song.name}</p>
                <p className="font-light text-sm text-gray-400 truncate">
                  {song.displayTags}
                </p>
              </div>

              {/* Right-aligned items */}
              <div className="flex items-center ml-auto gap-20">
                <span className="hidden md:block font-light text-sm text-white">
                  {song.duration}
                </span>

                {/* Waveform Audio Player */}
                <div className="hidden md:flex flex-grow">
                  <Waveform
                    waveform={song?.waveformData?.waveform}
                    audioSrc={song.audioSrc}
                    currentTime={
                      currentAudioSrc === song.audioSrc ? currentTime : 0
                    }
                    duration={currentAudioSrc === song.audioSrc ? duration : 0}
                    isPlaying={isPlaying && currentAudioSrc === song.audioSrc}
                    onSeek={(newTime) => seekAudio(newTime)}
                    isActive={currentAudioSrc === song.audioSrc}
                  />
                </div>

                <div className="flex items-center gap-6">
                  <button
                    className="flex flex-row text-white hover:text-gray-300 "
                    onClick={(e) => handleOpenModal(song, e)}
                    disabled={
                      localState.userProfileData?.remaining_credits <= 0
                    }
                  >
                    <img
                      src="/Generate.svg"
                      className="opacity-100 hover:brightness-200 disabled:opacity-50"
                    />
                  </button>

                  {isLoggedIn && ( // Only show the like button if the user is logged in
                    <button
                      className="flex text-[#878188] hover:text-white"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLike(song.id, song.name);
                      }}
                    >
                      {likeStatus[song.id] === "like" ? (
                        <ThumbsUpFill />
                      ) : (
                        <ThumbsUp />
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {isModalOpen && selectedSong && (
        <Modal closeModal={handleCloseModal} showCloseButton={false}>
          <CustomInput
            audioFile={selectedSong.audioFile}
            imageSrc={selectedSong.image}
            mood={selectedSong.mood}
            fromDiscoverPage={true}
            className="w-full md:w-[40rem]"
            requestData={{ createProject: true }}
            chatPage={false}
            isLoadingAudio={isLoadingAudio}
          />
        </Modal>
      )}
    </div>
  );
};

export default SongList;
